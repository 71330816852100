import { APP_ROUTER } from "@/routes"
import {
  CalendarIcon,
  CooperateIcon,
  EmailIcon,
  HomeIcon,
  SettingIcon,
  SliderIcon,
  SongIcon,
  ToolIcon,
  UserIcon,
} from "@/components/icons"

export const APP_MENU = [
  {
    name: "Trang chủ",
    path: APP_ROUTER.HOME,
    icon: <HomeIcon />,
  },
  {
    name: "Đối tác",
    path: APP_ROUTER.PARTNER,
    icon: <CooperateIcon />,
  },
  {
    name: "Slider",
    icon: <SliderIcon />,
    children: [
      {
        name: "Danh sách",
        path: APP_ROUTER.SLIDER.LIST,
      },
      {
        name: "Loại",
        path: APP_ROUTER.SLIDER.TYPE,
      },
    ],
  },
  {
    name: "Bài hát",
    icon: <SongIcon />,
    children: [
      {
        name: "Danh sách",
        path: APP_ROUTER.SONG.LIST,
      },
      {
        name: "Nhóm",
        path: APP_ROUTER.SONG.GROUP,
      },
    ],
  },
  {
    name: "Sự kiện",
    icon: <CalendarIcon width={22} height={22} />,
    children: [
      {
        name: "Danh sách",
        path: APP_ROUTER.EVENT.LIST,
      },
      {
        name: "Loại",
        path: APP_ROUTER.EVENT.TYPE,
      },
    ],
  },
  {
    name: "Email",
    icon: <EmailIcon />,
    children: [
      {
        name: "Tài khoản",
        path: APP_ROUTER.EMAIL.ACCOUNT,
      },
      {
        name: "Lịch sử gửi",
        path: APP_ROUTER.EMAIL.LOG,
      },
      {
        name: "Mẫu",
        path: APP_ROUTER.EMAIL.TEMPLATE,
      },
      {
        name: "Danh mục",
        path: APP_ROUTER.EMAIL.CATEGORY,
      },
    ],
  },
  {
    name: "Công cụ",
    icon: <ToolIcon />,
    children: [
      {
        name: "Lọc Boxchat",
        path: APP_ROUTER.TOOL.FILTER_BOXCHAT,
      },
    ],
  },
  {
    name: "Cấu hình",
    path: APP_ROUTER.CONFIG,
    icon: <SettingIcon />,
  },
  {
    name: "Người dùng",
    icon: <UserIcon />,
    children: [
      {
        name: "Danh sách",
        path: APP_ROUTER.USER.LIST,
      },
      {
        name: "Vai trò",
        path: APP_ROUTER.USER.ROLE,
      },
      {
        name: "Quyền",
        path: APP_ROUTER.USER.PERMISSION,
      },
      {
        name: "Nhóm quyền",
        path: APP_ROUTER.USER.PERMISSION_GROUP,
      },
    ],
  },
]
