import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.string().required("Vui lòng nhập Họ và tên"),
    birthday:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.string().required("Vui lòng nhập Ngày sinh"),
    gender:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.number().required("Vui lòng Giới tính"),
    email:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.string().required("Vui lòng nhập Email"),
    phone:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.string().required("Vui lòng nhập Số điện thoại"),
    facebook:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.string().required("Vui lòng nhập Địa chỉ Facebook"),
    position_id:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET ||
        modalName === MODAL.SEND_EMAIL_ALL) &&
      yup.number().required("Vui lòng chọn Hạng vé"),
    owner_id:
      (modalName === MODAL.CREATE_EVENT_TICKET ||
        modalName === MODAL.UPDATE_EVENT_TICKET) &&
      yup.number().required("Vui lòng chọn Người sở hữu"),
    payment_id:
      modalName === MODAL.VERIFY_PAYMENT &&
      yup.number().required("Vui lòng chọn giao dịch"),
    method:
      modalName === MODAL.VERIFY_PAYMENT &&
      yup.number().required("Vui lòng chọn Phương thức"),
    description:
      modalName === MODAL.VERIFY_PAYMENT &&
      yup.string().required("Vui lòng Nhập nội dung"),
    bill_path:
      modalName === MODAL.ADD_PAYMENT_HISTORY &&
      yup.string().required("Vui lòng tải lên hình ảnh"),
    subject:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.string().required("Vui lòng nhập tiêu đề"),
    content:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.string().required("Vui lòng nhập nội dung"),
    account_ids:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.array().of(yup.number()).required("Vui lòng chọn email gửi"),
    ticket_status:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.number().required("Vui lòng chọn trạng thái"),
    send_ticket_status:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.number().required("Vui lòng chọn trạng thái"),
    confirm_status:
      modalName === MODAL.SEND_EMAIL_ALL &&
      yup.number().required("Vui lòng chọn trạng thái"),
    file:
      modalName === MODAL.IMPORT_EVENT_TICKET &&
      yup.mixed().required("Vui lòng tải lên tệp"),
  } as any)
