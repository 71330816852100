import { useEffect } from "react"
import { useFormContext } from "react-hook-form"

interface IProps {
  modalName: string
  content: string | React.ReactNode
}

export const ConfirmUpdateMany: React.FC<IProps> = ({ modalName, content }) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    setValue("modal_name", modalName)
  }, [])

  return <div className="c-confirm-update-many">{content}</div>
}
