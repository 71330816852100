import { ColorPicker, ColorPickerProps } from "antd"
import { ControllerRenderProps } from "react-hook-form"

interface IProps extends ColorPickerProps {
  field?: ControllerRenderProps<any, any>
}
export const ColorPickerWrap: React.FC<IProps> = ({
  className,
  children,
  field,
  ...props
}) => {
  return <ColorPicker className={className} {...field} {...props} />
}
