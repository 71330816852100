import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_SONG_GROUP ||
        modalName === MODAL.UPDATE_SONG_GROUP) &&
      yup.string().required("Vui lòng nhập Tên"),
    day_release:
      (modalName === MODAL.CREATE_SONG_GROUP ||
        modalName === MODAL.UPDATE_SONG_GROUP) &&
      yup.string().required("Vui lòng chọn Ngày phát hành"),
    type:
      (modalName === MODAL.CREATE_SONG_GROUP ||
        modalName === MODAL.UPDATE_SONG_GROUP) &&
      yup.string().required("Vui lòng loại"),
  } as any)
