import { ArrowDownIcon, ArrowUpIcon } from "@/components/icons"
import { useState } from "react"
import { NavLink } from "react-router-dom"
import "./style.scss"

interface ISidebarItemProps {
  data: any
}

export const SidebarItem: React.FC<ISidebarItemProps> = ({ data }) => {
  const isPathExist = data?.children?.some(
    (item: any) => item.path === window.location.pathname,
  )
  const [isVisible, setIsVisible] = useState(isPathExist || false)

  const showSubMenu = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div className="c-sidebar-item">
      {data?.path ? (
        <NavLink
          className={({ isActive }) =>
            isActive ? "sidebar-item sidebar-item--active" : "sidebar-item"
          }
          to={data?.path}
        >
          <div className="left">
            {data?.icon}
            <span className="sidebar-item__name">{data?.name}</span>
          </div>
        </NavLink>
      ) : (
        <div className="sidebar-item no-select" onClick={showSubMenu}>
          <div className="left">
            {data?.icon}
            <span className="sidebar-item__name">{data?.name}</span>
          </div>
          {data?.children && data?.children.length > 0 ? (
            <div className="right">
              {isVisible ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          ) : null}
        </div>
      )}

      {isVisible && data?.children && data?.children.length > 0 ? (
        <div className="sidebar-item__sub-item-wrapper">
          {data?.children?.map((item: any, index: any) => (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "sidebar-item__sub-item sidebar-item__sub-item--active"
                  : "sidebar-item__sub-item"
              }
              to={item.path}
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      ) : null}
    </div>
  )
}
