import { ISvgIcon } from "@/types/common"

export const VerifyIcon: React.FC<ISvgIcon> = ({
  width = 20,
  height = 20,
  fillColor = "var(--color-neutral-8)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fillColor}
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8z"
      />
    </svg>
  )
}
