import { ISvgIcon } from "@/types/common"

export const UserIcon: React.FC<ISvgIcon> = ({
  width = 20,
  height = 20,
  fillColor = "var(--color-neutral-8)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fillColor}
        d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4"
      />
    </svg>
  )
}
