import { REGEX } from "@/utils/constants"
import * as yup from "yup"

export const formSchemaFn = () =>
  yup.object().shape({
    bank_username: yup.string().required("Vui lòng nhập Tài khoản ngân hàng"),
    bank_password: yup.string().required("Vui lòng nhập Mật khẩu ngân hàng"),
    bank_account_no: yup.string().required("Vui lòng nhập Số tài khoản"),
    bank_device_id: yup.string().required("Vui lòng nhập Mã thiết bị"),
    limit_email_daily: yup.number().required("Vui lòng nhập số lượng"),
    send_email_delay: yup.number().required("Vui lòng nhập thời gian!"),
    system_email_id: yup.number().required("Vui lòng chọn email!"),
    email_notified: yup
      .string()
      .matches(REGEX.EMAIL, "Vui lòng nhập đúng định dạng email!")
      .required("Vui lòng nhập email!"),
    ticket_registration_time: yup.number().required("Vui lòng nhập thời gian!"),
    ticket_payment_time: yup.number().required("Vui lòng nhập thời gian!"),
  } as any)
