import {
  BreadcrumbWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { APP_ROUTER } from "@/routes"
import { emailTypes, sendEmailStatus } from "@/utils/constants"
import { convertObjectToArray, convertToStringObject, t } from "@/utils/helpers"
import { Col, Form, Row } from "antd"
import { useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import "./style.scss"

interface IHeaderProps {
  params: any
}

const Header: React.FC<IHeaderProps> = ({ params }) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { control, reset, setValue } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.EMAIL.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.EMAIL.LOG"),
      href: APP_ROUTER.EMAIL.LOG,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        subject: searchParams.get("subject") || undefined,
        sender_name: searchParams.get("sender_name") || undefined,
        sender_email: searchParams.get("sender_email") || undefined,
        receiver_name: searchParams.get("receiver_name") || undefined,
        receiver_email: searchParams.get("receiver_email") || undefined,
        type: searchParams.get("type")
          ? Number(searchParams.get("type"))
          : undefined,
        status: searchParams.get("status")
          ? Number(searchParams.get("status"))
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        subject: formMonitor?.subject || "",
        sender_name: formMonitor?.sender_name || "",
        sender_email: formMonitor?.sender_email || "",
        receiver_name: formMonitor?.receiver_name || "",
        receiver_email: formMonitor?.receiver_email || "",
        type: formMonitor.type === undefined ? "" : String(formMonitor.type),
        status:
          formMonitor.status === undefined ? "" : String(formMonitor.status),
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-email-account-header no-select">
      <div className="title">{t("P_EMAIL_LOG.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
      </div>
      <Form labelCol={{ span: 4, md: 8 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_SUBJECT")}
                  colon
                >
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_EMAIL_LOG.FILTER.PLACEHOLDER_SUBJECT",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_SENDER_NAME")}
                  colon
                >
                  <Controller
                    name="sender_name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_EMAIL_LOG.FILTER.PLACEHOLDER_SENDER_NAME",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_SENDER_EMAIL")}
                  colon
                >
                  <Controller
                    name="sender_email"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_EMAIL_LOG.FILTER.PLACEHOLDER_SENDER_EMAIL",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_RECEIVER_NAME")}
                  colon
                >
                  <Controller
                    name="receiver_name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_EMAIL_LOG.FILTER.PLACEHOLDER_RECEIVER_NAME",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_RECEIVER_EMAIL")}
                  colon
                >
                  <Controller
                    name="receiver_email"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_EMAIL_LOG.FILTER.PLACEHOLDER_RECEIVER_EMAIL",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_EMAIL_LOG.FILTER.LABEL_TYPE")} colon>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("type", "")}
                        placeholder={t("P_EMAIL_LOG.FILTER.PLACEHOLDER_TYPE")}
                        options={convertObjectToArray(emailTypes)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EMAIL_LOG.FILTER.LABEL_STATUS")}
                  colon
                >
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("status", "")}
                        placeholder={t("P_EMAIL_LOG.FILTER.PLACEHOLDER_STATUS")}
                        options={convertObjectToArray(sendEmailStatus)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
