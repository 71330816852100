import { Cascader } from "antd"
import clsx from "clsx"
import "./style.scss"

export const CascaderWrap: React.FC<any> = ({ className, field, ...props }) => {
  return (
    <Cascader
      className={clsx("c-cascader-wrap", className)}
      {...field}
      {...props}
    />
  )
}
