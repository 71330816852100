import {
  ButtonWrap,
  ModalWrap,
  PaginationWrap,
  TableWrap,
} from "@/components/commons"
import {
  ClockIcon,
  ErrorCircleIcon,
  PencilIcon,
  PendingCircleIcon,
  RemoveCircleIcon,
  VerifyIcon,
} from "@/components/icons"
import { ENDPOINT } from "@/services/endpoint"
import { EventTicketService } from "@/services/eventTicket"
import { IEmailCondition, IModalState, IResponse } from "@/types/common"
import { IEventTicketCustomize } from "@/types/response"
import { IGetDetailEventResponse } from "@/types/response/event"
import { IGetAllEventPositionResponse } from "@/types/response/eventPosition"
import {
  CONFIRM_JOIN_EVENT_STATUS,
  EVENT_TICKET_STATUS,
  SEND_TICKET_STATUS,
  confirmJoinEventStatus,
  eventTicketStatus,
  sendTicketStatus,
} from "@/utils/constants"
import { convertRequest, getAvatarByGender, t } from "@/utils/helpers"
import { Divider } from "antd"
import { ColumnsType } from "antd/es/table"
import { useEffect, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { ChangeCondition } from "../ChangeCondition"
import "./style.scss"

const ConfirmReceiver = () => {
  const { event_id: eventId, status: statusTab } = useParams()
  const queryClient = useQueryClient()
  const emailConditionRef = useRef<any>(null)
  const { control, setValue, getValues, reset } = useFormContext()
  const formMonitor = useWatch({
    control,
  })

  const [params, setParams] = useState({
    current_page: 1,
    page_size: 3,
    event_id: Number(eventId),
    status: formMonitor.ticket_status,
    send_ticket_status: formMonitor.send_ticket_status,
    position_id: formMonitor.position_id,
  })
  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setParams({
      ...params,
      current_page: currentPage,
    })
  }

  const {
    data: ticketList,
    refetch: refetchTicketList,
    isFetching: isFetchingTicketList,
  } = useQuery(
    [ENDPOINT.EVENT_TICKET.SEARCH, params],
    () => EventTicketService.search(convertRequest(params)),
    {
      enabled: !!params?.status,
      onSuccess: (response: any) => {},
    },
  )

  const eventDetail: IResponse<IGetDetailEventResponse> | undefined =
    queryClient.getQueryData([ENDPOINT.EVENT.GET_DETAIL, eventId])

  const positions: IResponse<IGetAllEventPositionResponse> | undefined =
    queryClient.getQueryData([ENDPOINT.EVENT_POSITON.GET_ALL])

  const positionList = positions?.data.reduce<{ [key: number]: string }>(
    (acc, item) => {
      acc[item.id] = item.name
      return acc
    },
    { 0: "Tất cả" },
  )

  const handleChangeCondition = (data: IEmailCondition) => {
    emailConditionRef.current = data
  }

  const handleSaveCondition = () => {
    setValue("ticket_status", emailConditionRef.current?.ticket_status)
    setValue(
      "send_ticket_status",
      emailConditionRef.current?.send_ticket_status,
    )
    setValue("confirm_status", emailConditionRef.current?.confirm_status)
    setValue("position_id", emailConditionRef.current?.position_id)

    handleCancelModal()
  }

  const getConfirmStatusLabel = (value: number) => {
    let icon = <></>
    switch (value) {
      case CONFIRM_JOIN_EVENT_STATUS.NONE:
        icon = <RemoveCircleIcon fillColor="var(--color-yellow-1)" />
        break
      case CONFIRM_JOIN_EVENT_STATUS.CONFIRMED:
        icon = <VerifyIcon fillColor="var(--color-green-1)" />
        break
      case CONFIRM_JOIN_EVENT_STATUS.UNCONFIRMED:
        icon = <ErrorCircleIcon fillColor="var(--color-red-0)" />
        break
      default:
        icon = <></>
        break
    }

    return (
      <div className="column-condition">
        {icon} {confirmJoinEventStatus[value]}
      </div>
    )
  }

  const getSendTicketStatusLabel = (value: number) => {
    let icon = <></>
    switch (value) {
      case SEND_TICKET_STATUS.NONE:
        icon = <RemoveCircleIcon fillColor="var(--color-yellow-1)" />
        break
      case SEND_TICKET_STATUS.SENT:
        icon = <VerifyIcon fillColor="var(--color-green-1)" />
        break
      case SEND_TICKET_STATUS.UNSENT:
        icon = <ErrorCircleIcon fillColor="var(--color-red-0)" />
        break
      default:
        icon = <></>
        break
    }

    return (
      <div className="column-condition no-select">
        {icon} {sendTicketStatus[value]}
      </div>
    )
  }

  const getTicketStatusLabel = (value: number) => {
    let icon = <></>
    switch (value) {
      case EVENT_TICKET_STATUS.PENDING_PAYMENT:
        icon = <PendingCircleIcon fillColor="var(--color-yellow-1)" />
        break
      case EVENT_TICKET_STATUS.VERIFICATION_REQUEST:
        icon = <ClockIcon fillColor="var(--color-yellow-1)" />
        break
      case EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL:
        icon = <VerifyIcon fillColor="var(--color-green-1)" />
        break
      case EVENT_TICKET_STATUS.VERIFICATION_FAILED:
        icon = <ErrorCircleIcon fillColor="var(--color-yellow-1)" />
        break
      case EVENT_TICKET_STATUS.CANCELED:
        icon = <ErrorCircleIcon fillColor="var(--color-red-0)" />
        break
      default:
        icon = <></>
        break
    }

    return (
      <div className="column-condition no-select">
        {icon} {eventTicketStatus[value]}
      </div>
    )
  }

  const receiverColumns: ColumnsType<IEventTicketCustomize> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "40px",
      align: "center",
    },
    {
      title: "Tên",
      width: "120px",
      render: (record) => (
        <div className="column-name">
          <img
            className="avatar"
            src={getAvatarByGender(record.gender)}
            alt={record.name}
          />
          <span className="name">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "120px",
      render: (value) => value,
    },
  ]

  const conditionData = [
    {
      id: 1,
      ticket_status: formMonitor?.ticket_status,
      send_ticket_status: formMonitor?.send_ticket_status,
      confirm_status: formMonitor?.confirm_status,
      position_id: formMonitor?.position_id,
    },
  ]

  let conditionColumns: any = [
    {
      title: "Trạng thái vé",
      width: "100px",
      align: "center",
      dataIndex: "ticket_status",
      render: (value: number) => getTicketStatusLabel(value),
    },
    {
      title: "Gửi vé",
      width: "100px",
      align: "center",
      dataIndex: "send_ticket_status",
      render: (value: number) => getSendTicketStatusLabel(value),
    },
    {
      title: "Xác nhận tham gia",
      width: "100px",
      align: "center",
      dataIndex: "confirm_status",
      render: (value: number) => getConfirmStatusLabel(value),
    },
    {
      title: "Hạng vé",
      width: "100px",
      align: "center",
      dataIndex: "position_id",
      render: (value: number) => positionList![value],
    },
  ]

  const handleCancelModal = () => {
    setModal({
      open: false,
      width: 608,
      bodyHeight: 588,
      children: <></>,
    })
  }

  const handleShowModal = () => {
    setModal({
      open: true,
      title: t("P_TICKET_FAN.TITLE_CHANGE_CONDITION_CONFIRM_RECEIVER"),
      width: 500,
      okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
      onOk: handleSaveCondition,
      onCancel: handleCancelModal,
      children: (
        <ChangeCondition
          handleChangeCondition={handleChangeCondition}
          positionList={positionList!}
          emailCondition={{
            ticket_status: formMonitor?.ticket_status,
            send_ticket_status: formMonitor?.send_ticket_status,
            confirm_status: formMonitor?.confirm_status,
            position_id: formMonitor?.position_id,
          }}
        />
      ),
    })
  }

  useEffect(() => {
    reset({
      ...getValues(),
      ticket_status: EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL,
      send_ticket_status: 0,
      confirm_status: 0,
      position_id: 0,
    })
  }, [])

  useEffect(() => {
    setParams({
      ...params,
      status: formMonitor.ticket_status,
      send_ticket_status: formMonitor.send_ticket_status,
      position_id: formMonitor.position_id,
    })
  }, [
    formMonitor.ticket_status,
    formMonitor.send_ticket_status,
    formMonitor.position_id,
  ])

  return (
    <div className="c-confirm-content">
      <Divider orientation="center" plain>
        Điều kiện gửi
      </Divider>
      <TableWrap
        rowKey="id"
        columns={
          !eventDetail?.data?.have_payment
            ? conditionColumns
            : conditionColumns.filter(
                (column: any) => column?.dataIndex !== "confirm_status",
              )
        }
        dataSource={conditionData}
        pagination={false}
        loading={false}
      />
      <div className="change-condition">
        <ButtonWrap
          type="primary"
          onClick={handleShowModal}
          icon={
            <PencilIcon
              fillColor="var(--color-neutral-1)"
              width={18}
              height={18}
            />
          }
        >
          Thay đổi điều kiện
        </ButtonWrap>
      </div>
      <Divider orientation="center" plain>
        Danh sách người nhận
      </Divider>
      <TableWrap
        rowKey="id"
        columns={receiverColumns}
        dataSource={ticketList?.data}
        pagination={false}
        loading={isFetchingTicketList}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params?.current_page}
        total={ticketList?.total}
        onChange={handleChangePage}
        showLessItems
      />
      <ModalWrap {...modal}>{modal.children}</ModalWrap>
    </div>
  )
}

export default ConfirmReceiver
