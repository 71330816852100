import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_PARTNER ||
        modalName === MODAL.UPDATE_PARTNER) &&
      yup.string().required("Vui lòng nhập Tên đối tác"),
    priority:
      (modalName === MODAL.CREATE_PARTNER ||
        modalName === MODAL.UPDATE_PARTNER) &&
      yup.number().required("Vui lòng nhập Thứ tự hiển thị"),
  } as any)
