import { IUserActionInfo } from "@/types/common"
import { ClockCircleOutlined } from "@ant-design/icons"
import { Avatar } from "antd"
import moment from "moment"
import { TooltipWrap } from "../TooltipWrap"
import { UserInfoWrap } from "../UserInfoWrap"
import "./style.scss"
import { t } from "@/utils/helpers"

export enum TABLE_EDITOR_TYPE {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

interface ITableEditorWrapProps {
  users: IUserActionInfo[]
  type: "single" | "multiple"
  time: string
}
export const TableEditorWrap: React.FC<ITableEditorWrapProps> = ({
  users,
  type,
  time,
}) => {
  const renderUsers = () => {
    switch (type) {
      case "single":
        return <UserInfoWrap data={users[0]} avatarSize={32} gapSize={8} />
      case "multiple":
        if (users.length === 1) {
          return <UserInfoWrap data={users[0]} avatarSize={32} gapSize={8} />
        }
        return (
          <Avatar.Group
            maxCount={4}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              cursor: "pointer",
              width: 36,
              height: 36,
              display: "flex",
              alignItems: "center",
            }}
          >
            {users?.map((user, index) => (
              <UserInfoWrap key={index} data={user} avatarSize={32} />
            ))}
          </Avatar.Group>
        )

      default:
        return <></>
    }
  }
  return (
    <div className="c-table-editor-wrap no-select">
      {users.length !== 0 ? (
        <>
          {renderUsers()}
          <TooltipWrap title={moment(time).format("DD/MM/YYYY HH:mm:ss")}>
            <div className="action-time">
              <div className="clock-icon">
                <ClockCircleOutlined />
              </div>
              <span className="time">{moment(time).fromNow()}</span>
            </div>
          </TooltipWrap>
        </>
      ) : (
        t("COMMON.TABLE_EDITOR_WRAP.NO_DATA")
      )}
    </div>
  )
}
