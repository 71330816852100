import {
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import {
  IGetAllSliderTypesResponse,
  IGetSliderResponse,
} from "@/types/response"
import { SELECT_TYPE, UPLOAD_LIST_TYPE } from "@/utils/constants"
import { convertArrayToOptions, getFileUploadedObject } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"

interface IProps {
  data: IGetSliderResponse
  setIsLoadingUpload: (value: boolean) => void
  sliderTypeList?: IGetAllSliderTypesResponse
}

export const UpdateSlider: React.FC<IProps> = ({
  data,
  setIsLoadingUpload,
  sliderTypeList,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([])
  console.log("data", data)

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  const { mutate: uploadFile, isLoading } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return { ...file, url: response?.data?.path, status: "done" }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeFile = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
      }

      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, tempFile])

      uploadFile({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedFiles((prevUploadedFiles) =>
        prevUploadedFiles.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    setIsLoadingUpload(isLoading)
  }, [isLoading])

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_SLIDER,
      id: data.id,
      title: data.title,
      description: data?.description,
      image_path: data?.image_path,
      cta_text: data?.cta_text,
      link_to: data?.link_to,
      type_id: data?.type_id,
      priority: data.priority,
      is_displayed: data.is_displayed,
    })

    data?.image_path &&
      setUploadedFiles([getFileUploadedObject(data?.image_path)])
  }, [data])

  useEffect(() => {
    setValue("image_path", uploadedFiles[0]?.url)
  }, [uploadedFiles])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tiêu đề"
        required
        validateStatus={errors?.title ? "error" : ""}
        help={<>{errors?.title && errors?.title?.message}</>}
      >
        <Controller
          name="title"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: Đen Vâu FC trên Facebook"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Mô tả">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextAreaWrap
              placeholder="Nhập mô tả"
              rows={6}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Hình ảnh">
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
          onChangeFiles={handleChangeFile}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          maxCount={1}
        />
      </FormItemWrap>
      <FormItemWrap label="Tiêu đề nút chuyển hướng">
        <Controller
          name="cta_text"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: Truy cập nhóm"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Liên kết chuyển hướng"
        validateStatus={errors?.link_to ? "error" : ""}
        help={<>{errors?.link_to && errors?.link_to?.message}</>}
      >
        <Controller
          name="link_to"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: https://denvaufc.vn"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Loại"
        required
        validateStatus={errors?.type_id ? "error" : ""}
        help={<>{errors?.type_id && errors?.type_id?.message}</>}
      >
        <Controller
          name="type_id"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn loại Slider"
              type={SELECT_TYPE.SLIDER_TYPE}
              options={convertArrayToOptions(sliderTypeList!, "id", "name")}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Thứ tự hiển thị"
        required
        validateStatus={errors?.priority ? "error" : ""}
        help={<>{errors?.priority && errors?.priority?.message}</>}
      >
        <Controller
          name="priority"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap placeholder="Nhập thứ tự" field={field} />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái hiển thị">
        <Controller
          name="is_displayed"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
