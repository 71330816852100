import {
  DatePickerWrap,
  FormItemWrap,
  InputWrap,
  RadioGroupWrap,
  SelectWrap,
  SwitchWrap,
  TimePickerWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import {
  IGetAllArtistResponse,
  IGetAllSongGroupsResponse,
  ISong,
} from "@/types/response"
import {
  SELECT_TYPE,
  UPLOAD_LIST_TYPE,
  songReleaseStatus,
} from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  getFileUploadedObject,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"
import moment from "moment"

interface IProps {
  data: ISong
  setIsLoadingUpload: (value: boolean) => void
  songGroupList?: IGetAllSongGroupsResponse
  artistList?: IGetAllArtistResponse
}

export const UpdateSong: React.FC<IProps> = ({
  data,
  setIsLoadingUpload,
  songGroupList,
  artistList,
}) => {
  const [uploadedAudios, setUploadedAudios] = useState<IUploadedFile[]>([])
  const [uploadedThumbnails, setUploadedThumbnails] = useState<IUploadedFile[]>(
    [],
  )
  const {
    control,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { mutate: uploadThumbnail, isLoading: isLoadingUploadThumbnail } =
    useMutation((body: IUploadFileRequest) => FileService.uploadImage(body), {
      onSuccess: (response) => {
        setUploadedThumbnails((prevUploadThumbnails) =>
          prevUploadThumbnails.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedThumbnails((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    })

  const { mutate: uploadAudio, isLoading: isLoadingUploadAudio } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadAudio(body),
    {
      onSuccess: (response) => {
        setUploadedAudios((prevUploadAudios) =>
          prevUploadAudios.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedAudios((prevUploadAudios) =>
          prevUploadAudios.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeThumbnail = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedThumbnails((prevUploadThumbnails) => [
        ...prevUploadThumbnails,
        tempFile,
      ])

      uploadThumbnail({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedThumbnails((prevUploadThumbnails) =>
        prevUploadThumbnails.filter(
          (uploadFile) => uploadFile.uid !== file.uid,
        ),
      )
    }
  }

  const handleChangeAudio = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedAudios((prevUploadedAudios) => [
        ...prevUploadedAudios,
        tempFile,
      ])

      uploadAudio({
        file,
        folder: "audios",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedAudios((prevUploadedAudios) =>
        prevUploadedAudios.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_SONG,
      id: data.id,
      name: data.name,
      thumbnail_path: data?.thumbnail_path,
      description: data?.description,
      group_id: data?.songGroup?.id,
      artist_ids: data?.artists.map((obj) => obj.id),
      day_release: data?.day_release ? moment(data?.day_release) : null,
      time_release: data?.time_release
        ? moment(data?.time_release, "HH:mm")
        : null,
      file_id: data?.file?.id,
      status: data.status,
      is_displayed: data.is_displayed,
      is_warning: data.is_warning,
    })

    data?.thumbnail_path &&
      setUploadedThumbnails([getFileUploadedObject(data?.thumbnail_path)])
  }, [data])

  useEffect(() => {
    const isLoading = isLoadingUploadThumbnail || isLoadingUploadAudio
    setIsLoadingUpload(isLoading)
  }, [isLoadingUploadThumbnail, isLoadingUploadAudio])

  useEffect(() => {
    setValue("thumbnail_path", uploadedThumbnails[0]?.url)
  }, [uploadedThumbnails])

  useEffect(() => {
    setValue("file_id", uploadedAudios[0]?.id)
  }, [uploadedAudios])
  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên bài hát"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên bài hát"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Mô tả">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextAreaWrap
              placeholder="Nhập mô tả"
              rows={6}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Ngày phát hành"
        required
        validateStatus={errors?.day_release ? "error" : ""}
        help={<>{errors?.day_release && errors?.day_release?.message}</>}
      >
        <Controller
          name="day_release"
          control={control}
          render={({ field }) => (
            <DatePickerWrap
              placeholder="Chọn ngày"
              format="DD/MM/YYYY"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Giờ phát hành">
        <Controller
          name="time_release"
          control={control}
          render={({ field }) => (
            <TimePickerWrap
              placeholder="Chọn giờ"
              format="HH:mm"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Thumbnail">
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
          onChangeFiles={handleChangeThumbnail}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedThumbnails}
          setUploadedThumbnails={setUploadedThumbnails}
          maxCount={1}
          accept="image/*"
        />
      </FormItemWrap>
      <FormItemWrap label="Tệp âm thanh">
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE}
          onChangeFiles={handleChangeAudio}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedAudios}
          setUploadedThumbnails={setUploadedThumbnails}
          maxCount={1}
          accept="audio/*"
        />
      </FormItemWrap>
      <FormItemWrap
        label="Nghệ sĩ"
        required
        validateStatus={errors?.artist_ids ? "error" : ""}
        help={<>{errors?.artist_ids && errors?.artist_ids?.message}</>}
      >
        <Controller
          name="artist_ids"
          control={control}
          render={({ field }) => (
            <SelectWrap
              allowClear
              onClear={() => setValue("artist_ids", "")}
              placeholder="Chọn nghệ sĩ"
              mode="multiple"
              type={SELECT_TYPE.ARTIST}
              options={convertArrayToOptions(artistList!, "id", "stage_name")}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Nhóm">
        <Controller
          name="group_id"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn nhóm"
              type={SELECT_TYPE.SONG_GROUP}
              options={convertArrayToOptions(songGroupList!, "id", "name")}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái phát hành" required>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <RadioGroupWrap
              options={convertObjectToArray(songReleaseStatus)}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái hiển thị" required>
        <Controller
          name="is_displayed"
          control={control}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Cảnh báo ngôn từ" required>
        <Controller
          name="is_warning"
          control={control}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
