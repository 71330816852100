import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_SONG || modalName === MODAL.UPDATE_SONG) &&
      yup.string().required("Vui lòng nhập Tên bài hát"),
    artist_ids:
      (modalName === MODAL.CREATE_SONG || modalName === MODAL.UPDATE_SONG) &&
      yup.array().required("Vui lòng chọn Nghệ sĩ"),
    day_release:
      (modalName === MODAL.CREATE_SONG || modalName === MODAL.UPDATE_SONG) &&
      yup.string().required("Vui lòng chọn Ngày phát hành"),
  } as any)
