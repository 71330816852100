import {
  ISendEmailAllRequest,
  ISendEmailToAllUserRequest,
  ISendOneEmailRequest,
} from "@/types/request"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Email {
  sendOneEmail = (body: ISendOneEmailRequest) => {
    return http.post(ENDPOINT.EMAIL.SEND_ONE_EMAIL, body)
  }

  sendEmailAll = (body: ISendEmailAllRequest) => {
    return http.post(ENDPOINT.EMAIL.SEND_EMAIL_ALL, body)
  }

  sendEmailToAllUser = (body: ISendEmailToAllUserRequest) => {
    return http.post(ENDPOINT.EMAIL.SEND_EMAIL_TO_ALL_USER, body)
  }
}

export const EmailService = new Email()
