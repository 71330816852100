import { IArtistInfo } from "@/types/common"
import { Avatar } from "antd"
import { ArtistInfoWrap } from "../ArtistInfoWrap"
import "./style.scss"

export enum ARTIST_LIST_TYPE {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

interface IProps {
  artists: IArtistInfo[]
  type: "single" | "multiple"
}
export const ArtistListWrap: React.FC<IProps> = ({ artists, type }) => {
  const renderArtists = () => {
    switch (type) {
      case "single":
        return (
          <ArtistInfoWrap
            data={artists[0]}
            avatarSize={24}
            isShowText={false}
          />
        )
      case "multiple":
        if (artists.length === 1) {
          return (
            <ArtistInfoWrap
              data={artists[0]}
              avatarSize={24}
              isShowText={false}
            />
          )
        }
        return (
          <Avatar.Group
            maxCount={4}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              cursor: "pointer",
              width: 36,
              height: 36,
              display: "flex",
              alignItems: "center",
            }}
          >
            {artists?.map((artist, index) => (
              <ArtistInfoWrap
                key={index}
                data={artist}
                avatarSize={24}
                isShowText={false}
              />
            ))}
          </Avatar.Group>
        )

      default:
        return <></>
    }
  }
  return <div className="c-artist-list-wrap no-select">{renderArtists()}</div>
}
