import {
  CKEditorWrap,
  CascaderWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
  SpinWrap,
  SwitchWrap,
} from "@/components/commons"
import {
  EmailAccountService,
  EmailCategoryService,
  EmailTemplateService,
} from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import {
  EMAIL_TEMPLATE_TYPE,
  SELECT_TYPE,
  templateEmailOptions,
} from "@/utils/constants"
import { convertArrayToOptions } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useQuery } from "react-query"

export const EmailInformation = () => {
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  const [emailTemplateOptions, setEmailTemplateOptions] =
    useState(templateEmailOptions)

  const { data: accountList, isFetching: isFetchingAccountList } = useQuery(
    [ENDPOINT.EMAIL_ACCOUNT.GET_ALL],
    () => EmailAccountService.getAll(),
  )

  const {
    data: templateWithoutCategory,
    refetch: refetchTemplateWithoutCategory,
    isFetching: isFetchingTemplateWithoutCategory,
  } = useQuery(
    [ENDPOINT.EMAIL_TEMPLATE.GET_ALL_WITHOUT_CATEGORY],
    () => EmailTemplateService.getAllWithoutCategory(),
    { enabled: false },
  )

  const {
    data: templateByYear,
    refetch: refetchTemplateByYear,
    isFetching: isFetchingTemplateByYear,
  } = useQuery(
    [ENDPOINT.EMAIL_TEMPLATE.GET_ALL_BY_YEAR],
    () => EmailTemplateService.getAllByYear(),
    { enabled: false },
  )

  const {
    data: templateByCategory,
    refetch: refetchTemplateByCategory,
    isFetching: isFetchingTemplateByCategory,
  } = useQuery(
    [ENDPOINT.EMAIL_CATEGORY.GET_ALL],
    () => EmailCategoryService.getAll({ is_show_template: true }),
    { enabled: false },
  )

  const handleEmailContent = (data: string) => {
    setValue("content", data)
  }

  const handleLoadData = (selectedOptions: any) => {
    const selectedOption = selectedOptions[0]
    if (selectedOption?.value === EMAIL_TEMPLATE_TYPE.OTHER) {
      selectedOption.loading = isFetchingTemplateWithoutCategory
      !templateWithoutCategory && refetchTemplateWithoutCategory()
      selectedOption.children = templateWithoutCategory?.data.map(
        (template) => ({
          value: Number(template?.id),
          label: template?.subject,
          content: template?.content,
          is_attach_ticket: template?.is_attach_ticket,
        }),
      )
      selectedOption.loading = isFetchingTemplateWithoutCategory
      setEmailTemplateOptions([...emailTemplateOptions])
    }
    if (selectedOption?.value === EMAIL_TEMPLATE_TYPE.EVENT) {
      selectedOption.loading = isFetchingTemplateByYear
      !templateByYear && refetchTemplateByYear()
      selectedOption.children = templateByYear?.data?.map((item) => ({
        value: "year-" + item?.year,
        label: item?.year,
        disabled: item?.events?.length > 0 ? false : true,
        isLeaf: item?.events?.length > 0 ? false : true,
        children: item?.events?.map((event) => ({
          value: "event-" + event?.id,
          label: event?.name,
          disabled: event?.templates?.length > 0 ? false : true,
          children: event?.templates?.map((template) => ({
            value: Number(template?.id),
            label: template?.subject,
            content: template?.content,
            is_attach_ticket: template?.is_attach_ticket,
          })),
        })),
      }))
      setEmailTemplateOptions([...emailTemplateOptions])
    }
    if (selectedOption?.value === EMAIL_TEMPLATE_TYPE.CATEGORY) {
      selectedOption.loading = isFetchingTemplateByCategory
      !templateByCategory && refetchTemplateByCategory()
      selectedOption.children = templateByCategory?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
        disabled: item?.templates?.length > 0 ? false : true,
        isLeaf: item?.templates?.length > 0 ? false : true, // false: có mũi tên, true: không có mũi tên
        children: item?.templates?.map((template) => ({
          value: Number(template?.id),
          label: template?.subject,
          content: template?.content,
          is_attach_ticket: template?.is_attach_ticket,
        })),
      }))
      setEmailTemplateOptions([...emailTemplateOptions])
    }
  }

  const handleChange = async (value: string[], selectedOptions: any) => {
    const currentTemplateType = value[0]

    if (currentTemplateType === EMAIL_TEMPLATE_TYPE.OTHER) {
      selectedOptions[1] &&
        reset({
          ...getValues(),
          subject: selectedOptions[1]?.label,
          content: selectedOptions[1]?.content,
          is_attach_ticket: selectedOptions[1]?.is_attach_ticket,
        })
    } else if (currentTemplateType === EMAIL_TEMPLATE_TYPE.CATEGORY) {
      selectedOptions[2] &&
        reset({
          ...getValues(),
          subject: selectedOptions[2]?.label,
          content: selectedOptions[2]?.content,
          is_attach_ticket: selectedOptions[2]?.is_attach_ticket,
        })
    } else if (currentTemplateType === EMAIL_TEMPLATE_TYPE.EVENT) {
      selectedOptions[3] &&
        reset({
          ...getValues(),
          subject: selectedOptions[3]?.label,
          content: selectedOptions[3]?.content,
          is_attach_ticket: selectedOptions[3]?.is_attach_ticket,
        })
    }
  }

  return (
    <SpinWrap tip="Đang tải" spinning={isFetchingAccountList}>
      <FormItemWrap
        label="Gửi bằng"
        required
        validateStatus={errors?.account_ids ? "error" : ""}
        help={<>{errors?.account_ids && errors?.account_ids?.message}</>}
      >
        <Controller
          name="account_ids"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn email"
              type={SELECT_TYPE.FROM_EMAIL}
              mode="multiple"
              options={convertArrayToOptions(
                accountList?.data!,
                "id",
                "username",
              )}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Mẫu có sẵn">
        <Controller
          name="email_template"
          control={control}
          render={({ field }) => (
            <CascaderWrap
              placeholder="Chọn mẫu"
              options={emailTemplateOptions}
              loadData={handleLoadData}
              onChange={handleChange}
              changeOnSelect
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Tiêu đề"
        required
        validateStatus={errors?.subject ? "error" : ""}
        help={<>{errors?.subject && errors?.subject?.message}</>}
      >
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: Thông báo đăng ký vé thành công sự kiện Honda Thanks Day 2023"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Nội dung"
        required
        validateStatus={errors?.content ? "error" : ""}
        help={<>{errors?.content && errors?.content?.message}</>}
      >
        <Controller
          name="content"
          control={control}
          render={() => (
            <CKEditorWrap
              defaultValue={formMonitor?.content || ""}
              onChange={handleEmailContent}
            />
          )}
        />
      </FormItemWrap>
    </SpinWrap>
  )
}
