import { IArtistInfo } from "@/types/common"
import { IMAGE_TYPE } from "@/utils/constants"
import { getImage, notificationModal, replaceStrings, t } from "@/utils/helpers"
import { useNavigate } from "react-router-dom"
import { TooltipWrap } from "../TooltipWrap"
import "./style.scss"

interface IProps {
  data: IArtistInfo
  onOk?: () => void
  isAccessProfile?: boolean
  isShowAvatar?: boolean
  isShowText?: boolean
  textSize?: string | number
  textColor?: string
  textWeight?: number
  avatarSize?: string | number
  gapSize?: string | number
}

export const ArtistInfoWrap: React.FC<IProps> = ({
  isShowAvatar = true,
  isShowText = true,
  textSize = 14,
  textColor = "black",
  textWeight = 400,
  avatarSize = 36,
  gapSize = 4,
  data,
  onOk,
  isAccessProfile = true,
}) => {
  const navigate = useNavigate()

  const onClickToArtist = (data: IArtistInfo) => {
    return notificationModal({
      type: "confirm",
      className: "c-event-popup",
      content: (
        <>
          {replaceStrings(t("COMMON.ARTIST_INFO_WRAP.CONTENT"), {
            user: data?.stage_name,
          })}
        </>
      ),
      okText: t("COMMON.ARTIST_INFO_WRAP.BUTTON_OK"),
      cancelText: t("COMMON.ARTIST_INFO_WRAP.BUTTON_CANCEL"),
      onOk: () => onRedirectToProfile(data),
    })
  }

  const onRedirectToProfile = (data: IArtistInfo) => {
    onOk && onOk()
    navigate(`/artist/${data?.slug}`)
  }

  const artistRender = (data: IArtistInfo) => {
    const title = (
      <div className="artist-hover">
        <div className="avatar">
          <img
            src={getImage(data?.avatar_path, IMAGE_TYPE.AVATAR)}
            alt={data?.stage_name}
          />
        </div>
        <div className="info">
          <span className="stage-name">{data?.stage_name}</span>
          <span className="real-name">{data?.real_name}</span>
        </div>
      </div>
    )
    return (
      <TooltipWrap placement="top" title={title}>
        <div
          className="artist-show"
          style={{
            gap: gapSize,
          }}
          onClick={() => isAccessProfile && onClickToArtist(data)}
        >
          {isShowAvatar && (
            <div
              className="avatar"
              style={{
                width: avatarSize,
                height: avatarSize,
              }}
            >
              <img
                src={getImage(data?.avatar_path, IMAGE_TYPE.AVATAR)}
                alt={data?.stage_name}
              />
            </div>
          )}
          {isShowText && (
            <div className="info">
              <span
                className="name"
                style={{
                  color: textColor,
                  fontSize: textSize,
                  fontWeight: textWeight,
                }}
              >
                {data?.stage_name}
              </span>
            </div>
          )}
        </div>
      </TooltipWrap>
    )
  }

  return <div className="artist-info-wrap">{artistRender(data)}</div>
}
