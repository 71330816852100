import { FormItemWrap, SwitchWrap, TableEditorWrap } from "@/components/commons"
import { IGetPartnerResponse } from "@/types/response/partner"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"

interface IViewPartnerDetailProps {
  record: IGetPartnerResponse
}

export const ViewPartnerDetail: React.FC<IViewPartnerDetailProps> = ({
  record,
}) => {
  const { name, description, slug, priority, is_displayed } = record

  return (
    <div className="c-view-partner-detail">
      <Form layout="vertical">
        <FormItemWrap label="Tên đối tác">{name}</FormItemWrap>
        <FormItemWrap label="Mô tả">{description}</FormItemWrap>
        <FormItemWrap label="Slug">{slug}</FormItemWrap>
        <FormItemWrap label="Thứ tự hiển thị">{priority}</FormItemWrap>
        <FormItemWrap label="Trạng thái hiển thị">
          <SwitchWrap
            defaultValue={is_displayed}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled
          />
        </FormItemWrap>
        <FormItemWrap label="Người khởi tạo">
          <TableEditorWrap
            users={[record.created_by]}
            time={record.created_at}
            type="single"
          />
        </FormItemWrap>
        <FormItemWrap label="Người chỉnh sửa">
          <TableEditorWrap
            users={record.updated_by}
            time={record.updated_at}
            type="multiple"
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
