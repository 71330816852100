import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_EVENT || modalName === MODAL.UPDATE_EVENT) &&
      yup.string().required("Vui lòng nhập Tên sự kiện"),
    time_organizing:
      (modalName === MODAL.CREATE_EVENT || modalName === MODAL.UPDATE_EVENT) &&
      yup.mixed().required("Vui lòng nhập Thời gian tổ chức!"),
    type_id:
      (modalName === MODAL.CREATE_EVENT || modalName === MODAL.UPDATE_EVENT) &&
      yup.number().required("Vui lòng chọn Phân loại!"),
    attached_ticket_type:
      (modalName === MODAL.CREATE_EVENT || modalName === MODAL.UPDATE_EVENT) &&
      yup.number().required("Vui lòng chọn Phân loại!"),
    // attached_ticket_template: yup.string().when("attached_ticket_type", {
    //   is: 2,
    //   then: yup.number().required("Vui lòng nhập Tên tệp chứa mẫu"),
    // } as any),
  } as any)
