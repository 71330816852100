import {
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SwitchWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

export const CreateEventPosition: React.FC = () => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.CREATE_EVENT_POSITION,
      price: 0,
      quantity: 0,
      priority: 0,
      is_displayed: true,
    })
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên hạng vé"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên hạng vé"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Mô tả">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextAreaWrap
              placeholder="Nhập mô tả"
              rows={6}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Giá"
        required
        validateStatus={errors?.price ? "error" : ""}
        help={<>{errors?.price && errors?.price?.message}</>}
      >
        <Controller
          name="price"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap
              placeholder="Nhập giá"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              addonAfter="đ"
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Số lượng"
        required
        validateStatus={errors?.quantity ? "error" : ""}
        help={<>{errors?.quantity && errors?.quantity?.message}</>}
      >
        <Controller
          name="quantity"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap
              addonAfter="vé"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              placeholder="Nhập số lượng"
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Thứ tự hiển thị"
        required
        validateStatus={errors?.priority ? "error" : ""}
        help={<>{errors?.priority && errors?.priority?.message}</>}
      >
        <Controller
          name="priority"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              placeholder="Nhập số lượng"
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái hiển thị" required>
        <Controller
          name="is_displayed"
          control={control}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
