import { ButtonWrap } from "@/components/commons"
import { t } from "@/utils/helpers"
import { Result } from "antd"
import "./style.scss"
import { useNavigate } from "react-router-dom"
import { APP_ROUTER } from "@/routes"

export const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className="p-404">
      <Result
        status="404"
        title={t("P_NOTFOUND.TITLE")}
        subTitle={t("P_NOTFOUND.DESCRIPTION")}
        extra={
          <ButtonWrap type="primary" onClick={() => navigate(APP_ROUTER.HOME)}>
            {t("P_NOTFOUND.BUTTON_GO_HOME")}
          </ButtonWrap>
        }
      />
    </div>
  )
}
