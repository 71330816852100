import { InputNumber, InputNumberProps } from "antd"
import _ from "lodash"
import { useState } from "react"

export const InputCellWrap: React.FC<InputNumberProps> = ({
  className,
  onChange,
  defaultValue,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const specialCharRegex = new RegExp("[0-9]")
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (!specialCharRegex.test(pressedKey) || pressedKey === " ") {
      e.preventDefault()
      return
    }
  }

  const handleChange = _.debounce((e) => {
    if (e !== null) {
      setCurrentValue(e)
      onChange && onChange(e)
      return
    }
    setCurrentValue(defaultValue)
  }, 500)

  return (
    <InputNumber
      className={className}
      onKeyPress={onKeyPress}
      inputMode="none"
      controls={false}
      pattern="[0-9]*"
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
      value={currentValue}
      onChange={(e) => handleChange(e)}
      {...props}
    />
  )
}
