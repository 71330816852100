import {
  ButtonWrap,
  ModalWrap,
  PaginationWrap,
  TableWrap,
} from "@/components/commons"
import { ErrorCircleIcon, PencilIcon, VerifyIcon } from "@/components/icons"
import { UserService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState, ISendEmailToAllUserCondition } from "@/types/common"
import { IUser } from "@/types/response"
import {
  IMAGE_TYPE,
  USER_ACCOUNT_TYPES,
  userAccountTypes,
} from "@/utils/constants"
import { convertRequest, getImage, t } from "@/utils/helpers"
import { Divider } from "antd"
import { ColumnsType } from "antd/es/table"
import { useEffect, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useQuery, useQueryClient } from "react-query"
import { ChangeCondition } from "../ChangeCondition"
import "./style.scss"

const ConfirmReceiver = () => {
  const emailConditionRef = useRef<any>(null)
  const { control, setValue, getValues, reset } = useFormContext()
  const formMonitor = useWatch({
    control,
  })

  const [params, setParams] = useState({
    current_page: 1,
    page_size: 3,
    account_type: formMonitor.account_type,
  })

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setParams({
      ...params,
      current_page: currentPage,
    })
  }

  const { data: userList, isFetching: isFetchingUserList } = useQuery(
    [ENDPOINT.USER.GET_LIST, params],
    () => UserService.getList(convertRequest(params)),
    {
      enabled: !!params?.account_type,
      onSuccess: (response: any) => {},
    },
  )

  const handleChangeCondition = (data: ISendEmailToAllUserCondition) => {
    emailConditionRef.current = data
  }

  const handleSaveCondition = () => {
    setValue("account_type", emailConditionRef.current?.account_type)
    handleCancelModal()
  }

  const getAccountTypeLabel = (value: number) => {
    let icon = <></>
    switch (value) {
      case USER_ACCOUNT_TYPES.ALL:
      case USER_ACCOUNT_TYPES.USER:
      case USER_ACCOUNT_TYPES.ADMIN:
        icon = <VerifyIcon fillColor="var(--color-green-1)" />
        break
      case USER_ACCOUNT_TYPES.DEACTIVATED:
        icon = <ErrorCircleIcon fillColor="var(--color-red-0)" />
        break
      default:
        icon = <></>
        break
    }

    return (
      <div className="column-condition no-select">
        {icon} {userAccountTypes[value]}
      </div>
    )
  }

  const receiverColumns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "40px",
      align: "center",
    },
    {
      title: "Tên",
      width: "180px",
      render: (record: IUser) => (
        <div className="column-name">
          <div className="avatar">
            <img
              src={getImage(record?.avatar_path, IMAGE_TYPE.AVATAR)}
              alt={record.full_name}
            />
          </div>
          <div className="right">
            <span className="name">{record.full_name}</span>
            <span className="username">@{record.username}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "120px",
      render: (value) => value,
    },
  ]

  const conditionData = [
    {
      id: 1,
      account_type: formMonitor?.account_type,
    },
  ]

  let conditionColumns: any = [
    {
      title: "Loại tài khoản",
      width: "100px",
      align: "center",
      dataIndex: "account_type",
      render: (value: number) => getAccountTypeLabel(value),
    },
  ]

  const handleCancelModal = () => {
    setModal({
      open: false,
      width: 608,
      bodyHeight: 588,
      children: <></>,
    })
  }

  const handleShowModal = () => {
    setModal({
      open: true,
      title: t("P_TICKET_FAN.TITLE_CHANGE_CONDITION_CONFIRM_RECEIVER"),
      width: 500,
      okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
      onOk: handleSaveCondition,
      onCancel: handleCancelModal,
      children: (
        <ChangeCondition
          handleChangeCondition={handleChangeCondition}
          emailCondition={{
            account_type: formMonitor?.account_type,
          }}
        />
      ),
    })
  }

  useEffect(() => {
    reset({
      ...getValues(),
      account_type: USER_ACCOUNT_TYPES.ALL,
    })
  }, [])

  useEffect(() => {
    setParams({
      ...params,
      account_type: formMonitor.account_type,
    })
  }, [formMonitor.account_type])

  return (
    <div className="c-confirm-content">
      <Divider orientation="center" plain>
        Điều kiện gửi
      </Divider>
      <TableWrap
        rowKey="id"
        columns={conditionColumns}
        dataSource={conditionData}
        pagination={false}
        loading={false}
      />
      <div className="change-condition">
        <ButtonWrap
          type="primary"
          onClick={handleShowModal}
          icon={
            <PencilIcon
              fillColor="var(--color-neutral-1)"
              width={18}
              height={18}
            />
          }
        >
          Thay đổi điều kiện
        </ButtonWrap>
      </div>
      <Divider orientation="center" plain>
        Danh sách người nhận
      </Divider>
      <TableWrap
        rowKey="id"
        columns={receiverColumns}
        dataSource={userList?.data}
        pagination={false}
        loading={isFetchingUserList}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params?.current_page}
        total={userList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap {...modal}>{modal.children}</ModalWrap>
    </div>
  )
}

export default ConfirmReceiver
