import { Helmet } from "react-helmet-async"

interface IHomeProps {
  title?: string
}

export const Home: React.FC<IHomeProps> = ({ title }) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </div>
  )
}
