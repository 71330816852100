import { FormItemWrap, InputWrap, SwitchWrap } from "@/components/commons"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

export const CreateEmailAccount: React.FC = () => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.CREATE_EMAIL_ACCOUNT,
      is_enabled: true,
    })
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên hiển thị"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên hiển thị"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Username"
        required
        validateStatus={errors?.username ? "error" : ""}
        help={<>{errors?.username && errors?.username?.message}</>}
      >
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <InputWrap placeholder="Nhập Username" field={field} allowClear />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái hiển thị" required>
        <Controller
          name="is_enabled"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
