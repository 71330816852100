import { FormItemWrap } from "@/components/commons"
import { IEventTicketPaymentCustomize } from "@/types/response"
import {
  IMAGE_TYPE,
  TICKET_PAYMENT_STATUS,
  ticketPaymentColor,
  ticketPaymentStatus,
} from "@/utils/constants"
import { convertThousandsFormat, getImage } from "@/utils/helpers"
import { Form, Image, Tag } from "antd"
import moment from "moment"
import "./style.scss"

interface IProps {
  data: IEventTicketPaymentCustomize
}

export const ViewPaymentDetail: React.FC<IProps> = ({ data }) => {
  const {
    transaction_code,
    booking_date,
    value_date,
    description,
    amount,
    currency,
    bill_path,
    status,
    created_at,
  } = data

  return (
    <div className="c-view-payment-detail">
      <Form layout="vertical">
        {status === TICKET_PAYMENT_STATUS.PAID && (
          <>
            <FormItemWrap label="Mã giao dịch">{transaction_code}</FormItemWrap>
            <FormItemWrap label="Nội dung chuyển khoản">
              {description}
            </FormItemWrap>
            <FormItemWrap label="Số tiền">
              {convertThousandsFormat(Number(amount))} {currency}
            </FormItemWrap>
            <FormItemWrap label="Ngày thực hiện">
              {moment(booking_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
            </FormItemWrap>
            <FormItemWrap label="Ngày hiệu lực">
              {moment(value_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
            </FormItemWrap>
          </>
        )}
        <FormItemWrap label="Thời gian yêu cầu xác minh">
          {moment(created_at).format("DD/MM/YYYY HH:mm:ss")}
        </FormItemWrap>
        <FormItemWrap label="Trạng thái">
          <Tag color={ticketPaymentColor[status]}>
            {ticketPaymentStatus[status]}
          </Tag>
        </FormItemWrap>
        <FormItemWrap label="Hình ảnh">
          <div className="field-image">
            <Image
              width={112}
              src={getImage(bill_path, IMAGE_TYPE.THUMBNAIL)}
            />
          </div>
        </FormItemWrap>
      </Form>
    </div>
  )
}
