import { ModalWrap, PaginationWrap, TableWrap } from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ENDPOINT } from "@/services/endpoint"
import { PermissionService } from "@/services/permission"
import { PermissionGroupService } from "@/services/permissionGroup"
import { IModalState } from "@/types/common"
import {
  ICreatePermissionRequest,
  IUpdatePermissionRequest,
} from "@/types/request/permission"
import { IGetSliderResponse } from "@/types/response"
import { IGetPartnerResponse } from "@/types/response/partner"
import { IPermission } from "@/types/response/permission"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreatePermission, UpdatePermission, ViewSliderDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const Permission: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    display_name: searchParams.get("display_name"),
    group_id: searchParams.get("group_id"),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
      is_displayed: true,
    },
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetSliderResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreatePermission = (values: any) => {
    const body: ICreatePermissionRequest = {
      name: values.name,
      display_name: values.display_name,
      group_id: values?.group_id,
    }

    createPermission(body)
  }

  const handleUpdatePermission = (values: any) => {
    const body: IUpdatePermissionRequest = {
      display_name: values.display_name,
      group_id: values.group_id,
    }

    updatePermission({ id: values.id, body })
  }

  const handleDeleteOnePermission = (values: any) => {
    forceDeleteOne(values.id)
  }

  const handleDeleteManyPermission = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IPermission) => {
    switch (type) {
      case MODAL.CREATE_PERMISSION:
        setModal({
          open: true,
          title: t("P_PERMISSION.TITLE_CREATE_PERMISSION"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreatePermission),
          onCancel: hanldeCancelModal,
          children: (
            <CreatePermission
              permissionGroupList={permissionGroupList?.data!}
            />
          ),
        })
        break
      case MODAL.UPDATE_PERMISSION:
        setModal({
          open: true,
          title: t("P_PERMISSION.TITLE_UPDATE_PERMISSION"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdatePermission),
          onCancel: hanldeCancelModal,
          children: (
            <UpdatePermission
              data={record!}
              permissionGroupList={permissionGroupList?.data!}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOnePermission),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyPermission),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SLIDER.TITLE_DETAIL_SLIDER"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSliderDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: permissionList,
    refetch: refetchPermissionList,
    isFetching: isFetchingPermissionList,
  } = useQuery([ENDPOINT.PERMISSION.GET_LIST, params], () =>
    PermissionService.getList(convertRequest(params)),
  )

  const {
    data: permissionGroupList,
    isFetching: isFetchingPermissionGroupList,
  } = useQuery([ENDPOINT.PERMISSION_GROUP.GET_ALL, params], () =>
    PermissionGroupService.getAll(),
  )

  const { mutate: createPermission, isLoading: isLoadingCreatePermission } =
    useMutation(
      (body: ICreatePermissionRequest) => PermissionService.create(body),
      {
        onSuccess: () => {
          refetchPermissionList()
          handleModalContent(MODAL.NOT_SHOW)
          setSearchParams({
            ...convertToStringObject(params),
            current_page: String(1),
          })
          notificationMessage({
            message: "Thêm thành công",
            type: "success",
          })
          reset({
            name: "",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updatePermission } = useMutation(
    (payload: { id: number; body: IUpdatePermissionRequest }) =>
      PermissionService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPermissionList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          name: "",
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: forceDeleteOne } = useMutation(
    (id: number) => PermissionService.forceDeleteOne(id),
    {
      onSuccess: () => {
        refetchPermissionList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => PermissionService.forceDeleteMany(ids),
    {
      onSuccess: () => {
        refetchPermissionList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IGetPartnerResponse> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "40px",
      align: "center",
    },
    {
      title: "Tên quyền",
      dataIndex: "display_name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Key",
      dataIndex: "name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Nhóm quyền",
      dataIndex: "group",
      width: "160px",
      align: "center",
      render: (value) => {
        return value && <Tag color="var(--color-green-1)">{value?.name}</Tag>
      },
    },
    {
      title: "Action",
      width: "82px",
      align: "center",
      fixed: "right",
      render: (record: IPermission) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-slider-type">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
        permissionGroupList={permissionGroupList?.data!}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={permissionList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingPermissionList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={permissionList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreatePermission}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
