import { Modal, notification } from "antd"
import _, { isArray } from "lodash"
import { v4 as uuidv4 } from "uuid"
import { EVENT_FORM_TYPE_ENUMS, IMAGE_TYPE, SERVER_URL } from "../constants"
import text from "./text.json"
import moment from "moment"
import { IEventFormCustomize } from "@/types/response/event"

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

type Texts = {
  [key: string]: string | Texts
}

interface Option {
  value: number | string
  label: string
}

interface INotificationModal {
  title?: string
  content: string | React.ReactNode
  okText?: string
  cancelText?: string
  onOk?: (close: () => void) => void
  onCancel?: () => void
  type: "confirm" | "warning" | "info" | "error"
  maskClosable?: boolean
  keyboard?: boolean
  width?: string | number
  icon?: React.ReactNode
  className?: string
}

interface INotificationMessage {
  title?: string
  message: string
  type: "success" | "info" | "warning" | "error"
  duration?: number
  placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight"
}

type OptionsObject = Record<number | string, string>

export const getImage = (image: string | null, type: string) => {
  const blankLogo = "/images/blank_logo.png"
  const blankThumbnail = "/images/blank_thumbnail.png"
  const blankAvatar = "/images/blank_avatar.png"

  const getUrl = (path: string) => {
    return SERVER_URL + "/" + path
  }

  if (image?.includes("http") || image?.includes("https")) {
    return image
  }

  if (type === IMAGE_TYPE.THUMBNAIL) {
    return image ? getUrl(image) : blankThumbnail
  }

  if (type === IMAGE_TYPE.AVATAR) {
    return image ? getUrl(image) : blankAvatar
  }

  if (type === IMAGE_TYPE.LOGO) {
    return image ? getUrl(image) : blankLogo
  }

  return ""
}

export const getAvatarByGender = (gender: number) => {
  const maleAvatar = "/images/male_avatar.png"
  const femaleAvatar = "/images/female_avatar.png"
  const unknownAvatar = "/images/unknown_avatar.png"
  switch (gender) {
    case 1:
      return maleAvatar
    case 2:
      return femaleAvatar
    default:
      return unknownAvatar
  }
}

export const t = (key: string): string => {
  const keyPaths = key.split(".")
  let result: string | Texts = text

  for (const element of keyPaths) {
    if (result && typeof result === "object" && element in result) {
      result = result[element]
    } else {
      return ""
    }
  }

  if (typeof result === "object") {
    return ""
  }

  return result as string
}

export const notificationModal = ({
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
  type,
  maskClosable = true,
  keyboard = true,
  width,
  icon,
  className,
}: INotificationModal) => {
  return Modal[type]({
    title,
    content,
    okText: okText || t("COMMON.BUTTON_OK"),
    cancelText: cancelText || t("COMMON.BUTTON_CANCEL"),
    onOk,
    onCancel,
    centered: true,
    maskClosable,
    keyboard,
    width,
    icon,
    className,
  })
}

export const notificationMessage = ({
  title,
  message,
  type,
  duration,
  placement,
}: INotificationMessage) => {
  return notification[type]({
    message: title,
    description: message,
    duration,
    placement,
  })
}

export const convertObjectToArray = (
  optionsObject: OptionsObject,
  idType: "number" | "string" = "number",
) => {
  return Object.entries(optionsObject).map(([value, label]) => ({
    value: idType === "number" ? parseInt(value) : value,
    label,
  }))
}

export const replaceStrings = (
  originalString: string,
  replacementObject: Record<string, string | number>,
): string => {
  for (const oldString in replacementObject) {
    if (replacementObject.hasOwnProperty(oldString)) {
      const newString = replacementObject[oldString]
      const searchString = `{{${oldString}}}`
      originalString = originalString
        .split(searchString)
        .join(String(newString))
    }
  }

  return originalString
}

export const convertStringToBoolean = (
  value: string | null,
): boolean | undefined => {
  if (value === "true") {
    return true
  } else if (value === "false") {
    return false
  } else {
    return undefined
  }
}

export const convertToStringObject = (value: object) => {
  return _.mapValues(value, (value) => String(value))
}

export const convertRequest = (value: object) => {
  return _.mapValues(value, (value) => (value === "" ? undefined : value))
}

export const getFileNameFromUrl = (url: string) => {
  return url.substring(url.lastIndexOf("/") + 1)
}

export const getFileUploadedObject = (url: string) => {
  return {
    uid: uuidv4(),
    name: getFileNameFromUrl(url),
    url,
    status: "done",
  }
}

export const convertArrayToOptions = (
  arrs: any[],
  valueKey: string,
  labelKey: string,
  idType: "number" | "string" = "number",
): Option[] => {
  const getValueById = (
    obj: any,
    idType: "number" | "string",
  ): number | string => {
    switch (idType) {
      case "number":
        return Number(obj[valueKey])
      case "string":
        return String(obj[valueKey])
      default:
        return Number(obj[valueKey])
    }
  }

  if (isArray(arrs)) {
    return arrs?.map((obj) => ({
      value: getValueById(obj, idType),
      label: obj[labelKey],
      original: { ...obj },
    }))
  }
  return []
}

export const truncate = (text: string, length: number) => {
  return _.truncate(text, {
    length: length,
    separator: " ",
  })
}

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (bytes === 0) return "0 Bytes"

  const k: number = 1024
  const dm: number = decimals < 0 ? 0 : decimals
  const sizes: string[] = [
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ]

  const i: number = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const getFileExtension = (fileName: string) => {
  return fileName.split(".").pop()
}

export function convertToCapitalize(text: string): string {
  return text.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())
}

export function convertOrganizingTime(
  start: string | Date,
  end: string | Date,
): string {
  const startTime = moment(start)
  const endTime = moment(end)

  if (startTime.isSame(endTime, "day")) {
    const formattedDate = startTime.format("DD/MM/YYYY") as `DD/MM/YYYY`
    const formattedTime = `${startTime.format("HH:mm")} - ${endTime.format(
      "HH:mm",
    )}` as `${string} - ${string}`
    return `${convertToCapitalize(formattedDate)} (${formattedTime})`
  } else {
    const startFormattedDate = startTime.format(
      `HH:mm, DD/MM/YYYY`,
    ) as `${string}, DD/MM/YYYY`
    const endFormattedDate = endTime.format(
      `HH:mm, DD/MM/YYYY`,
    ) as `${string}, DD/MM/YYYY`
    return `${startFormattedDate} - ${endFormattedDate}`
  }
}

export const getEventForm = (
  data: IEventFormCustomize[],
  type: EVENT_FORM_TYPE_ENUMS,
) => {
  return data.find((form) => form.type === type)
}

export const convertThousandsFormat = (number: number) => {
  return number?.toLocaleString("en-US")
}

export const exportExcelFile = ({ data, fileName }: any) => {
  console.log("data", data)

  const exportedFileName = fileName + ".xlsx" || "export.xlsx"
  const blob = new Blob([data])

  const link = document.createElement("a")
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)
    link.setAttribute("download", exportedFileName)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
