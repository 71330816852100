import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_EVENT_POSITION ||
        modalName === MODAL.UPDATE_EVENT_POSITION) &&
      yup.string().required("Vui lòng nhập Tên bài hát!"),
    price:
      (modalName === MODAL.CREATE_EVENT_POSITION ||
        modalName === MODAL.UPDATE_EVENT_POSITION) &&
      yup.number().required("Vui lòng nhập Giá!"),
    quantity:
      (modalName === MODAL.CREATE_EVENT_POSITION ||
        modalName === MODAL.UPDATE_EVENT_POSITION) &&
      yup.number().required("Vui lòng nhập Số lượng!"),
    priority:
      (modalName === MODAL.CREATE_EVENT_POSITION ||
        modalName === MODAL.UPDATE_EVENT_POSITION) &&
      yup.number().required("Vui lòng nhập Thứ tự hiển thị!"),
  } as any)
