import { Radio, RadioGroupProps } from "antd"
import { ControllerRenderProps } from "react-hook-form"

interface IProps extends RadioGroupProps {
  field?: ControllerRenderProps<any, any>
}

export const RadioGroupWrap: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <Radio.Group className={className} {...field} {...props} />
}
