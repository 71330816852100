import {
  ModalWrap,
  PaginationWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { EmailCategoryService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import {
  ICreateEmailCategoryRequest,
  IUpdateEmailCategoryRequest,
} from "@/types/request"
import { IEmailCategory } from "@/types/response"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import {
  CreateEmailCategory,
  UpdateEmailCategory,
  ViewSongDetail,
} from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const EmailCategory: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IEmailCategory> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateEmailCategory = (values: any) => {
    const body: ICreateEmailCategoryRequest = {
      name: values.name,
    }

    createEmailCategory(body)
  }

  const handleUpdateEmailCategory = (values: any) => {
    const body: IUpdateEmailCategoryRequest = {
      name: values.name,
    }

    updateEmailCategory({ id: values.id, body })
  }

  const handleDeleteOneEmailCategory = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyEmailCategory = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEmailCategory) => {
    switch (type) {
      case MODAL.CREATE_EMAIL_CATEGORY:
        setModal({
          open: true,
          title: t("P_EMAIL_CATEGORY.TITLE_CREATE_EMAIL_CATEGORY"),
          width: 608,
          bodyHeight: 80,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEmailCategory),
          onCancel: hanldeCancelModal,
          children: <CreateEmailCategory />,
        })
        break
      case MODAL.UPDATE_EMAIL_CATEGORY:
        setModal({
          open: true,
          title: t("P_EMAIL_CATEGORY.TITLE_UPDATE_EMAIL_CATEGORY"),
          width: 608,
          bodyHeight: 80,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateEmailCategory),
          onCancel: hanldeCancelModal,
          children: <UpdateEmailCategory data={record!} />,
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneEmailCategory),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyEmailCategory),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EMAIL_CATEGORY.TITLE_DETAIL_EVENT_TYPE"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: emailCategoryList,
    refetch: refetchEmailCategoryList,
    isFetching: isFetchingEmailCategoryList,
  } = useQuery([ENDPOINT.EMAIL_CATEGORY.GET_LIST, params], () =>
    EmailCategoryService.getList(convertRequest(params)),
  )

  const {
    mutate: createEmailCategory,
    isLoading: isLoadingCreateEmailCategory,
  } = useMutation(
    (body: ICreateEmailCategoryRequest) => EmailCategoryService.create(body),
    {
      onSuccess: () => {
        refetchEmailCategoryList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const {
    mutate: updateEmailCategory,
    isLoading: isLoadingUpdateEmailCategory,
  } = useMutation(
    (payload: { id: number; body: IUpdateEmailCategoryRequest }) =>
      EmailCategoryService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEmailCategoryList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EmailCategoryService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchEmailCategoryList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EmailCategoryService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchEmailCategoryList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEmailCategory> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEmailCategory) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-email-category">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={emailCategoryList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingEmailCategoryList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={emailCategoryList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEmailCategory || isLoadingUpdateEmailCategory}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
