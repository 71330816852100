export enum MODAL {
  CREATE_EVENT = "CreateEvent",
  UPDATE_EVENT = "UpdateEvent",
  PREVIEW_SONG = "PreviewSong",
  ROW_DETAIL = "RowDetail",
  SELECT_ADDRESS = "SelectAddress",
  CONFIRM_DELETE_ONE = "ConfirmDeleteOne",
  CONFIRM_DELETE_MANY = "ConfirmDeleteMany",
  NOT_SHOW = "NotShow",
}

export enum EVENT_FORM_KEY_ACTIVE_ENUMS {
  MAIN = "1",
  TICKET_FAN = "2",
  TICKET_TEAM = "3",
  JOIN_SUPPORT = "4",
  JOIN_MEDIA = "5",
  JOIN_TALENT = "6",
  ATTENDANCE = "7",
}
