import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    subject:
      (modalName === MODAL.CREATE_EMAIL_TEMPLATE ||
        modalName === MODAL.UPDATE_EMAIL_TEMPLATE) &&
      yup.string().required("Vui lòng nhập Tiêu đề"),
    content:
      (modalName === MODAL.CREATE_EMAIL_TEMPLATE ||
        modalName === MODAL.UPDATE_EMAIL_TEMPLATE) &&
      yup.string().required("Vui lòng nhập Nội dung"),
  } as any)
