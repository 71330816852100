export const ENDPOINT = {
  AUTH: {
    LOGIN: "/auth/login",
    LOGOUT: "/auth/logout",
    GET_ME: "/auth/get-me",
  },
  CONFIG: {
    GET_ALL: "/configs/get-all",
    UPDATE: "/configs/update",
  },
  PARTNER: {
    GET_LIST: "/partners",
    CREATE: "/partners",
    UPDATE: "/partners/:id",
    UPDATE_IS_DISPLAYED: "/partners/:id/update-is-displayed",
    UPDATE_PRIORITY: "/partners/:id/update-priority",
    SOFT_DELETE_ONE: "/partners/:id",
    SOFT_DELETE_MANY: "/partners/soft-delete-many",
  },
  SLIDER: {
    GET_LIST: "/sliders",
    CREATE: "/sliders",
    UPDATE: "/sliders/:id",
    UPDATE_IS_DISPLAYED: "/sliders/:id/update-is-displayed",
    UPDATE_PRIORITY: "/sliders/:id/update-priority",
    SOFT_DELETE_ONE: "/sliders/:id",
    SOFT_DELETE_MANY: "/sliders/soft-delete-many",
  },
  SLIDER_TYPE: {
    GET_ALL: "/slider-types/get-all",
    GET_LIST: "/slider-types",
    CREATE: "/slider-types",
    UPDATE: "/slider-types/:id",
    SOFT_DELETE_ONE: "/slider-types/:id",
    SOFT_DELETE_MANY: "/slider-types/soft-delete-many",
  },
  FILE: {
    UPLOAD_IMAGE: "/files/upload-image",
    UPLOAD_AUDIO: "/files/upload-audio",
  },
  SONG: {
    GET_LIST: "/songs",
    CREATE: "/songs",
    UPDATE: "/songs/:id",
    UPDATE_IS_DISPLAYED: "/songs/:id/update-is-displayed",
    UPDATE_IS_WARNING: "/songs/:id/update-is-warning",
    SOFT_DELETE_ONE: "/songs/:id",
    SOFT_DELETE_MANY: "/songs/soft-delete-many",
  },
  SONG_GROUP: {
    GET_ALL: "/song-groups/get-all",
    GET_LIST: "/song-groups",
    CREATE: "/song-groups",
    UPDATE: "/song-groups/:id",
    UPDATE_IS_DISPLAYED: "/song-groups/:id/update-is-displayed",
    SOFT_DELETE_ONE: "/song-groups/:id",
    SOFT_DELETE_MANY: "/song-groups/soft-delete-many",
  },
  ARTIST: {
    GET_ALL: "/artists/get-all",
    GET_LIST: "/artists",
    CREATE: "/artists",
    UPDATE: "/artists/:id",
    UPDATE_IS_DISPLAYED: "/artists/:id/update-is-displayed",
    SOFT_DELETE_ONE: "/artists/:id",
    SOFT_DELETE_MANY: "/artists/soft-delete-many",
  },
  PROVINCE: {
    GET_PROVINCES: "/provinces",
    GET_DISTRICTS: "/provinces/get-districts/:province_id",
    GET_WARDS: "/provinces/get-wards/:district_id",
  },
  EVENT: {
    GET_DETAIL: "/events/:id",
    GET_LIST: "/events",
    CREATE: "/events",
    UPDATE: "/events/:id",
    UPDATE_HAVE_DRINK: "/events/:id/update-have-drink",
    UPDATE_HAVE_PAYMENT: "/events/:id/update-have-payment",
    UPDATE_IS_DISPLAYED: "/events/:id/update-is-displayed",
    SOFT_DELETE_ONE: "/events/:id",
    SOFT_DELETE_MANY: "/events/soft-delete-many",
  },
  EVENT_TYPE: {
    GET_ALL: "/event-types/get-all",
    GET_LIST: "/event-types",
    CREATE: "/event-types",
    UPDATE: "/event-types/:id",
    SOFT_DELETE_ONE: "/event-types/:id",
    SOFT_DELETE_MANY: "/event-types/soft-delete-many",
  },
  EVENT_TICKET: {
    SEARCH: "/event-tickets/search",
    GET_LIST: "/event-tickets",
    CREATE: "/event-tickets",
    UPDATE: "/event-tickets/:id",
    VERIFY_PAYMENT: "/event-tickets/:id/verify-payment",
    ADD_PAYMENT_HISTORY: "/event-tickets/:id/add-payment-history",
    UPDATE_STATUS_SUCCESS: "/event-tickets/:id/update-status-success",
    UPDATE_IS_ACTIVATED: "/event-tickets/:id/update-is-activated",
    UPDATE_IS_ACTIVATED_MANY: "/event-tickets/update-is-activated-many",
    IMPORT: "/event-tickets/import",
    EXPORT_SAMPLE: "/event-tickets/export-sample",
    DOWNLOAD: "/event-tickets/download-text-file",
    SOFT_DELETE_ONE: "/event-tickets/:id",
    SOFT_DELETE_MANY: "/event-tickets/soft-delete-many",
  },
  EVENT_POSITON: {
    GET_ALL: "/event-positions/get-all",
    GET_LIST: "/event-positions",
    CREATE: "/event-positions",
    UPDATE: "/event-positions/:id",
    UPDATE_PRICE: "/event-positions/:id/update-price",
    UPDATE_QUANTITY: "/event-positions/:id/update-quantity",
    UPDATE_PRIORITY: "/event-positions/:id/update-priority",
    UPDATE_DISPLAYED: "/event-positions/:id/update-is-displayed",
    SOFT_DELETE_ONE: "/event-positions/:id",
    SOFT_DELETE_MANY: "/event-positions/soft-delete-many",
  },
  EMAIL: {
    SEND_ONE_EMAIL: "/emails/send-one-email",
    SEND_EMAIL_ALL: "/emails/send-email-all",
    SEND_EMAIL_TO_ALL_USER: "/emails/send-email-to-all-user",
  },
  EMAIL_ACCOUNT: {
    GET_ALL: "/email-accounts/get-all",
    GET_LIST: "/email-accounts",
    CREATE: "/email-accounts",
    UPDATE: "/email-accounts/:id",
    UPDATE_IS_ENABLED: "/email-accounts/:id/update-is-enabled",
    SOFT_DELETE_ONE: "/email-accounts/:id",
    SOFT_DELETE_MANY: "/email-accounts/soft-delete-many",
  },
  EMAIL_LOGS: {
    GET_LIST: "/email-logs",
  },
  EMAIL_TEMPLATE: {
    GET_ALL_WITHOUT_CATEGORY: "/email-templates/get-all-without-category",
    GET_ALL_BY_YEAR: "/email-templates/get-all-by-year",
    GET_LIST: "/email-templates",
    CREATE: "/email-templates",
    UPDATE: "/email-templates/:id",
    UPDATE_IS_ATTACH_TICKET: "/email-templates/:id/update-is-attach-ticket",
    UPDATE_IS_ENABLED: "/email-templates/:id/update-is-enabled",
    SOFT_DELETE_ONE: "/email-templates/:id",
    SOFT_DELETE_MANY: "/email-templates/soft-delete-many",
  },
  EMAIL_CATEGORY: {
    GET_ALL: "/email-categories/get-all",
    GET_LIST: "/email-categories",
    CREATE: "/email-categories",
    UPDATE: "/email-categories/:id",
    SOFT_DELETE_ONE: "/email-categories/:id",
    SOFT_DELETE_MANY: "/email-categories/soft-delete-many",
  },
  ROLE: {
    GET_ALL: "/roles/get-all",
    GET_LIST: "/roles",
    CREATE: "/roles",
    UPDATE: "/roles/:id",
    FORCE_DELETE_ONE: "/roles/:id/force-delete-one",
    FORCE_DELETE_MANY: "/roles/force-delete-many",
  },
  PERMISSION: {
    GET_ALL: "/permissions/get-all",
    GET_ALL_BY_GROUP: "/permissions/get-all-by-group",
    GET_REMAINING: "/permissions/get-remaining",
    GET_LIST: "/permissions",
    CREATE: "/permissions",
    UPDATE: "/permissions/:id",
    FORCE_DELETE_ONE: "/permissions/:id/force-delete-one",
    FORCE_DELETE_MANY: "/permissions/force-delete-many",
  },
  PERMISSION_GROUP: {
    GET_ALL: "/permission-groups/get-all",
    GET_LIST: "/permission-groups",
    CREATE: "/permission-groups",
    UPDATE: "/permission-groups/:id",
    UPDATE_PRIORITY: "/permission-groups/:id/update-priority",
    FORCE_DELETE_ONE: "/permission-groups/:id/force-delete-one",
    FORCE_DELETE_MANY: "/permission-groups/force-delete-many",
  },
  USER: {
    GET_LIST: "/users",
    CREATE: "/users",
    UPDATE: "/users/:id",
    UPDATE_IS_ACTIVATED: "/email-templates/:id/update-is-activated",
  },
}
