import { IColumnActionItem } from "@/types/common"
import { t } from "@/utils/helpers"
import { Drawer, Dropdown } from "antd"
import { ButtonWrap } from "../ButtonWrap"
import "./style.scss"

interface IColumnActionWrapProps {
  menu: IColumnActionItem[]
  buttonText?: string
  onOpenDrawer: () => void
  onCloseDrawer: () => void
  visibleDrawer: boolean
}

export const ColumnActionWrap: React.FC<IColumnActionWrapProps> = ({
  menu,
  buttonText = t("COMMON.COLUMN_ACTION.BUTTON_ACTION"),
  onOpenDrawer,
  onCloseDrawer,
  visibleDrawer,
}) => {
  return (
    <div className="c-column-action-wrap">
      <div className="c-column-action-wrap__big-device">
        <Dropdown
          menu={{ items: menu }}
          placement="bottomCenter"
          trigger={["click"]}
          overlayClassName="c-column-action-wrap__big-device__overlay"
        >
          <ButtonWrap type="primary">{buttonText}</ButtonWrap>
        </Dropdown>
      </div>
      <div className="c-column-action-wrap__small-device">
        <ButtonWrap type="primary" onClick={onOpenDrawer}>
          {buttonText}
        </ButtonWrap>
        <Drawer
          placement="right"
          width={250}
          onClose={onCloseDrawer}
          open={visibleDrawer}
          closable={false}
          classNames={{ content: "c-column-action-wrap__small-device" }}
        >
          {menu?.map((item) => {
            if (item.type === "group") {
              return (
                <div className="group" key={item.key}>
                  <div className="group__label">{item.label}</div>
                  <div className="menu">
                    {item?.children &&
                      item?.children?.length > 0 &&
                      item?.children?.map((children) => {
                        return (
                          <div className="menu__label" key={children.key}>
                            {children?.label}
                          </div>
                        )
                      })}
                  </div>
                </div>
              )
            }
            return <></>
          })}
        </Drawer>
      </div>
    </div>
  )
}
