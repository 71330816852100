import {
  ButtonWrap,
  FormItemWrap,
  InputPasswordWrap,
  InputWrap,
} from "@/components/commons"
import { useAuth } from "@/providers/Auth"
import { APP_ROUTER } from "@/routes"
import { AuthService } from "@/services"
import { ILoginRequest } from "@/types/request"
import { ERROR_CODES, NOTIFICATION_STATUS } from "@/utils/constants"
import { notificationMessage, t } from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Form } from "antd"
import { Helmet } from "react-helmet-async"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"
import { usePermission } from "react-permission-role"

interface ILoginProps {
  title?: string
}

export const Login: React.FC<ILoginProps> = ({ title }) => {
  const navigate = useNavigate()
  const { setUser } = usePermission()
  const { logInSuccess, setCurrentUser } = useAuth()

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  const { mutate: mutateLogin, isLoading } = useMutation(
    (body: ILoginRequest) => AuthService.login(body),
    {
      onSuccess: (response) => {
        notificationMessage({
          message: "Đăng nhập thành công",
          type: NOTIFICATION_STATUS.SUCCESS,
        })
        logInSuccess({
          access_token: response.data.access_token,
        })
        getMe()
      },
      onError: (error: any) => {
        if (error?.error_code === ERROR_CODES.INVALID_ACCOUNT) {
          notificationMessage({
            message: "Sai tài khoản hoặc mật khẩu!",
            type: NOTIFICATION_STATUS.ERROR,
          })
        }
      },
    },
  )

  const { mutate: getMe } = useMutation(() => AuthService.getMe(), {
    onSuccess: (response) => {
      setUser({
        id: response.data.id,
        roles: response.data.roles?.map((item) => item.name),
        permissions: response.data.permissions?.map((item) => item.name),
      })
      setCurrentUser(response.data)
      navigate(APP_ROUTER.HOME)
    },
  })

  const handleLogin = (values: any) => {
    console.log("values", values)
    mutateLogin(values)
  }

  return (
    <div className="p-login">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="login-form">
        <div className="header">
          <span className="title">{t("P_LOGIN.FORM_TITLE")}</span>
          <span className="description">{t("P_LOGIN.FORM_DESCRIPTION")}</span>
        </div>
        <Form onFinish={handleSubmit(handleLogin)} className="body">
          <FormItemWrap
            required
            validateStatus={errors?.email ? "error" : ""}
            help={<>{errors?.email?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputWrap
                  placeholder={t("P_LOGIN.PLACEHOLDER_EMAIL")}
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            required
            validateStatus={errors?.password ? "error" : ""}
            help={<>{errors?.password?.message}</>}
            hasFeedback={true}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <InputPasswordWrap
                  placeholder={t("P_LOGIN.PLACEHOLDER_PASSWORD")}
                  size="large"
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <ButtonWrap
            className="login-btn"
            type="primary"
            htmlType="submit"
            size="large"
            loading={isLoading}
          >
            {t("P_LOGIN.BUTTON_LOGIN")}
          </ButtonWrap>
          <ButtonWrap className="forget-btn" type="link" size="large">
            {t("P_LOGIN.BUTTON_FORGET")}
          </ButtonWrap>
        </Form>
      </div>
    </div>
  )
}
