import {
  ModalWrap,
  PaginationWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { EventTypeService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import {
  ICreateEventTypeRequest,
  IUpdateEventTypeRequest,
} from "@/types/request"
import { IEventType } from "@/types/response"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateEventType, UpdateEventType, ViewSongDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const EventType: React.FC<IProps> = ({ title }) => {
  const { event_id: eventId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IEventType> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateEventType = (values: any) => {
    const body: ICreateEventTypeRequest = {
      name: values.name,
    }

    createEventType(body)
  }

  const handleUpdateEventType = (values: any) => {
    const body: IUpdateEventTypeRequest = {
      name: values.name,
    }

    updateEventType({ id: values.id, body })
  }

  const handleDeleteOneEventType = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyEventType = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEventType) => {
    switch (type) {
      case MODAL.CREATE_EVENT_TYPE:
        setModal({
          open: true,
          title: t("P_EVENT_TYPE.TITLE_CREATE_EVENT_TYPE"),
          width: 608,
          bodyHeight: 300,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEventType),
          onCancel: hanldeCancelModal,
          children: <CreateEventType />,
        })
        break
      case MODAL.UPDATE_EVENT_TYPE:
        setModal({
          open: true,
          title: t("P_EVENT_TYPE.TITLE_UPDATE_EVENT_TYPE"),
          width: 608,
          bodyHeight: 300,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateEventType),
          onCancel: hanldeCancelModal,
          children: <UpdateEventType data={record!} />,
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneEventType),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyEventType),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EVENT_TYPE.TITLE_DETAIL_EVENT_TYPE"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: typeList,
    refetch: refetchTypeList,
    isFetching: isFetchingTypeList,
  } = useQuery([ENDPOINT.EVENT_TYPE.GET_LIST, params], () =>
    EventTypeService.getList(convertRequest(params)),
  )

  const { mutate: createEventType, isLoading: isLoadingCreateEventType } =
    useMutation(
      (body: ICreateEventTypeRequest) => EventTypeService.create(body),
      {
        onSuccess: () => {
          refetchTypeList()
          handleModalContent(MODAL.NOT_SHOW)
          setSearchParams({
            ...convertToStringObject(params),
            current_page: String(1),
          })
          notificationMessage({
            message: "Thêm thành công",
            type: "success",
          })
          reset({ name: "" })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateEventType, isLoading: isLoadingUpdateEventType } =
    useMutation(
      (payload: { id: number; body: IUpdateEventTypeRequest }) =>
        EventTypeService.update(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchTypeList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Cập nhật thành công",
            type: "success",
          })
          setSelectedItems([])
          reset({ name: "" })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EventTypeService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchTypeList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EventTypeService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchTypeList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEventType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEventType) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-event-type">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={typeList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingTypeList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={typeList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEventType || isLoadingUpdateEventType}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
