import {
  FormItemWrap,
  SwitchWrap,
  TableEditorWrap,
  TooltipWrap,
} from "@/components/commons"
import {
  CalendarIcon,
  CompactDiscIcon,
  FileMusicIcon,
} from "@/components/icons"
import { ISong } from "@/types/response"
import {
  IMAGE_TYPE,
  songGroupTypes,
  songReleaseColors,
  songReleaseStatus,
} from "@/utils/constants"
import {
  formatBytes,
  getFileExtension,
  getImage,
  truncate,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Badge, Form, Image, Tag, Typography } from "antd"
import moment from "moment"
import { MODAL } from "../../constants"
import "./style.scss"
import { IEvent } from "@/types/response/event"

interface IViewPartnerDetailProps {
  record: any
  handleSecondModalContent?: any
}

export const ViewSongDetail: React.FC<IViewPartnerDetailProps> = ({
  record,
  handleSecondModalContent,
}) => {
  const {
    name,
    thumbnail_path,
    description,
    day_release,
    time_release,
    listener_counter,
    songGroup,
    artists,
    file,
    // slug,
    status,
    is_warning,
    is_displayed,
    created_by,
    updated_by,
    created_at,
    updated_at,
  } = record

  return (
    <div className="c-view-song-detail">
      <Form layout="vertical">
        <FormItemWrap label="Tên bài hát">{name}</FormItemWrap>
        <FormItemWrap label="Thumbnail">
          <div className="field-image">
            <Image
              width={112}
              src={getImage(thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
            />
          </div>
        </FormItemWrap>
        <FormItemWrap label="Nghệ sĩ">
          <div className="field-artists">
            {artists.map((artist: any, index: any) => (
              <Tag key={index} className="no-select">
                <div className="artist">
                  <img
                    src={getImage(artist.avatar_path, IMAGE_TYPE.THUMBNAIL)}
                    alt={artist.stage_name}
                  />
                  <span className="stage-name">{artist.stage_name}</span>
                </div>
              </Tag>
            ))}
          </div>
        </FormItemWrap>
        <FormItemWrap label="Mô tả">
          {description ? (
            <Typography.Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,
              }}
              title={description}
            >
              {description}
            </Typography.Paragraph>
          ) : (
            "Chưa có mô tả"
          )}
        </FormItemWrap>
        <FormItemWrap label="Thông tin tệp">
          {file ? (
            <div
              className="field-file no-select"
              onClick={() =>
                handleSecondModalContent(MODAL.PREVIEW_SONG, record)
              }
            >
              <Badge.Ribbon
                placement="start"
                text={getFileExtension(file.name)?.toUpperCase()}
                color="red"
              >
                <FileMusicIcon width={90} />
              </Badge.Ribbon>
              <div className="right">
                <p className="file-name">
                  <TooltipWrap placement="top" title={file.name}>
                    {truncate(file?.name, 18)}
                  </TooltipWrap>
                </p>
                <div className="file-size">{formatBytes(file?.size)}</div>
                <div className="file-bit-rate">{file?.detail.bit_rate}</div>
              </div>
            </div>
          ) : (
            "Chưa có tệp"
          )}
        </FormItemWrap>
        <FormItemWrap label="Ngày phát hành">
          {time_release && moment(time_release, "HH:mm:ss").format("HH:mm")}{" "}
          {moment(day_release).format("DD/MM/YYYY")}
        </FormItemWrap>
        <FormItemWrap label="Lượt nghe">{listener_counter}</FormItemWrap>
        <FormItemWrap label="Nhóm">
          {songGroup ? (
            <div className="field-group">
              <div className="thumbnail">
                <Image
                  width={64}
                  height={64}
                  src={getImage(
                    songGroup?.thumbnail_path,
                    IMAGE_TYPE.THUMBNAIL,
                  )}
                  title={songGroup.name}
                />
              </div>
              <div className="right">
                <span className="group-name">
                  <TooltipWrap placement="top" title={songGroup?.name}>
                    {truncate(songGroup?.name, 28)}
                  </TooltipWrap>
                </span>
                <span className="day-release">
                  <CalendarIcon />
                  <span>
                    {moment(songGroup.day_release).format("DD/MM/YYYY")}
                  </span>
                </span>
                <div className="type">
                  <CompactDiscIcon />{" "}
                  <span>{songGroupTypes[songGroup.type]}</span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </FormItemWrap>
        <FormItemWrap label="Trạng thái phát hành">
          <div className="column-status">
            <Tag color={songReleaseColors[status]}>
              {songReleaseStatus[status]}
            </Tag>
          </div>
        </FormItemWrap>
        <FormItemWrap label="Cảnh báo ngôn từ">
          <SwitchWrap
            defaultValue={is_warning}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled
          />
        </FormItemWrap>
        <FormItemWrap label="Trạng thái hiển thị">
          <SwitchWrap
            defaultValue={is_displayed}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled
          />
        </FormItemWrap>
        <FormItemWrap label="Người khởi tạo">
          <TableEditorWrap
            users={[created_by]}
            time={created_at}
            type="single"
          />
        </FormItemWrap>
        <FormItemWrap label="Người chỉnh sửa">
          <TableEditorWrap
            users={updated_by}
            time={updated_at}
            type="multiple"
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
