import {
  CopyWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
  UserInfoWrap,
} from "@/components/commons"
import {
  ConfirmDeleteMany,
  ConfirmDeleteOne,
  ConfirmUpdateMany,
  ConfirmUpdateOne,
} from "@/components/modals"
import { EventPositionService, EventService } from "@/services"
import { EmailService } from "@/services/email"
import { ENDPOINT } from "@/services/endpoint"
import { EventTicketService } from "@/services/eventTicket"
import { IModalState, IResponse } from "@/types/common"
import { ISendOneEmailRequest } from "@/types/request"
import {
  IAddPaymentHistoryRequest,
  ICreateEventTicketRequest,
  IImportEventTicketRequest,
  IUpdateEventTicketRequest,
  IVerifyTicketPaymentRequest,
} from "@/types/request/eventTicket"
import { IEmailLog, IEventTicket, IGetSliderResponse } from "@/types/response"
import { EVENT_TICKET_STATUS } from "@/utils/constants"
import {
  convertRequest,
  convertThousandsFormat,
  convertToStringObject,
  getAvatarByGender,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Empty, Tabs, TabsProps } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import moment from "moment"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { Log } from "./Log"
import {
  AddPaymentHistory,
  CreateEventTicket,
  ImportEventTicket,
  SendEmailAll,
  SendOneEmail,
  UpdateEventTicket,
  VerifyPayment,
  ViewAllLog,
  ViewPaymentDetail,
  ViewSendLogDetail,
  ViewSliderDetail,
} from "./Modal"
import { MODAL, MODAL_COMMON_TYPE } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"
import { IGetAllEventPositionResponse } from "@/types/response/eventPosition"

interface IProps {
  title?: string
}

export const TicketFan: React.FC<IProps> = ({ title }) => {
  const { event_id: eventId } = useParams()

  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)
  const [convertedTicketList, setConvertedTicketList] = useState<any>([])

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const [secondModal, setSecondModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const { data: eventPositionList, isFetching: isFetchingEventPositionList } =
    useQuery([ENDPOINT.EVENT_POSITON.GET_ALL], () =>
      EventPositionService.getAll({ event_id: Number(eventId) }),
    )

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    event_id: eventId,
    name: searchParams.get("name") || "",
    email: searchParams.get("email") || "",
    phone: searchParams.get("phone") || "",
    ticket_code: searchParams.get("ticket_code") || "",
    birthday: searchParams.get("birthday") || "",
    gender: searchParams.get("gender") || "",
    note: searchParams.get("note") || "",
    position_id:
      Number(searchParams.get("position_id")) ||
      String(eventPositionList?.data[0]?.id),
    status:
      Number(searchParams.get("status")) ||
      String(EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
      is_displayed: true,
    },
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetSliderResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleChangeTab = (key: string) => {
    setSelectedItems([])
    setSearchParams({
      ...convertToStringObject(params),
      status: key || String(EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL),
    })
  }

  const handleChangePositionTab = (key: string) => {
    setSelectedItems([])
    setSearchParams({
      ...convertToStringObject(params),
      position_id: key || String(eventPositionList?.data[0]?.id),
    })
  }

  const handleCreateEventTicket = (values: any) => {
    const body: ICreateEventTicketRequest = {
      name: values.name,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      gender: values.gender,
      email: values.email,
      phone: values.phone,
      facebook: values.facebook,
      event_id: Number(eventId),
      position_id: values.position_id,
      drink_id: values?.drink_id,
      is_activated: values.is_activated,
      owner_id: values.owner_id,
      note: values?.note,
    }

    createEventTicket(body)
  }

  const handleUpdateEventTicket = (values: any) => {
    const body: IUpdateEventTicketRequest = {
      name: values.name,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      gender: values.gender,
      email: values.email,
      phone: values.phone,
      facebook: values.facebook,
      event_id: Number(eventId),
      position_id: values.position_id,
      drink_id: values?.drink_id,
      is_activated: values.is_activated,
      owner_id: values.owner_id,
      note: values?.note,
    }

    updateEventTicket({ id: values.id, body })
  }

  const handleSendOneEmail = (values: any) => {
    const body: ISendOneEmailRequest = {
      subject: values.subject,
      content: values.content,
      account_id: values.account_id,
      ticket_id: values.ticket_id,
      is_attach_ticket: values.is_attach_ticket,
    }

    sendOneEmail(body)
  }

  const handleVerifyPayment = (values: any) => {
    const body: IVerifyTicketPaymentRequest = {
      method: values.method,
      payment_id: values.payment_id,
      description: values.description,
      have_send_ticket: values.have_send_ticket,
    }

    verifyPayment({
      id: values?.id,
      body,
    })
  }

  const handleAddPaymentHistory = (values: any) => {
    console.log("values", values)

    const body: IAddPaymentHistoryRequest = {
      bill_path: values.bill_path,
    }

    addPaymentHistory({
      id: values?.id,
      body,
    })
  }

  const handleUpdateIsActivatedMany = () => {
    const getIsActivated = () => {
      const statusTab = searchParams.get("status")

      if (Number(statusTab) === EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL) {
        return false
      } else if (Number(statusTab) === EVENT_TICKET_STATUS.CANCELED) {
        return true
      } else {
        return false
      }
    }

    updateIsActivatedMany({
      ids: selectedItems,
      is_activated: getIsActivated(),
    })
  }

  const handleUpdateStatusSuccess = (values: any) => {
    updateStatusSuccess(values.id)
  }

  const handleImportTicket = (values: any) => {
    if (eventPositionList?.data?.length === 0) {
      return notificationMessage({
        message: "Sự kiện này chưa có thông tin về Hạng vé",
        type: "error",
      })
    }
    const body: IImportEventTicketRequest = {
      file: values.file,
      event_id: Number(eventId),
      generate_ticket_code: values.generate_ticket_code,
      generate_qr_code: values.generate_qr_code,
      generate_created_at: values.generate_created_at,
      generate_updated_at: values.generate_updated_at,
    }

    importTicket(body)
  }

  const handleDeleteOnePartner = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyPartner = () => {
    softDeleteMany(selectedItems)
  }

  const handleCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
    reset({})
  }

  const handleCancelSecondModal = () => {
    handleSecondModalContent(MODAL.NOT_SHOW)
  }

  const handleSecondModalContent = (type: MODAL, data?: any) => {
    switch (type) {
      case MODAL.VIEW_PAYMENT_DETAIL:
        setSecondModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_VIEW_PAYMENT_DETAIL"),
          width: 608,
          bodyHeight: 588,
          onlyCancel: true,
          onCancel: handleCancelSecondModal,
          children: <ViewPaymentDetail data={data} />,
        })
        break
      case MODAL.VIEW_SEND_EMAIL_LOG_DETAIL:
      case MODAL.VIEW_SEND_TICKET_LOG_DETAIL:
        setSecondModal({
          open: true,
          title:
            type === MODAL.VIEW_SEND_EMAIL_LOG_DETAIL
              ? t("P_TICKET_FAN.TITLE_VIEW_SEND_EMAIL_LOG_DETAIL")
              : t("P_TICKET_FAN.TITLE_VIEW_SEND_TICKET_LOG_DETAIL"),
          width: 608,
          bodyHeight: 420,
          onlyCancel: true,
          onCancel: handleCancelSecondModal,
          children: <ViewSendLogDetail data={data} />,
        })
        break
      case MODAL.SEARCH_USER:
        setSecondModal({
          open: true,
          title: "Tìm kiếm người dùng",
          width: 608,
          bodyHeight: 420,
          onlyCancel: true,
          onCancel: handleCancelSecondModal,
          children: <ViewSendLogDetail data={data} />,
        })
        break
      default:
        setSecondModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const handleModalContent = (type: MODAL, record?: IEventTicket) => {
    switch (type) {
      case MODAL.CREATE_EVENT_TICKET:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_CREATE_TICKET_FAN"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEventTicket),
          onCancel: handleCancelModal,
          children: (
            <CreateEventTicket eventPositionList={eventPositionList?.data} />
          ),
        })
        break
      case MODAL.UPDATE_EVENT_TICKET:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_UPDATE_TICKET_FAN"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateEventTicket),
          onCancel: handleCancelModal,
          children: (
            <UpdateEventTicket
              data={record!}
              eventPositionList={eventPositionList?.data}
            />
          ),
        })
        break
      case MODAL.SEND_ONE_EMAIL:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_SEND_ONE_EMAIL"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_SEND_EMAIL"),
          onOk: handleSubmit(handleSendOneEmail),
          onCancel: handleCancelModal,
          children: <SendOneEmail ticketData={record} />,
        })
        break
      case MODAL.SEND_EMAIL_ALL:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_SEND_ONE_EMAIL"),
          width: 620,
          okText: t("COMMON.MODAL_WRAP.BUTTON_SEND_EMAIL"),
          isNoFooter: true,
          onCancel: handleCancelModal,
          children: <SendEmailAll handleCancelModal={handleCancelModal} />,
        })
        break
      case MODAL.VERIFY_PAYMENT:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_VERIFY_PAYMENT"),
          width: 608,
          bodyHeight: 360,
          okText: t("COMMON.MODAL_WRAP.BUTTON_SEND_EMAIL"),
          onOk: handleSubmit(handleVerifyPayment),
          onCancel: handleCancelModal,
          children: <VerifyPayment data={record!} />,
        })
        break
      case MODAL.ADD_PAYMENT_HISTORY:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_ADD_PAYMENT_HISTORY"),
          width: 608,
          bodyHeight: 180,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleAddPaymentHistory),
          onCancel: handleCancelModal,
          children: (
            <AddPaymentHistory
              data={record!}
              setIsLoadingUpload={setIsLoadingUpload}
            />
          ),
        })
        break
      case MODAL.IMPORT_EVENT_TICKET:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_IMPORT_EVENT_TICKET"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_IMPORT"),
          onOk: handleSubmit(handleImportTicket),
          onCancel: handleCancelModal,
          children: (
            <ImportEventTicket eventPositionList={eventPositionList?.data} />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOnePartner),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_UPDATE_SUCCESS_ONE:
        setModal({
          open: true,
          title: replaceStrings(
            t("COMMON.MODAL_WRAP.TITLE_UPDATE_STATUS_ONE"),
            {
              count: 1,
            },
          ),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleUpdateStatusSuccess),
          onCancel: handleCancelModal,
          children: (
            <ConfirmUpdateOne
              id={record?.id}
              modalName={MODAL.CONFIRM_UPDATE_SUCCESS_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_UPDATE_STATUS_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_UPDATE_IS_ACTIVATED_MANY:
        const statusTab = searchParams.get("status")
        const getContent = () => {
          if (
            Number(statusTab) === EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL
          ) {
            return t("COMMON.MODAL_WRAP.CONTENT_UPDATE_IS_ACTIVATED_FALSE_MANY")
          } else if (Number(statusTab) === EVENT_TICKET_STATUS.CANCELED) {
            return t("COMMON.MODAL_WRAP.CONTENT_UPDATE_IS_ACTIVATED_TRUE_MANY")
          } else {
            return ""
          }
        }

        const getTitle = () => {
          if (
            Number(statusTab) === EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL
          ) {
            return t("COMMON.MODAL_WRAP.TITLE_UPDATE_IS_ACTIVATED_FALSE_MANY")
          } else if (Number(statusTab) === EVENT_TICKET_STATUS.CANCELED) {
            return t("COMMON.MODAL_WRAP.TITLE_UPDATE_IS_ACTIVATED_TRUE_MANY")
          } else {
            return ""
          }
        }

        setModal({
          open: true,
          title: replaceStrings(getTitle(), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleUpdateIsActivatedMany),
          onCancel: handleCancelModal,
          children: (
            <ConfirmUpdateMany
              modalName={MODAL.CONFIRM_UPDATE_IS_ACTIVATED_MANY}
              content={getContent()}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyPartner),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SLIDER.TITLE_DETAIL_SLIDER"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: <ViewSliderDetail record={record!} />,
        })
        break
      case MODAL.VIEW_ALL_SEND_EMAIL_LOG:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_VIEW_ALL_SEND_EMAIL_LOG"),
          width: 220,
          bodyHeight: 200,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: (
            <ViewAllLog
              data={record?.send_email_logs!}
              type={MODAL_COMMON_TYPE.EMAIL}
              handleSecondModalContent={handleSecondModalContent}
            />
          ),
        })
        break
      case MODAL.VIEW_ALL_SEND_TICKET_LOG:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_VIEW_ALL_SEND_TICKET_LOG"),
          width: 220,
          bodyHeight: 200,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: (
            <ViewAllLog
              data={record?.send_ticket_logs!}
              type={MODAL_COMMON_TYPE.TICKET}
              handleSecondModalContent={handleSecondModalContent}
            />
          ),
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: ticketList,
    refetch: refetchTicketList,
    isFetching: isFetchingTicketList,
  } = useQuery(
    [ENDPOINT.EVENT_TICKET.GET_LIST, params],
    () => EventTicketService.getList(convertRequest(params)),
    {
      onSuccess: (response: any) => {
        const result = response?.data.map(
          (ticket: IEventTicket, index: number) => ({
            ...ticket,
            expandableRow: (
              <TableWrap
                rowKey="id"
                columns={[
                  {
                    title: "Email",
                    dataIndex: "email",
                    width: "280px",
                    render: (value) => <CopyWrap value={value} />,
                  },
                  {
                    title: "Mã đăng ký",
                    dataIndex: "ticket_code",
                    width: "200px",
                    align: "left",
                    render: (value) => <CopyWrap value={value} />,
                  },
                  {
                    title: "Số điện thoại",
                    dataIndex: "phone",
                    width: "200px",
                    render: (value) => <CopyWrap value={value} />,
                  },
                  {
                    title: "Ngày sinh",
                    dataIndex: "birthday",
                    align: "left",
                    width: "160px",
                    render: (value) =>
                      value && moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                  },
                  {
                    title: "Lịch sử thanh toán",
                    dataIndex: "ticket_payments",
                    align: "center",
                    width: "240px",
                    render: (value, record) => (
                      <Log
                        className="column-ticket-payment"
                        data={value}
                        record={record}
                        type={MODAL_COMMON_TYPE.PAYMENT}
                        handleModalContent={handleModalContent}
                        handleSecondModalContent={handleSecondModalContent}
                      />
                    ),
                  },
                  {
                    title: "Ghi chú",
                    dataIndex: "note",
                    align: "left",
                    render: (value) => (
                      <div className="column-note">{value}</div>
                    ),
                  },
                ]}
                dataSource={[
                  {
                    key: index,
                    birthday: ticket.birthday,
                    email: ticket.email,
                    phone: ticket.phone,
                    ticket_code: ticket.ticket_code,
                    ticket_payments: ticket.ticket_payments,
                    note: ticket.note,
                  },
                ]}
                scroll={{ x: 1300 }}
                pagination={false}
                bordered
              ></TableWrap>
            ),
          }),
        )
        setConvertedTicketList(result)
      },
    },
  )

  useQuery([ENDPOINT.EVENT.GET_DETAIL, eventId], () =>
    EventService.getDetail(Number(eventId)),
  )

  const { mutate: createEventTicket, isLoading: isLoadingCreateTicket } =
    useMutation(
      (body: ICreateEventTicketRequest) => EventTicketService.create(body),
      {
        onSuccess: () => {
          refetchTicketList()
          handleModalContent(MODAL.NOT_SHOW)
          setSearchParams({
            ...convertToStringObject(params),
            current_page: String(1),
            status: String(EVENT_TICKET_STATUS.PENDING_PAYMENT),
          })
          notificationMessage({
            message: "Thêm thành công",
            type: "success",
          })
          reset({
            name: "",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateEventTicket, isLoading: isLoadingUpdateTicket } =
    useMutation(
      (payload: { id: number; body: IUpdateEventTicketRequest }) =>
        EventTicketService.update(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchTicketList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Cập nhật thành công",
            type: "success",
          })
          setSelectedItems([])
          reset({
            name: "",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateIsActivated } = useMutation(
    (payload: { id: number; body: { is_activated: boolean } }) =>
      EventTicketService.updateIsActivated(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchTicketList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateStatusSuccess } = useMutation(
    (id: number) => EventTicketService.updateStatusSuccess(id),
    {
      onSuccess: () => {
        refetchTicketList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsActivatedMany } = useMutation(
    (payload: { ids: Key[]; is_activated: boolean }) =>
      EventTicketService.updateIsActivatedMany(payload),
    {
      onSuccess: () => {
        refetchTicketList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: sendOneEmail, isLoading: isLoadingSendOneEmail } =
    useMutation(
      (body: ISendOneEmailRequest) => EmailService.sendOneEmail(body),
      {
        onSuccess: () => {
          refetchTicketList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Gửi Email thành công",
            type: "success",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: verifyPayment, isLoading: isLoadingVerifyPayment } =
    useMutation(
      (payload: { id: number; body: IVerifyTicketPaymentRequest }) =>
        EventTicketService.verifyPayment(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchTicketList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message:
              "Đã gửi yêu cầu xác minh thanh toán, vui lòng đợi trong ít phút",
            type: "success",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: addPaymentHistory, isLoading: isLoadingAddPaymentHistory } =
    useMutation(
      (payload: { id: number; body: IAddPaymentHistoryRequest }) =>
        EventTicketService.addPaymentHistory(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchTicketList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Thêm lịch sử thanh toán thành công",
            type: "success",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: importTicket, isLoading: isLoadingImport } = useMutation(
    (body: IImportEventTicketRequest) => EventTicketService.import(body),
    {
      onSuccess: () => {
        refetchTicketList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Nhập dữ liệu vé thành công",
          type: "success",
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EventTicketService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchTicketList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EventTicketService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchTicketList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEventTicket> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "70px",
      align: "center",
    },
    {
      title: "Tên",
      width: "280px",
      render: (record) => (
        <div className="column-name">
          <img
            className="avatar"
            src={getAvatarByGender(record.gender)}
            alt={record.name}
          />
          <span className="name">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Lịch sử gửi vé",
      dataIndex: "send_ticket_logs",
      width: "200px",
      align: "center",
      render: (value: IEmailLog[], record: IEventTicket) => (
        <Log
          data={value}
          record={record}
          type={MODAL_COMMON_TYPE.TICKET}
          handleModalContent={handleModalContent}
          handleSecondModalContent={handleSecondModalContent}
        />
      ),
    },
    {
      title: "Lịch sử gửi email",
      dataIndex: "send_email_logs",
      width: "200px",
      align: "center",
      render: (value: IEmailLog[], record: IEventTicket) => (
        <Log
          data={value}
          record={record}
          type={MODAL_COMMON_TYPE.EMAIL}
          handleModalContent={handleModalContent}
          handleSecondModalContent={handleSecondModalContent}
        />
      ),
    },
    {
      title: "Trạng thái kích hoạt",
      dataIndex: "is_activated",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          disabled={
            record?.status !== EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL &&
            record?.status !== EVENT_TICKET_STATUS.CANCELED
          }
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsActivated({
              id: record?.id,
              body: {
                is_activated: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Chủ sở hữu",
      dataIndex: "owner",
      width: "240px",
      render: (value) => <UserInfoWrap data={value} avatarSize={32} />,
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEventTicket) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  const items: TabsProps["items"] = [
    {
      key: String(EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL),
      label: "Đăng ký thành công",
      children: (
        <>
          <TableWrap
            rowKey="id"
            columns={columns}
            dataSource={convertedTicketList}
            scroll={{ x: 1548 }}
            pagination={false}
            loading={isFetchingTicketList}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ marginLeft: "150px" }}>
                  {record.expandableRow}
                </div>
              ),
              expandedRowKeys: ticketList?.data?.map((ticket: IEventTicket) =>
                Number(ticket?.id),
              ),
            }}
          />
          <PaginationWrap
            className="pagination-table"
            pageSize={params.page_size}
            current={params.current_page}
            total={ticketList?.total}
            onChange={handleChangePage}
            showSizeChanger
            showLessItems
          />
        </>
      ),
    },
    {
      key: String(EVENT_TICKET_STATUS.VERIFICATION_REQUEST),
      label: "Yêu cầu xác minh",
      children: (
        <>
          <TableWrap
            rowKey="id"
            columns={columns}
            dataSource={convertedTicketList}
            scroll={{ x: 1548 }}
            pagination={false}
            loading={isFetchingTicketList}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ marginLeft: "150px" }}>
                  {record.expandableRow}
                </div>
              ),
              expandedRowKeys: ticketList?.data?.map((ticket: IEventTicket) =>
                Number(ticket?.id),
              ),
            }}
          />
          <PaginationWrap
            className="pagination-table"
            pageSize={params.page_size}
            current={params.current_page}
            total={ticketList?.total}
            onChange={handleChangePage}
            showSizeChanger
            showLessItems
          />
        </>
      ),
    },
    {
      key: String(EVENT_TICKET_STATUS.VERIFICATION_FAILED),
      label: "Xác minh thất bại",
      children: (
        <>
          <TableWrap
            rowKey="id"
            columns={columns}
            dataSource={convertedTicketList}
            scroll={{ x: 1548 }}
            pagination={false}
            loading={isFetchingTicketList}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ marginLeft: "150px" }}>
                  {record.expandableRow}
                </div>
              ),
              expandedRowKeys: ticketList?.data?.map((ticket: IEventTicket) =>
                Number(ticket?.id),
              ),
            }}
          />
          <PaginationWrap
            className="pagination-table"
            pageSize={params.page_size}
            current={params.current_page}
            total={ticketList?.total}
            onChange={handleChangePage}
            showSizeChanger
            showLessItems
          />
        </>
      ),
    },
    {
      key: String(EVENT_TICKET_STATUS.PENDING_PAYMENT),
      label: "Chờ thanh toán",
      children: (
        <>
          <TableWrap
            rowKey="id"
            columns={columns}
            dataSource={convertedTicketList}
            scroll={{ x: 1548 }}
            pagination={false}
            loading={isFetchingTicketList}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ marginLeft: "150px" }}>
                  {record.expandableRow}
                </div>
              ),
              expandedRowKeys: ticketList?.data?.map((ticket: IEventTicket) =>
                Number(ticket?.id),
              ),
            }}
          />
          <PaginationWrap
            className="pagination-table"
            pageSize={params.page_size}
            current={params.current_page}
            total={ticketList?.total}
            onChange={handleChangePage}
            showSizeChanger
            showLessItems
          />
        </>
      ),
    },
    {
      key: String(EVENT_TICKET_STATUS.CANCELED),
      label: "Hủy",
      children: (
        <>
          <TableWrap
            rowKey="id"
            columns={columns}
            dataSource={convertedTicketList}
            scroll={{ x: 1548 }}
            pagination={false}
            loading={isFetchingTicketList}
            rowSelection={rowSelection}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ marginLeft: "150px" }}>
                  {record.expandableRow}
                </div>
              ),
              expandedRowKeys: ticketList?.data?.map((ticket: IEventTicket) =>
                Number(ticket?.id),
              ),
            }}
          />
          <PaginationWrap
            className="pagination-table"
            pageSize={params.page_size}
            current={params.current_page}
            total={ticketList?.total}
            onChange={handleChangePage}
            showSizeChanger
            showLessItems
          />
        </>
      ),
    },
  ]

  const positionItems: TabsProps["items"] = eventPositionList?.data?.map(
    (position) => {
      const getLabel = () => {
        let icon = <></>
        if (!position.is_displayed) {
          icon = <EyeInvisibleOutlined />
        }

        return (
          <div className="position-tab">
            {icon} {position?.name} ({convertThousandsFormat(position?.price)}đ)
          </div>
        )
      }
      return {
        key: String(position.id),
        label: getLabel(),
      }
    },
  )

  return (
    <div className="p-ticket-fan">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />

      {eventPositionList?.data?.length! > 0 ? (
        <>
          <Tabs
            type="card"
            className="no-select"
            items={positionItems}
            activeKey={
              searchParams.get("position_id") ||
              String(eventPositionList?.data[0]?.id)
            }
            onChange={handleChangePositionTab}
          />
          <Tabs
            type="card"
            className="no-select"
            items={items}
            activeKey={
              searchParams.get("status") ||
              String(EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL)
            }
            onChange={handleChangeTab}
          />
        </>
      ) : (
        <Empty
          description="Chưa có dữ liệu về Hạng vé"
          className="ticket-empty"
        />
      )}

      <ModalWrap
        loading={
          isLoadingCreateTicket ||
          isLoadingUpdateTicket ||
          isLoadingVerifyPayment ||
          isLoadingAddPaymentHistory ||
          isLoadingSendOneEmail ||
          isLoadingImport
        }
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
      <ModalWrap {...secondModal}>
        <FormProvider {...methods}>{secondModal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
