import { FormItemWrap, SelectWrap } from "@/components/commons"
import { ISendEmailToAllUserCondition } from "@/types/common"
import { userAccountTypes } from "@/utils/constants"
import { convertObjectToArray } from "@/utils/helpers"
import { Form } from "antd"
import React, { useEffect } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useQueryClient } from "react-query"
import "./style.scss"

interface IProps {
  emailCondition: ISendEmailToAllUserCondition
  handleChangeCondition: (form: ISendEmailToAllUserCondition) => void
}

export const ChangeCondition: React.FC<IProps> = ({
  emailCondition,
  handleChangeCondition,
}) => {
  const queryClient = useQueryClient()
  const methods = useForm({
    mode: "onChange",
  })

  const {
    reset,
    control,
    formState: { errors },
  } = methods

  const formWatcher = useWatch({
    control,
  })

  const renderHelpMessage = (field: keyof typeof messages) => {
    const messages: {
      [key: string]: object
    } = {
      account_type: {
        0: "Email sẽ được gửi cho Tất cả tài khoản",
        1: "Email sẽ được gửi cho Người dùng",
        2: "Email sẽ được gửi cho Quản lý",
        6: "Email sẽ được gửi cho Tài khoản bị Hủy",
      },
    }

    const value = formWatcher[field]
    return (messages as any)[field]?.[value] || ""
  }

  useEffect(() => {
    const condition: ISendEmailToAllUserCondition = {
      account_type: formWatcher?.account_type,
    }
    handleChangeCondition(condition)
  }, [formWatcher])

  useEffect(() => {
    reset({
      account_type: emailCondition?.account_type,
    })
  }, [])

  return (
    <Form layout="vertical" className="c-change-condition">
      <FormItemWrap
        label="Trạng thái tài khoản"
        required
        validateStatus={errors?.account_type ? "error" : ""}
        help={<>{renderHelpMessage("account_type")}</>}
      >
        <Controller
          name="account_type"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn trạng thái"
              options={convertObjectToArray(userAccountTypes)}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
