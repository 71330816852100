import {
  FormItemWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import { IEventTicket } from "@/types/response"
import {
  SELECT_TYPE,
  VERIFY_PAYMENT_METHODS,
  verifyPaymentMethods,
} from "@/utils/constants"
import { convertArrayToOptions, convertObjectToArray } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: IEventTicket
}

export const VerifyPayment: React.FC<IProps> = ({ data }) => {
  const {
    control,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  useEffect(() => {
    if (formMonitor?.method === VERIFY_PAYMENT_METHODS.TICKET_CODE) {
      setValue("description", data.ticket_code)
    } else {
      setValue("description", "")
    }
  }, [formMonitor?.method])

  useEffect(() => {
    reset({
      modal_name: MODAL.VERIFY_PAYMENT,
      id: data.id,
      have_send_ticket: true,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Giao dịch"
        required
        validateStatus={errors?.payment_id ? "error" : ""}
        help={<>{errors?.payment_id && errors?.payment_id?.message}</>}
      >
        <Controller
          name="payment_id"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn giao dịch"
              type={SELECT_TYPE.PAYMENT}
              options={convertArrayToOptions(
                data?.ticket_payments,
                "id",
                "created_at",
              )}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Phương thức xác minh"
        required
        validateStatus={errors?.method ? "error" : ""}
        help={<>{errors?.method && errors?.method?.message}</>}
      >
        <Controller
          name="method"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn phương thức"
              options={convertObjectToArray(verifyPaymentMethods)}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Nội dung thanh toán"
        required
        validateStatus={errors?.description ? "error" : ""}
        help={<>{errors?.description && errors?.description?.message}</>}
      >
        <Controller
          name="description"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              field={field}
              readOnly={
                formMonitor?.method === VERIFY_PAYMENT_METHODS.TICKET_CODE
              }
              placeholder="Nhập nội dung chuyển khoản"
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Gửi vé hay không?" required>
        <Controller
          name="have_send_ticket"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
