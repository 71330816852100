import {
  InputCellWrap,
  ModalWrap,
  PaginationWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ENDPOINT } from "@/services/endpoint"
import { PermissionService } from "@/services/permission"
import { PermissionGroupService } from "@/services/permissionGroup"
import { IModalState } from "@/types/common"
import {
  ICreatePermissionGroupRequest,
  IUpdatePermissionGroupRequest,
} from "@/types/request/permissionGroup"
import { IGetSliderResponse } from "@/types/response"
import { IPermission } from "@/types/response/permission"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import {
  CreatePermissionGroup,
  UpdatePermissionGroup,
  ViewSliderDetail,
} from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const PermissionGroup: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name"),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
      is_displayed: true,
    },
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetSliderResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreatePermissionGroup = (values: any) => {
    const body: ICreatePermissionGroupRequest = {
      name: values.name,
      permission_ids: values?.permission_ids,
      priority: values.priority,
    }

    createPermissionGroup(body)
  }

  const handleUpdatePermissionGroup = (values: any) => {
    const body: IUpdatePermissionGroupRequest = {
      name: values.name,
      permission_ids: values?.permission_ids,
      priority: values.priority,
    }

    updatePermissionGroup({ id: values.id, body })
  }

  const handleDeleteOnePermission = (values: any) => {
    forceDeleteOne(values.id)
  }

  const handleDeleteManyPermission = () => {
    softDeleteMany(selectedItems)
  }

  const handleCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IPermissionGroup) => {
    switch (type) {
      case MODAL.CREATE_PERMISSION_GROUP:
        setModal({
          open: true,
          title: t("P_PERMISSION_GROUP.TITLE_CREATE_PERMISSION_GROUP"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreatePermissionGroup),
          onCancel: handleCancelModal,
          children: (
            <CreatePermissionGroup permissionList={permissionList?.data!} />
          ),
        })
        break
      case MODAL.UPDATE_PERMISSION_GROUP:
        setModal({
          open: true,
          title: t("P_PERMISSION_GROUP.TITLE_UPDATE_PERMISSION_GROUP"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdatePermissionGroup),
          onCancel: handleCancelModal,
          children: (
            <UpdatePermissionGroup
              data={record!}
              permissionList={permissionList?.data!}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOnePermission),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyPermission),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_PERMISSION_GROUP.TITLE_DETAIL_PERMISSION_GROUP"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: <ViewSliderDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: permissionGroupList,
    refetch: refetchPermissionGroupList,
    isFetching: isFetchingPermissionGroupList,
  } = useQuery([ENDPOINT.PERMISSION.GET_LIST, params], () =>
    PermissionGroupService.getList(convertRequest(params)),
  )

  const { data: permissionList, isFetching: isFetchingPermissionList } =
    useQuery([ENDPOINT.PERMISSION.GET_REMAINING, params], () =>
      PermissionService.getRemaining(),
    )

  const {
    mutate: createPermissionGroup,
    isLoading: isLoadingCreatePermission,
  } = useMutation(
    (body: ICreatePermissionGroupRequest) =>
      PermissionGroupService.create(body),
    {
      onSuccess: () => {
        refetchPermissionGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({
          name: "",
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePermissionGroup } = useMutation(
    (payload: { id: number; body: IUpdatePermissionGroupRequest }) =>
      PermissionGroupService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPermissionGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          name: "",
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePriority } = useMutation(
    (payload: { id: number; body: { priority: number } }) =>
      PermissionGroupService.updatePriority(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPermissionGroupList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          name: "",
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: forceDeleteOne } = useMutation(
    (id: number) => PermissionGroupService.forceDeleteOne(id),
    {
      onSuccess: () => {
        refetchPermissionGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => PermissionGroupService.forceDeleteMany(ids),
    {
      onSuccess: () => {
        refetchPermissionGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IPermissionGroup> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "40px",
      align: "center",
    },
    {
      title: "Tên nhóm quyền",
      dataIndex: "name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Quyền",
      dataIndex: "permissions",
      width: "160px",
      align: "center",
      render: (value: IPermission[]) => {
        return value?.map((permission, index) => (
          <Tag key={index} color="var(--color-green-1)" className="no-select">
            {permission?.display_name}
          </Tag>
        ))
      },
    },
    {
      title: "Thứ tự hiển thị",
      dataIndex: "priority",
      width: "132px",
      align: "center",
      render: (value, record) => (
        <InputCellWrap
          defaultValue={value}
          onChange={(e) =>
            updatePriority({
              id: record?.id,
              body: {
                priority: Number(e),
              },
            })
          }
        />
      ),
    },
    {
      title: "Action",
      width: "82px",
      align: "center",
      fixed: "right",
      render: (record: IPermissionGroup) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-permission-group">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={permissionGroupList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingPermissionGroupList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={permissionGroupList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreatePermission}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
