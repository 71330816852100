import {
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
  TooltipWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { APP_ROUTER } from "@/routes"
import { EventService, EventTypeService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { ProvinceService } from "@/services/province"
import { IModalState } from "@/types/common"
import { ICreateEventRequest, IUpdateEventRequest } from "@/types/request/event"
import { IEventType } from "@/types/response"
import { IEvent, IEventPositionCustomize } from "@/types/response/event"
import { IMAGE_TYPE, attachedTicketTypes } from "@/utils/constants"
import {
  convertOrganizingTime,
  convertRequest,
  convertToStringObject,
  getImage,
  notificationMessage,
  replaceStrings,
  t,
  truncate,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Image, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateEvent, UpdateEvent, ViewSongDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const Event: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const navigate = useNavigate()

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
    province_name: searchParams.get("province_name") || "",
    type_id: searchParams.get("type_id") || "",
    have_drink: searchParams.get("have_drink") || "",
    have_payment: searchParams.get("have_payment") || "",
    attached_ticket_type: searchParams.get("attached_ticket_type") || "",
    is_displayed: searchParams.get("is_displayed") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const { handleSubmit, reset, control, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IEvent> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateEvent = (values: any) => {
    const body: ICreateEventRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      start_time: values.time_organizing[0].format("YYYY-MM-DD HH:mm:ss"),
      end_time: values.time_organizing[1].format("YYYY-MM-DD HH:mm:ss"),
      province_name: values.province_name,
      district_name: values.district_name,
      ward_name: values.ward_name,
      street_name: values?.street_name,
      house_number: values?.house_number,
      place_name: values?.place_name,
      type_id: values.type_id,
      have_drink: values.have_drink,
      have_payment: values.have_payment,
      attached_ticket_type: values.attached_ticket_type,
      attached_ticket_template: values?.attached_ticket_template,
      is_displayed: values.is_displayed,

      ticket_fan_active: values.ticket_fan_active,
      ticket_fan_url_type: values.ticket_fan_url_type,
      ticket_fan_optional_url: values?.ticket_fan_optional_url,
      ticket_team_active: values.ticket_team_active,
      ticket_team_url_type: values.ticket_team_url_type,
      ticket_team_optional_url: values?.ticket_team_optional_url,
      join_support_active: values.join_support_active,
      join_support_url_type: values.join_support_url_type,
      join_support_optional_url: values?.join_support_optional_url,
      join_media_active: values.join_media_active,
      join_media_url_type: values.join_media_url_type,
      join_media_optional_url: values?.join_media_optional_url,
      join_talent_active: values.join_talent_active,
      join_talent_url_type: values.join_talent_url_type,
      join_talent_optional_url: values?.join_talent_optional_url,
      attendance_active: values.attendance_active,
      attendance_url_type: values.attendance_url_type,
      attendance_optional_url: values?.attendance_optional_url,
    }
    createEvent(body)
  }

  const handleUpdateEvent = (values: any) => {
    const body: IUpdateEventRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      start_time: values.time_organizing[0].format("YYYY-MM-DD HH:mm:ss"),
      end_time: values.time_organizing[1].format("YYYY-MM-DD HH:mm:ss"),
      province_name: values.province_name,
      district_name: values.district_name,
      ward_name: values.ward_name,
      street_name: values?.street_name,
      house_number: values?.house_number,
      place_name: values?.place_name,
      type_id: values.type_id,
      have_drink: values.have_drink,
      have_payment: values.have_payment,
      attached_ticket_type: values.attached_ticket_type,
      attached_ticket_template: values?.attached_ticket_template,
      is_displayed: values.is_displayed,

      ticket_fan_id: values.ticket_fan_id,
      ticket_fan_active: values.ticket_fan_active,
      ticket_fan_url_type: values.ticket_fan_url_type,
      ticket_fan_optional_url: values?.ticket_fan_optional_url,

      ticket_team_id: values.ticket_team_id,
      ticket_team_active: values.ticket_team_active,
      ticket_team_url_type: values.ticket_team_url_type,
      ticket_team_optional_url: values?.ticket_team_optional_url,

      join_support_id: values.join_support_id,
      join_support_active: values.join_support_active,
      join_support_url_type: values.join_support_url_type,
      join_support_optional_url: values?.join_support_optional_url,

      join_media_id: values.join_media_id,
      join_media_active: values.join_media_active,
      join_media_url_type: values.join_media_url_type,
      join_media_optional_url: values?.join_media_optional_url,

      join_talent_id: values.join_talent_id,
      join_talent_active: values.join_talent_active,
      join_talent_url_type: values.join_talent_url_type,
      join_talent_optional_url: values?.join_talent_optional_url,

      attendance_id: values.attendance_id,
      attendance_active: values.attendance_active,
      attendance_url_type: values.attendance_url_type,
      attendance_optional_url: values?.attendance_optional_url,
    }

    updateEvent({ id: values.id, body })
  }

  const handleDeleteOneEvent = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyEvent = () => {
    softDeleteMany(selectedItems)
  }

  const handleCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEvent) => {
    switch (type) {
      case MODAL.CREATE_EVENT:
        setModal({
          open: true,
          title: t("P_EVENT.TITLE_CREATE_EVENT"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEvent),
          onCancel: handleCancelModal,
          children: (
            <CreateEvent
              setIsLoadingUpload={setIsLoadingUpload}
              eventTypeList={eventTypeList?.data}
            />
          ),
        })
        break
      case MODAL.UPDATE_EVENT:
        setModal({
          open: true,
          title: t("P_EVENT.TITLE_UPDATE_EVENT"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateEvent),
          onCancel: handleCancelModal,
          children: (
            <UpdateEvent
              data={record!}
              eventTypeList={eventTypeList?.data}
              setIsLoadingUpload={setIsLoadingUpload}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneEvent),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyEvent),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EVENT.TITLE_DETAIL_EVENT"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: eventList,
    refetch: refetchEventList,
    isFetching: isFetchingEventList,
  } = useQuery([ENDPOINT.EVENT.GET_LIST, params], () =>
    EventService.getList(convertRequest(params)),
  )

  const { data: eventTypeList } = useQuery(
    [ENDPOINT.EVENT_TYPE.GET_ALL, params],
    () => EventTypeService.getAll(),
  )

  const { data: provinceList } = useQuery(
    [ENDPOINT.PROVINCE.GET_PROVINCES],
    () => ProvinceService.getProvinces(),
  )

  const { mutate: createEvent, isLoading: isLoadingCreateEvent } = useMutation(
    (body: ICreateEventRequest) => EventService.create(body),
    {
      onSuccess: () => {
        refetchEventList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateEvent } = useMutation(
    (payload: { id: number; body: IUpdateEventRequest }) =>
      EventService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEventList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateHaveDrink } = useMutation(
    (payload: { id: number; body: { have_drink: boolean } }) =>
      EventService.updateHaveDrink(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEventList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateHavePayment } = useMutation(
    (payload: { id: number; body: { have_payment: boolean } }) =>
      EventService.updateHavePayment(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEventList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsDisplayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      EventService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEventList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const formMonitor = useWatch({
    control,
  })

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EventService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchEventList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EventService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchEventList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEvent> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên sự kiện",
      width: "320px",
      render: (record) => {
        return (
          <div className="column-name">
            <div className="thumbnail">
              <Image
                width={90}
                height={90}
                src={getImage(record?.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
                title={record.name}
              />
            </div>
            <div className="right">
              <span className="name">
                <TooltipWrap placement="top" title={record?.name}>
                  {truncate(record?.name, 28)}
                </TooltipWrap>
              </span>
              <div className="time">
                {convertOrganizingTime(record?.start_time, record?.end_time)}
              </div>
              <div className="location">{record?.province_name}</div>
              <div
                className="access-btn"
                onClick={() =>
                  navigate(
                    APP_ROUTER.EVENT.TICKET_FAN.replace(
                      ":event_id",
                      record?.id,
                    ),
                    {
                      replace: true,
                    },
                  )
                }
              >
                Truy cập
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: "Khu vực đứng",
      dataIndex: "event_positions",
      width: "150px",
      align: "center",
      render: (value: IEventPositionCustomize[]) => {
        return (
          <div className="column-positions">
            {value?.length > 0
              ? value?.map((position, index) => {
                  return (
                    <Tag className="no-select" color="#108ee9" key={index}>
                      {position?.name}
                    </Tag>
                  )
                })
              : ""}
          </div>
        )
      },
    },
    {
      title: "Phân loại",
      dataIndex: "event_type",
      width: "150px",
      align: "center",
      render: (value: IEventType) => value.name,
    },
    {
      title: "Vé đính kèm",
      dataIndex: "attached_ticket_type",
      width: "150px",
      align: "center",
      render: (value) => attachedTicketTypes[value],
    },
    {
      title: "Nước uống",
      dataIndex: "have_drink",
      width: "110px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateHaveDrink({
              id: record?.id,
              body: {
                have_drink: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Thanh toán",
      dataIndex: "have_payment",
      width: "110px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateHavePayment({
              id: record?.id,
              body: {
                have_payment: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Trạng thái hiển thị",
      dataIndex: "is_displayed",
      width: "150px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsDisplayed({
              id: record?.id,
              body: {
                is_displayed: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEvent) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-event">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        eventTypeList={eventTypeList?.data}
        provinceList={provinceList?.data}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={eventList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingEventList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={eventList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEvent}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
