import { IResponse, IResponsePagination } from "@/types/common"
import {
  IAddPaymentHistoryRequest,
  ICreateEventTicketRequest,
  IGetListEventTicketRequest,
  IImportEventTicketRequest,
  ISearchEventTicketRequest,
  IUpdateEventTicketRequest,
  IVerifyTicketPaymentRequest,
} from "@/types/request/eventTicket"
import {
  ICreateEventTicketResponse,
  IGetListEventTicketResponse,
  ISearchEventTicketResponse,
  IUpdateEventTicketResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EventTicket {
  search = (
    params: ISearchEventTicketRequest,
  ): Promise<IResponse<ISearchEventTicketResponse>> => {
    return http.get(ENDPOINT.EVENT_TICKET.SEARCH, { params: params })
  }

  getList = (
    params: IGetListEventTicketRequest,
  ): Promise<IResponsePagination<IGetListEventTicketResponse>> => {
    return http.get(ENDPOINT.EVENT_TICKET.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEventTicketRequest,
  ): Promise<IResponse<ICreateEventTicketResponse>> => {
    return http.post(ENDPOINT.EVENT_TICKET.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEventTicketRequest,
  ): Promise<IResponse<IUpdateEventTicketResponse>> => {
    return http.put(
      ENDPOINT.EVENT_TICKET.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  updateStatusSuccess = (id: number) => {
    return http.put(
      ENDPOINT.EVENT_TICKET.UPDATE_STATUS_SUCCESS.replace(":id", String(id)),
    )
  }

  updateIsActivated = (
    id: number,
    body: {
      is_activated: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT_TICKET.UPDATE_IS_ACTIVATED.replace(":id", String(id)),
      body,
    )
  }

  updateIsActivatedMany = (body: { ids: Key[]; is_activated: boolean }) => {
    return http.put(ENDPOINT.EVENT_TICKET.UPDATE_IS_ACTIVATED_MANY, body)
  }

  verifyPayment = (id: number, body: IVerifyTicketPaymentRequest) => {
    return http.post(
      ENDPOINT.EVENT_TICKET.VERIFY_PAYMENT.replace(":id", String(id)),
      body,
    )
  }

  addPaymentHistory = (id: number, body: IAddPaymentHistoryRequest) => {
    return http.post(
      ENDPOINT.EVENT_TICKET.ADD_PAYMENT_HISTORY.replace(":id", String(id)),
      body,
    )
  }

  import = async (body: IImportEventTicketRequest) => {
    const formData = new FormData()
    formData.append("file", body.file)
    formData.append("event_id", String(body.event_id))
    formData.append(
      "generate_ticket_code",
      Number(body.generate_ticket_code).toString(),
    )
    formData.append(
      "generate_qr_code",
      Number(body.generate_qr_code).toString(),
    )
    formData.append(
      "generate_created_at",
      Number(body.generate_created_at).toString(),
    )
    formData.append(
      "generate_updated_at",
      Number(body.generate_updated_at).toString(),
    )

    const res = await http.post(ENDPOINT.EVENT_TICKET.IMPORT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return res
  }

  exportSample = () => {
    return http.get(ENDPOINT.EVENT_TICKET.EXPORT_SAMPLE)
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EVENT_TICKET.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EVENT_TICKET.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EventTicketService = new EventTicket()
