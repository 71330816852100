import { IResponse, IResponsePagination } from "@/types/common"
import { IGetSliderTypeRequest } from "@/types/request/sliderType"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"
import {
  IGetAllSliderTypesResponse,
  IGetSliderTypesResponse,
} from "@/types/response"

class SliderType {
  getAll = (): Promise<IResponse<IGetAllSliderTypesResponse>> => {
    return http.get(ENDPOINT.SLIDER_TYPE.GET_ALL)
  }

  getList = (
    params: IGetSliderTypeRequest,
  ): Promise<IResponsePagination<IGetSliderTypesResponse[]>> => {
    return http.get(ENDPOINT.SLIDER_TYPE.GET_LIST, { params: params })
  }

  // create = (
  //   body: ICreatePartnerRequest,
  // ): Promise<IResponse<ICreatePartnerResponse>> => {
  //   return http.post(ENDPOINT.PARTNER.CREATE, body)
  // }

  // update = (
  //   id: number,
  //   body: IUpdatePartnerRequest,
  // ): Promise<IResponse<IUpdatePartnerResponse>> => {
  //   return http.put(ENDPOINT.PARTNER.UPDATE.replace(":id", String(id)), body)
  // }

  // updateIsDisplayed = (
  //   id: number,
  //   body: {
  //     is_displayed: boolean
  //   },
  // ) => {
  //   return http.put(
  //     ENDPOINT.SLIDER.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
  //     body,
  //   )
  // }

  // updatePriority = (
  //   id: number,
  //   body: {
  //     priority: number
  //   },
  // ) => {
  //   return http.put(
  //     ENDPOINT.SLIDER.UPDATE_PRIORITY.replace(":id", String(id)),
  //     body,
  //   )
  // }

  // softDeleteOne = (id: number) => {
  //   return http.delete(
  //     ENDPOINT.PARTNER.SOFT_DELETE_ONE.replace(":id", String(id)),
  //   )
  // }

  // softDeleteMany = (ids: Key[]) => {
  //   return http.delete(ENDPOINT.PARTNER.SOFT_DELETE_MANY, {
  //     data: {
  //       ids,
  //     },
  //   })
  // }
}

export const SliderTypeService = new SliderType()
