import {
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ENDPOINT } from "@/services/endpoint"
import { SongGroupService } from "@/services/songGroup"
import { IModalState } from "@/types/common"
import {
  ICreateSongGroupRequest,
  IUpdateSongGroupRequest,
} from "@/types/request"
import { ISongGroup } from "@/types/response"
import { IGetPartnerResponse } from "@/types/response/partner"
import { IMAGE_TYPE, songGroupTypes } from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  getImage,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Image } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import moment from "moment"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateSongGroup, UpdateSongGroup, ViewSongGroupDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const SongGroup: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
    type: searchParams.get("type") || "",
    is_displayed: searchParams.get("is_displayed") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<ISongGroup> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateSongGroup = (values: any) => {
    const body: ICreateSongGroupRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      day_release: moment(values.day_release).format("YYYY-MM-DD"),
      time_release: values?.time_release
        ? moment(values?.time_release).format("HH:mm")
        : null,
      type: values.type,
      is_displayed: values.is_displayed,
    }

    createSongGroup(body)
  }

  const handleUpdateSongGroup = (values: any) => {
    const body: IUpdateSongGroupRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      day_release: moment(values.day_release).format("YYYY-MM-DD"),
      time_release: values?.time_release
        ? moment(values?.time_release).format("HH:mm")
        : null,
      type: values.type,
      is_displayed: values.is_displayed,
    }

    updateSongGroup({ id: values.id, body })
  }

  const handleDeleteOnePartner = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyPartner = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: ISongGroup) => {
    switch (type) {
      case MODAL.CREATE_SONG_GROUP:
        setModal({
          open: true,
          title: t("P_SONG_GROUP.TITLE_CREATE_SONG_GROUP"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateSongGroup),
          onCancel: hanldeCancelModal,
          children: <CreateSongGroup setIsLoadingUpload={setIsLoadingUpload} />,
        })
        break
      case MODAL.UPDATE_SONG_GROUP:
        setModal({
          open: true,
          title: t("P_SONG_GROUP.TITLE_UPDATE_SONG_GROUP"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateSongGroup),
          onCancel: hanldeCancelModal,
          children: (
            <UpdateSongGroup
              data={record!}
              setIsLoadingUpload={setIsLoadingUpload}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOnePartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyPartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SONG_GROUP.TITLE_DETAIL_SONG_GROUP"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongGroupDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: songGroupList,
    refetch: refetchGroupList,
    isFetching: isFetchingGroupList,
  } = useQuery([ENDPOINT.SONG_GROUP.GET_LIST, params], () =>
    SongGroupService.getList(convertRequest(params)),
  )

  const { mutate: createSongGroup, isLoading: isLoadingCreateSongGroup } =
    useMutation(
      (body: ICreateSongGroupRequest) => SongGroupService.create(body),
      {
        onSuccess: () => {
          refetchGroupList()
          handleModalContent(MODAL.NOT_SHOW)
          setSearchParams({
            ...convertToStringObject(params),
            current_page: String(1),
          })
          notificationMessage({
            message: "Thêm thành công",
            type: "success",
          })
          reset({
            is_displayed: true,
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateSongGroup } = useMutation(
    (payload: { id: number; body: IUpdateSongGroupRequest }) =>
      SongGroupService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsDislayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      SongGroupService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchGroupList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => SongGroupService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => SongGroupService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchGroupList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IGetPartnerResponse> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: "200px",
      render: (value) => value,
    },
    {
      title: "Thumbnail",
      width: "160px",
      align: "center",
      render: (record) => (
        <div className="column-thumbnail">
          <Image
            width={112}
            src={getImage(record?.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
          />
        </div>
      ),
    },
    {
      title: "Ngày phát hành",
      width: "148px",
      align: "center",
      render: (record) => {
        return (
          <div>
            {record?.time_release &&
              moment(record?.time_release, "HH:mm:ss").format("HH:mm")}{" "}
            {moment(record?.day_release).format("DD/MM/YYYY")}
          </div>
        )
      },
    },
    {
      title: "Số lượng bài hát",
      dataIndex: "type",
      width: "160px",
      align: "center",
      render: (value) => value.length,
    },
    {
      title: "Loại",
      dataIndex: "type",
      width: "160px",
      align: "center",
      render: (value) => songGroupTypes[value],
    },
    {
      title: "Trạng thái hiển thị",
      dataIndex: "is_displayed",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsDislayed({
              id: record?.id,
              body: {
                is_displayed: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: ISongGroup) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-song-group">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={songGroupList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingGroupList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={songGroupList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreateSongGroup}
        disabled={isLoadingUpload}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
