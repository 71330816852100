import {
  BreadcrumbWrap,
  ButtonWrap,
  FormItemWrap,
  InputWrap,
  Navigation,
  SelectWrap,
} from "@/components/commons"
import {
  ImportFileIcon,
  RemoveCircleIcon,
  SendIcon,
  TrashIcon,
  VerifyIcon,
} from "@/components/icons"
import { APP_ROUTER } from "@/routes"
import { IGetSliderRequest } from "@/types/request/slider"
import { EVENT_TICKET_STATUS, genderOptions } from "@/utils/constants"
import {
  convertObjectToArray,
  convertToStringObject,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { PlusOutlined } from "@ant-design/icons"
import { Col, Form, Row } from "antd"
import { Key, useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useParams, useSearchParams } from "react-router-dom"
import { MODAL } from "../constants"
import "./style.scss"
import { useQueryClient } from "react-query"
import { IGetDetailEventResponse } from "@/types/response/event"
import { IResponse } from "@/types/common"
import { ENDPOINT } from "@/services/endpoint"

interface IHeaderProps {
  params: IGetSliderRequest
  selectedItems: Key[]
  onClearSelectedItems: () => void
  handleModalContent: (type: MODAL) => void
}

const Header: React.FC<IHeaderProps> = ({
  params,
  selectedItems,
  onClearSelectedItems,
  handleModalContent,
}) => {
  const queryClient = useQueryClient()
  const { event_id: eventId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { setValue, control, reset } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const eventDetail: IResponse<IGetDetailEventResponse> | undefined =
    queryClient.getQueryData([ENDPOINT.EVENT.GET_DETAIL, eventId])

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.EVENT.PARENT"),
      href: APP_ROUTER.EVENT.LIST,
    },
    {
      title: eventDetail?.data?.name,
    },
    {
      title: t("COMMON.BREADCRUMB.EVENT.TICKET_FAN"),
      href: APP_ROUTER.EVENT.TICKET_FAN.replace(":event_id", String(eventId)),
    },
  ]

  const renderUpdateIsActiveButton = () => {
    const statusTab = searchParams.get("status")
    let label
    let icon

    if (Number(statusTab) === EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL) {
      label = t("COMMON.HEADER_TABLE.BUTTON_UPDATE_IS_ACTIVE_FALSE")
      icon = <RemoveCircleIcon fillColor="var(--color-neutral-1)" />
    } else if (Number(statusTab) === EVENT_TICKET_STATUS.CANCELED) {
      label = t("COMMON.HEADER_TABLE.BUTTON_UPDATE_IS_ACTIVE_TRUE")
      icon = <VerifyIcon fillColor="var(--color-neutral-1)" />
    } else {
      label = ""
      icon = <></>
    }

    return (
      Number(statusTab) === EVENT_TICKET_STATUS.REGISTRATION_SUCCESSFUL ||
      (Number(statusTab) === EVENT_TICKET_STATUS.CANCELED && (
        <ButtonWrap
          icon={icon}
          onClick={() =>
            handleModalContent(MODAL.CONFIRM_UPDATE_IS_ACTIVATED_MANY)
          }
          type="primary"
        >
          {label}
        </ButtonWrap>
      ))
    )
  }

  useEffect(() => {
    if (searchParams) {
      reset({
        name: searchParams.get("name") || "",
        email: searchParams.get("email") || "",
        phone: searchParams.get("phone") || "",
        ticket_code: searchParams.get("ticket_code") || "",
        note: searchParams.get("note") || "",
        gender: searchParams.get("gender")
          ? Number(searchParams.get("gender"))
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        name: formMonitor?.name || "",
        email: formMonitor?.email || "",
        phone: formMonitor?.phone || "",
        ticket_code: formMonitor?.ticket_code || "",
        note: formMonitor?.note || "",
        gender:
          formMonitor.gender === undefined ? "" : String(formMonitor.gender),
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-slider-header no-select">
      <Navigation />
      <div className="title">{t("P_TICKET_FAN.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right">
          {selectedItems?.length > 0 ? (
            <>
              <div>
                {replaceStrings(
                  t("COMMON.HEADER_TABLE.MSG_RECORD_HAS_BEEN_SELECTED"),
                  {
                    count: selectedItems?.length,
                  },
                )}
              </div>
              <div className="selected-action-btns">
                <ButtonWrap type="default" onClick={onClearSelectedItems}>
                  {t("COMMON.HEADER_TABLE.BUTTON_UNSELECT")}
                </ButtonWrap>
                {renderUpdateIsActiveButton()}
                <ButtonWrap
                  icon={<TrashIcon fillColor="var(--color-neutral-1)" />}
                  onClick={() => handleModalContent(MODAL.CONFIRM_DELETE_MANY)}
                  type="primary"
                  danger
                >
                  {t("COMMON.HEADER_TABLE.BUTTON_MOVE_TO_TRASH")}
                </ButtonWrap>
              </div>
            </>
          ) : (
            <>
              <ButtonWrap
                icon={<SendIcon />}
                onClick={() => handleModalContent(MODAL.SEND_EMAIL_ALL)}
              >
                {t("P_TICKET_FAN.HEADER.BUTTON_SEND_EMAIL_ALL_TICKET_FAN")}
              </ButtonWrap>
              <ButtonWrap
                icon={<ImportFileIcon />}
                onClick={() => handleModalContent(MODAL.IMPORT_EVENT_TICKET)}
              >
                {t("P_TICKET_FAN.HEADER.BUTTON_IMPORT_TICKET")}
              </ButtonWrap>
              <ButtonWrap
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => handleModalContent(MODAL.CREATE_EVENT_TICKET)}
              >
                {t("P_TICKET_FAN.HEADER.BUTTON_ADD_TICKET_FAN")}
              </ButtonWrap>
            </>
          )}
        </div>
      </div>
      <Form labelCol={{ span: 4, md: 6 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_TICKET_FAN.FILTER.LABEL_NAME")} colon>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_TICKET_FAN.FILTER.PLACEHOLDER_NAME")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_TICKET_FAN.FILTER.LABEL_EMAIL")}
                  colon
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_TICKET_FAN.FILTER.PLACEHOLDER_EMAIL")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_TICKET_FAN.FILTER.LABEL_PHONE")}
                  colon
                >
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_TICKET_FAN.FILTER.PLACEHOLDER_PHONE")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_TICKET_FAN.FILTER.LABEL_TICKET_CODE")}
                  colon
                >
                  <Controller
                    name="ticket_code"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_TICKET_FAN.FILTER.PLACEHOLDER_TICKET_CODE",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_TICKET_FAN.FILTER.LABEL_NOTE")} colon>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_TICKET_FAN.FILTER.PLACEHOLDER_NOTE")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_TICKET_FAN.FILTER.LABEL_GENDER")}
                  colon
                >
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("gender", "")}
                        placeholder={t(
                          "P_TICKET_FAN.FILTER.PLACEHOLDER_GENDER",
                        )}
                        options={convertObjectToArray(genderOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
