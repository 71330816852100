import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_PERMISSION_GROUP ||
        modalName === MODAL.UPDATE_PERMISSION_GROUP) &&
      yup.string().required("Vui lòng nhập Tên nhóm quyền"),
    priority:
      (modalName === MODAL.CREATE_PERMISSION_GROUP ||
        modalName === MODAL.UPDATE_PERMISSION_GROUP) &&
      yup.number().required("Vui lòng nhập Thứ tự hiển thị"),
  } as any)
