"use client"
import { PlusOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Modal, Upload, UploadProps } from "antd"
import { RcFile } from "antd/es/upload"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { t } from "@/utils/helpers"
import { IUploadedFile } from "@/types/common"
import { FILE_TYPE, UPLOAD_LIST_TYPE } from "@/utils/constants"
import ReactPlayer from "react-player/file"
import "./style.scss"

interface IPreviewModal {
  visible: boolean
  path: string
  title: string
  mimeType: string
}

const UploadWrap = ({
  onChangeFiles,
  maxCount,
  uploadedFiles,
  onRemoveFiles,
  listType,
  accept,
  className,
  ...props
}: any) => {
  const [isPlaying, setPlaying] = useState<boolean>(false)
  const [fileList, setFileList] = useState<any>([])
  const [previewModal, setPreviewModal] = useState<IPreviewModal>({
    visible: false,
    path: "",
    title: "",
    mimeType: "",
  })

  useEffect(() => {
    setFileList(uploadedFiles)
  }, [uploadedFiles])

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  const handlePreviewModal = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewModal({
      ...previewModal,
      visible: true,
      path: file.url || file.preview,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      mimeType: file.mimeType,
    })
  }

  const handleCancelModal = () => {
    const divElement = document.querySelector("#reactPlayer")
    const audioElement = divElement?.querySelector("audio")
    if (audioElement) {
      audioElement.pause()
    }

    setPreviewModal({
      ...previewModal,
      visible: false,
    })
  }

  const handleChange: UploadProps["onChange"] = ({ file, fileList }) => {
    onChangeFiles(file, fileList)
    setFileList(fileList)
  }

  const renderUploadButton = (listType: string) => {
    switch (listType) {
      case UPLOAD_LIST_TYPE.TEXT:
      case UPLOAD_LIST_TYPE.PICTURE:
        return (
          <Button icon={<UploadOutlined />}>
            {t("COMMON.UPLOAD_WRAP.BUTTON_UPLOAD")}
          </Button>
        )
      case UPLOAD_LIST_TYPE.PICTURE_CARD:
      case UPLOAD_LIST_TYPE.PICTURE_CIRCLE:
        return (
          <div className="plus-upload-btn">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              {t("COMMON.UPLOAD_WRAP.BUTTON_UPLOAD")}
            </div>
          </div>
        )
      default:
        return (
          <Button icon={<UploadOutlined />}>
            {t("COMMON.UPLOAD_WRAP.BUTTON_UPLOAD")}
          </Button>
        )
    }
  }

  const renderPreviewModalContent = (previewModal: IPreviewModal) => {
    const mimeType = previewModal.mimeType.split("/")[0]

    switch (mimeType) {
      case FILE_TYPE.IMAGE:
        return <img alt="" style={{ width: "100%" }} src={previewModal.path} />
      case FILE_TYPE.AUDIO:
        return (
          <ReactPlayer
            url={previewModal.path}
            playing={isPlaying}
            controls={true}
            onPlay={() => setPlaying(!isPlaying)}
            onPause={() => setPlaying(false)}
            id="reactPlayer"
            className="react-player"
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={clsx("c-upload-wrap", className)}>
      <Upload
        listType={listType}
        multiple={true}
        fileList={fileList}
        maxCount={maxCount}
        onPreview={handlePreviewModal}
        onChange={handleChange}
        accept={accept}
        beforeUpload={() => false}
        onRemove={(removedFile) => {
          const result = uploadedFiles?.filter(
            (item: IUploadedFile) => item.uid !== removedFile.uid,
          )
          setFileList(result)
          onRemoveFiles(removedFile)
        }}
        {...props}
      >
        {fileList.length >= maxCount ? null : renderUploadButton(listType)}
      </Upload>
      <Modal
        open={previewModal.visible}
        title={previewModal.title}
        footer={null}
        onCancel={() => {
          handleCancelModal()
          setPlaying(false)
        }}
      >
        <div className="c-preview-modal">
          {renderPreviewModalContent(previewModal)}
        </div>
      </Modal>
    </div>
  )
}

export default UploadWrap
