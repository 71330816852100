import * as yup from "yup"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = () =>
  yup.object().shape({
    email: yup
      .string()
      .matches(REGEX.EMAIL, "Vui lòng nhập đúng định dạng email!")
      .required("Vui lòng nhập email!"),
    password: yup.string().required("Vui lòng nhập mật khẩu!"),
  })
