import { TimePicker, TimePickerProps } from "antd"
import { ControllerRenderProps } from "react-hook-form"

interface IProps extends TimePickerProps {
  field?: ControllerRenderProps<any, any>
}

export const TimePickerWrap: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <TimePicker className={className} {...field} {...props} />
}
