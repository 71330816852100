import { Table, TableProps } from "antd"

export const TableWrap: React.FC<TableProps<any>> = ({
  className,
  pagination = false,
  bordered = true,
  ...props
}) => {
  return (
    <Table
      className={className}
      pagination={pagination}
      bordered={bordered}
      {...props}
    />
  )
}
