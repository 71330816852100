import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEmailTemplateRequest,
  IGetListEmailTemplateRequest,
  IUpdateEmailTemplateRequest,
} from "@/types/request"
import {
  ICreateEmailTemplateResponse,
  IGetAllEmailTemplateByYearResponse,
  IGetAllEmailTemplateWithoutCategoryResponse,
  IGetListEmailTemplateResponse,
  IUpdateEmailTemplateResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EmailTemplate {
  getAllWithoutCategory = (): Promise<
    IResponse<IGetAllEmailTemplateWithoutCategoryResponse>
  > => {
    return http.get(ENDPOINT.EMAIL_TEMPLATE.GET_ALL_WITHOUT_CATEGORY)
  }

  getAllByYear = (): Promise<IResponse<IGetAllEmailTemplateByYearResponse>> => {
    return http.get(ENDPOINT.EMAIL_TEMPLATE.GET_ALL_BY_YEAR)
  }

  getList = (
    params: IGetListEmailTemplateRequest,
  ): Promise<IResponsePagination<IGetListEmailTemplateResponse>> => {
    return http.get(ENDPOINT.EMAIL_TEMPLATE.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEmailTemplateRequest,
  ): Promise<IResponse<ICreateEmailTemplateResponse>> => {
    return http.post(ENDPOINT.EMAIL_TEMPLATE.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEmailTemplateRequest,
  ): Promise<IResponse<IUpdateEmailTemplateResponse>> => {
    return http.put(
      ENDPOINT.EMAIL_TEMPLATE.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  updateIsAttachTicket = (
    id: number,
    body: {
      is_attach_ticket: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EMAIL_TEMPLATE.UPDATE_IS_ATTACH_TICKET.replace(
        ":id",
        String(id),
      ),
      body,
    )
  }

  updateIsEnabled = (
    id: number,
    body: {
      is_enabled: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EMAIL_TEMPLATE.UPDATE_IS_ENABLED.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EMAIL_TEMPLATE.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EMAIL_TEMPLATE.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EmailTemplateService = new EmailTemplate()
