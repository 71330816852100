import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEventPositionRequest,
  IGetAllEventPositionRequest,
  IGetListEventPositionRequest,
  IUpdateEventPositionRequest,
} from "@/types/request"
import {
  ICreateEventPositionResponse,
  IGetAllEventPositionResponse,
  IGetListEventPositionResponse,
  IUpdateEventPositionResponse,
} from "@/types/response/eventPosition"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EventPosition {
  getAll = (
    params: IGetAllEventPositionRequest,
  ): Promise<IResponse<IGetAllEventPositionResponse>> => {
    return http.get(ENDPOINT.EVENT_POSITON.GET_ALL, { params })
  }

  getList = (
    params: IGetListEventPositionRequest,
  ): Promise<IResponsePagination<IGetListEventPositionResponse>> => {
    return http.get(ENDPOINT.EVENT_POSITON.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEventPositionRequest,
  ): Promise<IResponse<ICreateEventPositionResponse>> => {
    return http.post(ENDPOINT.EVENT_POSITON.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEventPositionRequest,
  ): Promise<IResponse<IUpdateEventPositionResponse>> => {
    return http.put(
      ENDPOINT.EVENT_POSITON.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  updatePrice = (
    id: number,
    body: {
      price: number
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT_POSITON.UPDATE_PRICE.replace(":id", String(id)),
      body,
    )
  }

  updateQuantity = (
    id: number,
    body: {
      quantity: number
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT_POSITON.UPDATE_QUANTITY.replace(":id", String(id)),
      body,
    )
  }

  updatePriority = (
    id: number,
    body: {
      priority: number
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT_POSITON.UPDATE_PRIORITY.replace(":id", String(id)),
      body,
    )
  }

  updateIsDisplayed = (
    id: number,
    body: {
      is_displayed: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT_POSITON.UPDATE_DISPLAYED.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EVENT_POSITON.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EVENT_POSITON.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EventPositionService = new EventPosition()
