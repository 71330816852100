import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEventRequest,
  IGetListEventRequest,
  IUpdateEventRequest,
} from "@/types/request/event"
import {
  IGetDetailEventResponse,
  IGetListEventResponse,
  IUpdateEventResponse,
} from "@/types/response/event"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Event {
  getDetail = (
    id: number,
  ): Promise<IResponsePagination<IGetDetailEventResponse>> => {
    return http.get(ENDPOINT.EVENT.GET_DETAIL.replace(":id", String(id)))
  }

  getList = (
    params: IGetListEventRequest,
  ): Promise<IResponsePagination<IGetListEventResponse>> => {
    return http.get(ENDPOINT.EVENT.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEventRequest,
  ): Promise<IResponse<ICreateEventRequest>> => {
    return http.post(ENDPOINT.EVENT.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEventRequest,
  ): Promise<IResponse<IUpdateEventResponse>> => {
    return http.put(ENDPOINT.EVENT.UPDATE.replace(":id", String(id)), body)
  }

  updateHaveDrink = (
    id: number,
    body: {
      have_drink: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT.UPDATE_HAVE_DRINK.replace(":id", String(id)),
      body,
    )
  }

  updateHavePayment = (
    id: number,
    body: {
      have_payment: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT.UPDATE_HAVE_PAYMENT.replace(":id", String(id)),
      body,
    )
  }

  updateIsDisplayed = (
    id: number,
    body: {
      is_displayed: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EVENT.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EVENT.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EVENT.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EventService = new Event()
