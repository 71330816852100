import { Card } from "antd"
import parse from "html-react-parser"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import "./style.scss"

export const ConfirmContent = () => {
  const { getValues } = useFormContext()
  const emailContentRef = useRef<HTMLQuoteElement | null>(null)
  const [emailBodyHeight, setEmailBodyHeight] = useState(0)

  useEffect(() => {
    const emailContentOffsetHeight = emailContentRef?.current?.offsetHeight || 0

    setEmailBodyHeight(emailContentOffsetHeight)
  }, [])

  return (
    <div className="c-confirm-content">
      <Card title={getValues("subject")}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: {
              autoHide: "move",
              autoHideDelay: 1000,
            },
          }}
        >
          <div
            style={{ height: emailBodyHeight > 454 ? "340px" : "max-content" }}
          >
            <blockquote ref={emailContentRef}>
              {parse(getValues("content") || "")}
            </blockquote>
          </div>
        </OverlayScrollbarsComponent>
      </Card>
    </div>
  )
}
