import { t } from "@/utils/helpers"
import { Modal } from "antd"
import clsx from "clsx"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import "overlayscrollbars/overlayscrollbars.css"
import { ButtonWrap } from "../ButtonWrap"
import "./style.scss"

export interface IModalWrapProps {
  className?: string
  title?: string
  open: boolean
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
  isNoFooter?: boolean
  loading?: boolean
  disabled?: boolean
  destroyOnClose?: boolean
  onlyCancel?: boolean
  keyboard?: boolean
  maskClosable?: boolean
  width?: number | string
  bodyHeight?: number | string
  footer?: React.ReactNode
  children: React.ReactNode
}

export const ModalWrap: React.FC<IModalWrapProps> = ({
  className,
  title,
  open,
  onOk,
  onCancel,
  okText,
  cancelText,
  isNoFooter,
  width,
  bodyHeight,
  loading,
  disabled,
  destroyOnClose = true,
  onlyCancel = false,
  keyboard = true,
  maskClosable = false,
  footer,
  children,
}) => {
  const renderFooter = () => {
    if (isNoFooter) {
      return <></>
    } else if (footer) {
      return footer
    } else {
      if (onlyCancel) {
        return (
          <ButtonWrap type="default" onClick={onCancel}>
            {cancelText || t("COMMON.MODAL_WRAP.BUTTON_CLOSE")}
          </ButtonWrap>
        )
      }
      return (
        <>
          <ButtonWrap type="default" onClick={onCancel}>
            {cancelText || t("COMMON.MODAL_WRAP.BUTTON_CANCEL")}
          </ButtonWrap>
          <ButtonWrap
            type="primary"
            loading={loading}
            onClick={onOk}
            disabled={disabled}
          >
            {okText || t("COMMON.MODAL_WRAP.BUTTON_OK")}
          </ButtonWrap>
        </>
      )
    }
  }

  return (
    <Modal
      className={clsx("c-modal-wrap", className)}
      footer={<div className="footer">{renderFooter()}</div>}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      width={width}
      cancelText={cancelText}
      destroyOnClose={destroyOnClose}
      keyboard={keyboard}
      maskClosable={maskClosable}
      centered
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            autoHide: "move",
            autoHideDelay: 1000,
          },
        }}
      >
        <div className="body" style={{ height: bodyHeight }}>
          {children}
        </div>
      </OverlayScrollbarsComponent>
    </Modal>
  )
}
