import {
  InputCellWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { EventPositionService, EventService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import {
  ICreateEventPositionRequest,
  IUpdateEventPositionRequest,
} from "@/types/request"
import { IEventPosition } from "@/types/response/eventPosition"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useParams, useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import {
  CreateEventPosition,
  UpdateEventPosition,
  ViewSongDetail,
} from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

interface IProps {
  title?: string
}

export const EventPosition: React.FC<IProps> = ({ title }) => {
  const { event_id: eventId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
    event_id: eventId,
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IEventPosition> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateEventPosition = (values: any) => {
    const body: ICreateEventPositionRequest = {
      name: values.name,
      description: values?.description,
      price: values.price,
      quantity: values.quantity,
      priority: values.priority,
      is_displayed: values.is_displayed,
      event_id: Number(eventId),
    }

    createEventPosition(body)
  }

  const handleUpdateEventPosition = (values: any) => {
    const body: IUpdateEventPositionRequest = {
      name: values.name,
      description: values?.description,
      price: values.price,
      quantity: values.quantity,
      priority: values.priority,
      is_displayed: values.is_displayed,
      event_id: Number(eventId),
    }

    updateEventPosition({ id: values.id, body })
  }

  const handleDeleteOneEventPosition = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyEventPosition = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEventPosition) => {
    switch (type) {
      case MODAL.CREATE_EVENT_POSITION:
        setModal({
          open: true,
          title: t("P_EVENT_POSITION.TITLE_CREATE_EVENT_POSITION"),
          width: 608,
          bodyHeight: 460,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEventPosition),
          onCancel: hanldeCancelModal,
          children: <CreateEventPosition />,
        })
        break
      case MODAL.UPDATE_EVENT_POSITION:
        setModal({
          open: true,
          title: t("P_EVENT_POSITION.TITLE_UPDATE_EVENT_POSITION"),
          width: 608,
          bodyHeight: 460,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateEventPosition),
          onCancel: hanldeCancelModal,
          children: <UpdateEventPosition data={record!} />,
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneEventPosition),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyEventPosition),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EVENT_POSITION.TITLE_DETAIL_EVENT_POSITION"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: positionList,
    refetch: refetchPositionList,
    isFetching: isFetchingPositionList,
  } = useQuery([ENDPOINT.EVENT_POSITON.GET_LIST, params], () =>
    EventPositionService.getList(convertRequest(params)),
  )

  useQuery([ENDPOINT.EVENT.GET_DETAIL, eventId], () =>
    EventService.getDetail(Number(eventId)),
  )

  const {
    mutate: createEventPosition,
    isLoading: isLoadingCreateEventPosition,
  } = useMutation(
    (body: ICreateEventPositionRequest) => EventPositionService.create(body),
    {
      onSuccess: () => {
        refetchPositionList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const {
    mutate: updateEventPosition,
    isLoading: isLoadingUpdateEventPosition,
  } = useMutation(
    (payload: { id: number; body: IUpdateEventPositionRequest }) =>
      EventPositionService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPositionList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePrice } = useMutation(
    (payload: { id: number; body: { price: number } }) =>
      EventPositionService.updatePrice(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPositionList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateQuantity } = useMutation(
    (payload: { id: number; body: { quantity: number } }) =>
      EventPositionService.updateQuantity(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPositionList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePriority } = useMutation(
    (payload: { id: number; body: { priority: number } }) =>
      EventPositionService.updatePriority(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPositionList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsDisplayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      EventPositionService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPositionList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EventPositionService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchPositionList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EventPositionService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchPositionList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEventPosition> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Giá",
      dataIndex: "price",
      width: "132px",
      align: "center",
      render: (value, record) => (
        <InputCellWrap
          defaultValue={value}
          onChange={(e) =>
            updatePrice({
              id: record?.id,
              body: {
                price: Number(e),
              },
            })
          }
        />
      ),
    },
    {
      title: "Thứ tự hiển thị",
      dataIndex: "priority",
      width: "132px",
      align: "center",
      render: (value, record) => (
        <InputCellWrap
          defaultValue={value}
          onChange={(e) =>
            updatePriority({
              id: record?.id,
              body: {
                priority: Number(e),
              },
            })
          }
        />
      ),
    },
    {
      title: "Trạng thái hiển thị",
      dataIndex: "is_displayed",
      width: "150px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsDisplayed({
              id: record?.id,
              body: {
                is_displayed: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      width: "132px",
      align: "center",
      render: (value, record) => (
        <InputCellWrap
          defaultValue={value}
          onChange={(e) =>
            updateQuantity({
              id: record?.id,
              body: {
                quantity: Number(e),
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEventPosition) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-event-position">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={positionList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingPositionList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={positionList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEventPosition || isLoadingUpdateEventPosition}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
