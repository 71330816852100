import { eventFormTypeRoutes } from "@/utils/constants"
import { Tabs } from "antd"
import { TabsProps } from "antd/lib"
import _ from "lodash"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const Navigation = () => {
  const navigate = useNavigate()

  const { event_id: eventId } = useParams()
  const location = useLocation()
  const currentPath = location?.pathname
  const rawPath = currentPath.replace(/\/\d+\//, "/:event_id/")
  const activeKey = _.invert(eventFormTypeRoutes)[rawPath]

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Vé",
    },
    {
      key: "7",
      label: "Hạng vé",
    },
  ]

  return (
    <Tabs
      type="card"
      items={items}
      defaultActiveKey={activeKey}
      onChange={(key) =>
        navigate(eventFormTypeRoutes[key].replace(":event_id", String(eventId)))
      }
    />
  )
}
