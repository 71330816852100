import {
  BreadcrumbWrap,
  ButtonWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { TrashIcon } from "@/components/icons"
import { APP_ROUTER } from "@/routes"
import {
  IGetAllEventTypeResponse,
  IGetAllSongGroupsResponse,
} from "@/types/response"
import {
  attachedTicketTypes,
  displayStatusOptions,
  eventDrinkOptions,
  eventPaymentOptions,
  songReleaseStatus,
} from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  convertToStringObject,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { PlusOutlined } from "@ant-design/icons"
import { Col, Form, Row } from "antd"
import { Key, useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { MODAL } from "../constants"
import "./style.scss"
import { IGetListProvincesResponse } from "@/types/response/province"

interface IHeaderProps {
  params: any
  eventTypeList?: IGetAllEventTypeResponse
  provinceList?: IGetListProvincesResponse
  selectedItems: Key[]
  onClearSelectedItems: () => void
  handleModalContent: (type: MODAL) => void
}

const Header: React.FC<IHeaderProps> = ({
  params,
  selectedItems,
  eventTypeList,
  provinceList,
  onClearSelectedItems,
  handleModalContent,
}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { setValue, control, reset } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.EVENT.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.SONG.LIST"),
      href: APP_ROUTER.EVENT.LIST,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        name: searchParams.get("name") || undefined,
        province_name: searchParams.get("province_name") || undefined,
        type_id: searchParams.get("type_id")
          ? Number(searchParams.get("type_id"))
          : undefined,
        have_drink: searchParams.get("have_drink")
          ? Number(searchParams.get("have_drink"))
          : undefined,
        have_payment: searchParams.get("have_payment")
          ? Number(searchParams.get("have_payment"))
          : undefined,
        attached_ticket_type: searchParams.get("attached_ticket_type")
          ? Number(searchParams.get("attached_ticket_type"))
          : undefined,
        is_displayed: searchParams.get("is_displayed")
          ? Number(searchParams.get("is_displayed"))
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        name: formMonitor?.name || "",
        province_name: formMonitor?.province_name || "",
        type_id:
          formMonitor.type_id === undefined ? "" : String(formMonitor.type_id),
        have_drink:
          formMonitor.have_drink === undefined
            ? ""
            : String(formMonitor.have_drink),
        have_payment:
          formMonitor.have_payment === undefined
            ? ""
            : String(formMonitor.have_payment),
        attached_ticket_type:
          formMonitor.attached_ticket_type === undefined
            ? ""
            : String(formMonitor.attached_ticket_type),
        is_displayed:
          formMonitor.is_displayed === undefined
            ? ""
            : String(formMonitor.is_displayed),
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-event-header no-select">
      <div className="title">{t("P_EVENT.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right">
          {selectedItems?.length > 0 ? (
            <>
              <div>
                {replaceStrings(t("COMMON.HEADER_TABLE.BUTTON_UNSELECT"), {
                  count: selectedItems?.length,
                })}
              </div>
              <div className="selected-action-btns">
                <ButtonWrap type="default" onClick={onClearSelectedItems}>
                  {t("COMMON.HEADER_TABLE.BUTTON_UNSELECT")}
                </ButtonWrap>
                <ButtonWrap
                  icon={<TrashIcon fillColor="var(--color-neutral-1)" />}
                  onClick={() => handleModalContent(MODAL.CONFIRM_DELETE_MANY)}
                  type="primary"
                  danger
                >
                  {t("COMMON.HEADER_TABLE.BUTTON_MOVE_TO_TRASH")}
                </ButtonWrap>
              </div>
            </>
          ) : (
            <ButtonWrap
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => handleModalContent(MODAL.CREATE_EVENT)}
            >
              {t("P_EVENT.HEADER.BUTTON_ADD_EVENT")}
            </ButtonWrap>
          )}
        </div>
      </div>
      <Form labelCol={{ span: 4, md: 6 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={5}>
                <FormItemWrap label={t("P_EVENT.FILTER.LABEL_NAME")} colon>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_EVENT.FILTER.PLACEHOLDER_NAME")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={7}>
                <FormItemWrap
                  label={t("P_EVENT.FILTER.LABEL_PROVINCE_NAME")}
                  colon
                >
                  <Controller
                    name="province_name"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("province_name", "")}
                        placeholder={t(
                          "P_EVENT.FILTER.PLACEHOLDER_PROVINCE_NAME",
                        )}
                        options={convertArrayToOptions(
                          provinceList!,
                          "name_with_type",
                          "name_with_type",
                          "string",
                        )}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_EVENT.FILTER.LABEL_TYPE_ID")} colon>
                  <Controller
                    name="type_id"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("type_id", "")}
                        placeholder={t("P_EVENT.FILTER.PLACEHOLDER_TYPE_ID")}
                        options={convertArrayToOptions(
                          eventTypeList!,
                          "id",
                          "name",
                        )}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EVENT.FILTER.LABEL_HAVE_DRINK")}
                  colon
                >
                  <Controller
                    name="have_drink"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("have_drink", "")}
                        placeholder={t("P_EVENT.FILTER.PLACEHOLDER_HAVE_DRINK")}
                        options={convertObjectToArray(eventDrinkOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={5}>
                <FormItemWrap
                  label={t("P_EVENT.FILTER.LABEL_HAVE_PAYMENT")}
                  colon
                >
                  <Controller
                    name="have_payment"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("have_payment", "")}
                        placeholder={t(
                          "P_EVENT.FILTER.PLACEHOLDER_HAVE_PAYMENT",
                        )}
                        options={convertObjectToArray(eventPaymentOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={7}>
                <FormItemWrap
                  label={t("P_EVENT.FILTER.LABEL_ATTACHED_TICKET_TYPE")}
                  colon
                >
                  <Controller
                    name="attached_ticket_type"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("attached_ticket_type", "")}
                        placeholder={t(
                          "P_EVENT.FILTER.PLACEHOLDER_ATTACHED_TICKET_TYPE",
                        )}
                        options={convertObjectToArray(attachedTicketTypes)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_EVENT.FILTER.LABEL_IS_DISPLAYED")}
                  colon
                >
                  <Controller
                    name="is_displayed"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("is_displayed", "")}
                        placeholder={t(
                          "P_EVENT.FILTER.PLACEHOLDER_IS_DISPLAYED",
                        )}
                        options={convertObjectToArray(displayStatusOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
