import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateSongGroupRequest,
  IGetListSongRequest,
  IUpdateSongGroupRequest,
} from "@/types/request"
import {
  ICreateSongGroupResponse,
  IGetAllSongGroupsResponse,
  IGetListSongResponse,
  IUpdateSongGroupResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class SongGroup {
  getAll = (): Promise<IResponse<IGetAllSongGroupsResponse>> => {
    return http.get(ENDPOINT.SONG_GROUP.GET_ALL)
  }

  getList = (
    params: IGetListSongRequest,
  ): Promise<IResponsePagination<IGetListSongResponse>> => {
    return http.get(ENDPOINT.SONG_GROUP.GET_LIST, { params: params })
  }

  create = (
    body: ICreateSongGroupRequest,
  ): Promise<IResponse<ICreateSongGroupResponse>> => {
    return http.post(ENDPOINT.SONG_GROUP.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateSongGroupRequest,
  ): Promise<IResponse<IUpdateSongGroupResponse>> => {
    return http.put(ENDPOINT.SONG_GROUP.UPDATE.replace(":id", String(id)), body)
  }

  updateIsDisplayed = (
    id: number,
    body: {
      is_displayed: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.SONG_GROUP.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.SONG_GROUP.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.SONG_GROUP.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const SongGroupService = new SongGroup()
