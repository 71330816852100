export enum MODAL {
  CREATE_EVENT_TICKET = "CreateEventTicket",
  UPDATE_EVENT_TICKET = "UpdateEventTicket",
  SEND_ONE_EMAIL = "SendOneEmail",
  SEND_EMAIL_ALL = "SendEmailAll",
  ROW_DETAIL = "RowDetail",
  VIEW_PAYMENT_DETAIL = "ViewPaymentDetail",
  VIEW_SEND_EMAIL_LOG_DETAIL = "ViewSendEmailLogDetail",
  VIEW_SEND_TICKET_LOG_DETAIL = "ViewSendTicketLogDetail",
  VIEW_ALL_SEND_EMAIL_LOG = "ViewAllSendEmailLog",
  VIEW_ALL_SEND_TICKET_LOG = "ViewAllSendTicketLog",
  VIEW_ALL_PAYMENT_LOG = "ViewAllPaymentLog",
  VERIFY_PAYMENT = "VerifyPayment",
  ADD_PAYMENT_HISTORY = "AddPaymentHistory",
  PAYMENT = "Payment",
  IMPORT_EVENT_TICKET = "ImportEventTicket",
  SEARCH_USER = "SearchUser",
  CONFIRM_UPDATE_IS_ACTIVATED_MANY = "ConfirmUpdateIsActivatedMany",
  CONFIRM_UPDATE_SUCCESS_ONE = "ConfirmUpdateSuccessOne",
  CONFIRM_DELETE_ONE = "ConfirmDeleteOne",
  CONFIRM_DELETE_MANY = "ConfirmDeleteMany",
  NOT_SHOW = "NotShow",
}

export enum MODAL_COMMON_TYPE {
  TICKET = "Ticket",
  EMAIL = "Email",
  PAYMENT = "Payment",
}
