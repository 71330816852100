import { FormItemWrap } from "@/components/commons"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { IEventTicket, IGetAllEventTypeResponse } from "@/types/response"
import { UPLOAD_LIST_TYPE } from "@/utils/constants"
import { Form, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"

interface IProps {
  data: IEventTicket
  setIsLoadingUpload: (value: boolean) => void
}

export const AddPaymentHistory: React.FC<IProps> = ({
  data,
  setIsLoadingUpload,
}) => {
  const [uploadedBills, setUploadedBills] = useState<IUploadedFile[]>([])

  const {
    setValue,
    reset,
    formState: { errors },
  } = useFormContext()

  const { mutate: uploadBill, isLoading: isLoadingUploadBill } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedBills((prevUploadBills) =>
          prevUploadBills.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedBills((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeBill = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedBills((prevUploadBills) => [...prevUploadBills, tempFile])

      uploadBill({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedBills((prevUploadBills) =>
        prevUploadBills.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    const isLoading = isLoadingUploadBill
    setIsLoadingUpload(isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUploadBill])

  useEffect(() => {
    setValue("bill_path", uploadedBills[0]?.url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedBills])

  useEffect(() => {
    reset({
      modal_name: MODAL.ADD_PAYMENT_HISTORY,
      id: data.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Bill thanh toán"
        required
        validateStatus={errors?.bill_path ? "error" : ""}
        help={<>{errors?.bill_path && errors?.bill_path?.message}</>}
      >
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
          onChangeFiles={handleChangeBill}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedBills}
          setUploadedBills={setUploadedBills}
          maxCount={1}
          accept="image/*"
        />
      </FormItemWrap>
    </Form>
  )
}
