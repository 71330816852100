import { FormItemWrap } from "@/components/commons"
import { IEmailLog } from "@/types/response"
import { sendEmailColors, sendEmailStatus } from "@/utils/constants"
import { Form, Tag } from "antd"
import moment from "moment"
import "./style.scss"

interface IProps {
  data: IEmailLog
}

export const ViewSendLogDetail: React.FC<IProps> = ({ data }) => {
  const {
    subject,
    sender_name,
    sender_email,
    receiver_name,
    receiver_email,
    status,
    created_at,
  } = data

  return (
    <div className="c-view-payment-detail">
      <Form layout="vertical">
        <FormItemWrap label="Tiêu đề">{subject}</FormItemWrap>
        <FormItemWrap label="Người gửi">
          {sender_email} ({sender_name})
        </FormItemWrap>
        <FormItemWrap label="Người nhận">
          {receiver_email} ({receiver_name})
        </FormItemWrap>
        <FormItemWrap label="Thời gian gửi">
          {moment(created_at).format("DD/MM/YYYY HH:mm:ss")}
        </FormItemWrap>
        <FormItemWrap label="Trạng thái">
          <Tag color={sendEmailColors[status]}>{sendEmailStatus[status]}</Tag>
        </FormItemWrap>
      </Form>
    </div>
  )
}
