import { CheckboxWrap, FormItemWrap, InputWrap } from "@/components/commons"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import { Divider, Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"
import "./style.scss"

interface IProps {
  permissionList: IPermissionGroup[]
}

export const CreateRole: React.FC<IProps> = ({ permissionList }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setValue("modal_name", MODAL.CREATE_ROLE)
  }, [])

  return (
    <div className="c-create-role">
      <Form layout="vertical">
        <FormItemWrap
          label="Tên vai trò"
          required
          validateStatus={errors?.display_name ? "error" : ""}
          help={<>{errors?.display_name && errors?.display_name?.message}</>}
        >
          <Controller
            name="display_name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap
                placeholder="Nhập tên vai trò"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Key"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap placeholder="Nhập key" field={field} allowClear />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Quyền"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          {permissionList?.map((group, index) => {
            return (
              <>
                {group?.permissions?.length > 0 && (
                  <Divider
                    key={index}
                    orientation="left"
                    orientationMargin="0"
                    plain
                    className="no-select"
                  >
                    {group.name}
                  </Divider>
                )}
                {group?.permissions?.map((permission, index) => (
                  <Controller
                    key={index}
                    name={String(permission.name)}
                    control={control}
                    render={({ field }) => (
                      <CheckboxWrap field={field}>
                        {permission.display_name}
                      </CheckboxWrap>
                    )}
                  />
                ))}
              </>
            )
          })}
        </FormItemWrap>
      </Form>
    </div>
  )
}
