import { ColumnActionWrap } from "@/components/commons/ColumnActionWrap"
import {
  AddCircleIcon,
  ArrowCircleRightIcon,
  EditIcon,
  EmailIcon,
  EyeIcon,
  QrCodeIcon,
  TrashIcon,
  VerifyIcon,
} from "@/components/icons"
import { IColumnActionItem } from "@/types/common"
import { IEventTicket } from "@/types/response"
import { t } from "@/utils/helpers"
import { useState } from "react"
import { MODAL } from "../constants"

interface IColumnActionProps {
  record: IEventTicket
  handleModalContent: (type: MODAL, record?: IEventTicket) => void
}

export const ColumnAction: React.FC<IColumnActionProps> = ({
  record,
  handleModalContent,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenDrawer = () => {
    setOpen(true)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const items: IColumnActionItem[] = [
    {
      key: "1",
      type: "group",
      label: t("COMMON.COLUMN_ACTION.GROUP_ACTION"),
      children: [
        {
          key: "1-1",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.UPDATE_EVENT_TICKET, record)
                setOpen(false)
              }}
            >
              <EditIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_EDIT")}
            </div>
          ),
        },
        {
          key: "1-2",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.PAYMENT, record)
                setOpen(false)
              }}
            >
              <QrCodeIcon />
              Thanh toán
            </div>
          ),
        },
        {
          key: "1-3",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.VERIFY_PAYMENT, record)
                setOpen(false)
              }}
            >
              <VerifyIcon />
              Xác minh thanh toán
            </div>
          ),
        },
        {
          key: "1-4",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.ADD_PAYMENT_HISTORY, record)
                setOpen(false)
              }}
            >
              <AddCircleIcon />
              Thêm lịch sử thanh toán
            </div>
          ),
        },
        {
          key: "1-5",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.CONFIRM_UPDATE_SUCCESS_ONE, record)
                setOpen(false)
              }}
            >
              <ArrowCircleRightIcon />
              Đăng ký thành công
            </div>
          ),
        },
        {
          key: "1-6",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.SEND_ONE_EMAIL, record)
                setOpen(false)
              }}
            >
              <EmailIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_SEND_ONE_EMAIL")}
            </div>
          ),
        },
        {
          key: "1-7",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.CONFIRM_DELETE_ONE, record)
                setOpen(false)
              }}
            >
              <TrashIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_SOFT_DELETE")}
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      label: t("COMMON.COLUMN_ACTION.GROUP_DISPLAY"),
      type: "group",
      children: [
        {
          key: "2-1",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.ROW_DETAIL, record)
                setOpen(false)
              }}
            >
              <EyeIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_VIEW_DETAIL")}
            </div>
          ),
        },
      ],
    },
  ]

  return (
    <ColumnActionWrap
      menu={items}
      onOpenDrawer={handleOpenDrawer}
      onCloseDrawer={handleCloseDrawer}
      visibleDrawer={open}
    />
  )
}
