import { FormItemWrap, SwitchWrap, TableEditorWrap } from "@/components/commons"
import { IGetSliderResponse } from "@/types/response"
import { IMAGE_TYPE } from "@/utils/constants"
import { getImage } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, Image, Typography } from "antd"
import "./style.scss"

interface IViewPartnerDetailProps {
  record: any
}

export const ViewSliderDetail: React.FC<IViewPartnerDetailProps> = ({
  record,
}) => {
  const { title, description, link_to, slider_type, priority, is_displayed } =
    record

  return (
    <div className="c-view-slider-detail">
      <Form layout="vertical">
        <FormItemWrap label="Tiêu đề">{title}</FormItemWrap>
        <FormItemWrap label="Mô tả">
          {description ?? "Chưa có mô tả"}
        </FormItemWrap>
        <FormItemWrap label="Hình ảnh">
          <div className="field-image">
            {record?.image_path ? (
              <Image
                width={112}
                src={getImage(record?.image_path, IMAGE_TYPE.THUMBNAIL)}
              />
            ) : (
              <img
                src={getImage(record?.image_path, IMAGE_TYPE.THUMBNAIL)}
                alt={record.title}
              />
            )}
          </div>
        </FormItemWrap>
        <FormItemWrap label="Loại">
          <div
            className="field-slider-type no-select"
            style={{
              background: slider_type?.bg_color,
            }}
          >
            <span className="name">{slider_type?.name}</span>
          </div>
        </FormItemWrap>
        <FormItemWrap label="Chuyển hướng">
          <Typography.Link href={link_to} target="_blank">
            {link_to}
          </Typography.Link>
        </FormItemWrap>
        <FormItemWrap label="Thứ tự hiển thị">{priority}</FormItemWrap>
        <FormItemWrap label="Trạng thái hiển thị">
          <SwitchWrap
            defaultValue={is_displayed}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled
          />
        </FormItemWrap>
        <FormItemWrap label="Người khởi tạo">
          <TableEditorWrap
            users={[record.created_by]}
            time={record.created_at}
            type="single"
          />
        </FormItemWrap>
        <FormItemWrap label="Người chỉnh sửa">
          <TableEditorWrap
            users={record.updated_by}
            time={record.updated_at}
            type="multiple"
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
