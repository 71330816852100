import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    display_name:
      (modalName === MODAL.CREATE_ROLE || modalName === MODAL.UPDATE_ROLE) &&
      yup.string().required("Vui lòng nhập Tên vai trò"),
    name:
      (modalName === MODAL.CREATE_ROLE || modalName === MODAL.UPDATE_ROLE) &&
      yup.string().required("Vui lòng nhập Key"),
  } as any)
