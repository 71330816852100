import { FormItemWrap, InputWrap } from "@/components/commons"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

export const CreateEmailCategory: React.FC = () => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.CREATE_EMAIL_CATEGORY,
    })
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên danh mục"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên danh mục"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
