import { IResponsePagination } from "@/types/common"
import { IGetListEmailLogRequest } from "@/types/request/emailLog"
import { IGetListEventLogResponse } from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EmailLog {
  getList = (
    params: IGetListEmailLogRequest,
  ): Promise<IResponsePagination<IGetListEventLogResponse>> => {
    return http.get(ENDPOINT.EMAIL_LOGS.GET_LIST, { params: params })
  }
}
export const EmailLogService = new EmailLog()
