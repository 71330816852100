import {
  BreadcrumbWrap,
  ButtonWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { SendIcon, TrashIcon } from "@/components/icons"
import { APP_ROUTER } from "@/routes"
import { IGetPartnerRequest } from "@/types/request/partner"
import { genderOptions } from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  convertToStringObject,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { PlusOutlined } from "@ant-design/icons"
import { Col, Form, Row } from "antd"
import { Key, useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { MODAL } from "../constants"
import "./style.scss"
import { IProvince } from "@/types/response/province"

interface IHeaderProps {
  params: IGetPartnerRequest
  selectedItems: Key[]
  provinceList: IProvince[]
  onClearSelectedItems: () => void
  handleModalContent: (type: MODAL) => void
}

const Header: React.FC<IHeaderProps> = ({
  params,
  selectedItems,
  onClearSelectedItems,
  handleModalContent,
  provinceList,
}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { setValue, control, reset } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.USER.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.USER.LIST"),
      href: APP_ROUTER.USER.LIST,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        name: searchParams.get("name") || "",
        is_displayed: searchParams.get("is_displayed")
          ? Number(searchParams.get("is_displayed"))
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        full_name: formMonitor?.full_name || "",
        username: formMonitor?.username || "",
        email: formMonitor?.email || "",
        phone: formMonitor?.phone || "",
        gender:
          formMonitor.gender === undefined ? "" : String(formMonitor.gender),
        hometown: formMonitor?.hometown || "",
        current_city: formMonitor?.current_city || "",
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-user-header no-select">
      <div className="title">{t("P_USER.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right">
          {selectedItems?.length > 0 ? (
            <>
              <div>
                {replaceStrings(
                  t("COMMON.HEADER_TABLE.MSG_RECORD_HAS_BEEN_SELECTED"),
                  {
                    count: selectedItems?.length,
                  },
                )}
              </div>
              <div className="selected-action-btns">
                <ButtonWrap type="default" onClick={onClearSelectedItems}>
                  {t("COMMON.HEADER_TABLE.BUTTON_UNSELECT")}
                </ButtonWrap>
                <ButtonWrap
                  icon={<TrashIcon fillColor="var(--color-neutral-1)" />}
                  onClick={() => handleModalContent(MODAL.CONFIRM_DELETE_MANY)}
                  type="primary"
                  danger
                >
                  {t("COMMON.HEADER_TABLE.BUTTON_MOVE_TO_TRASH")}
                </ButtonWrap>
              </div>
            </>
          ) : (
            <>
              <ButtonWrap
                icon={<SendIcon />}
                onClick={() => handleModalContent(MODAL.SEND_EMAIL_ALL)}
              >
                {t("P_USER.HEADER.BUTTON_SEND_EMAIL_TO_ALL_USER")}
              </ButtonWrap>
              <ButtonWrap
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => handleModalContent(MODAL.CREATE_USER)}
              >
                {t("P_USER.HEADER.BUTTON_ADD_USER")}
              </ButtonWrap>
            </>
          )}
        </div>
      </div>
      <Form labelCol={{ span: 4, md: 8 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_NAME")} colon>
                  <Controller
                    name="full_name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_NAME")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_USERNAME")} colon>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_USERNAME")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_EMAIL")} colon>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_EMAIL")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_PHONE")} colon>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_PHONE")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_GENDER")} colon>
                  <Controller
                    name="gender"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("gender", "")}
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_GENDER")}
                        options={convertObjectToArray(genderOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_USER.FILTER.LABEL_HOMETOWN")} colon>
                  <Controller
                    name="hometown"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("hometown", "")}
                        placeholder={t("P_USER.FILTER.PLACEHOLDER_HOMETOWN")}
                        options={convertArrayToOptions(
                          provinceList!,
                          "name_with_type",
                          "name_with_type",
                          "string",
                        )}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_USER.FILTER.LABEL_CURRENT_CITY")}
                  colon
                >
                  <Controller
                    name="current_city"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("current_city", "")}
                        placeholder={t(
                          "P_USER.FILTER.PLACEHOLDER_CURRENT_CITY",
                        )}
                        options={convertArrayToOptions(
                          provinceList!,
                          "name_with_type",
                          "name_with_type",
                          "string",
                        )}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
