import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateRoleRequest,
  IGetListRoleRequest,
  IUpdateRoleRequest,
} from "@/types/request/role"
import {
  ICreateRoleResponse,
  IGetAllRoleResponse,
  IGetListRoleResponse,
  IUpdateRoleResponse,
} from "@/types/response/role"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Role {
  getAll = (): Promise<IResponse<IGetAllRoleResponse>> => {
    return http.get(ENDPOINT.ROLE.GET_ALL)
  }

  getList = (
    params: IGetListRoleRequest,
  ): Promise<IResponsePagination<IGetListRoleResponse>> => {
    return http.get(ENDPOINT.ROLE.GET_LIST, { params: params })
  }

  create = (
    body: ICreateRoleRequest,
  ): Promise<IResponse<ICreateRoleResponse>> => {
    return http.post(ENDPOINT.ROLE.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateRoleRequest,
  ): Promise<IResponse<IUpdateRoleResponse>> => {
    return http.put(ENDPOINT.ROLE.UPDATE.replace(":id", String(id)), body)
  }

  forceDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.ROLE.FORCE_DELETE_ONE.replace(":id", String(id)),
    )
  }

  forceDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.ROLE.FORCE_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const RoleService = new Role()
