import {
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { EmailCategoryService, EmailTemplateService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import {
  ICreateEmailTemplateRequest,
  IUpdateEmailTemplateRequest,
} from "@/types/request"
import {
  IEmailCategoryCustomize,
  IEmailTemplate,
  ISong,
} from "@/types/response"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import {
  CreateEmailTemplate,
  UpdateEmailTemplate,
  ViewSongDetail,
} from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const EmailTemplate: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const [secondModal, setSecondModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
    status: searchParams.get("status") || "",
    group_id: searchParams.get("group_id") || "",
    is_warning: searchParams.get("is_warning") || "",
    is_displayed: searchParams.get("is_displayed") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<ISong> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateEmailTemplate = (values: any) => {
    const body: ICreateEmailTemplateRequest = {
      subject: values.subject,
      content: values.content,
      category_ids: values?.category_ids,
      is_attach_ticket: values.is_attach_ticket,
      is_enabled: values.is_enabled,
    }

    createEmailTemplate(body)
  }

  const handleUpdateSong = (values: any) => {
    const body: IUpdateEmailTemplateRequest = {
      subject: values.subject,
      content: values.content,
      category_ids: values?.category_ids,
      is_attach_ticket: values.is_attach_ticket,
      is_enabled: values.is_enabled,
    }

    updateEmailTemplate({ id: values.id, body })
  }

  const handleDeleteOneEmailTemplate = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyEmailTemplate = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEmailTemplate) => {
    switch (type) {
      case MODAL.CREATE_EMAIL_TEMPLATE:
        setModal({
          open: true,
          title: t("P_EMAIL_TEMPLATE.TITLE_CREATE_EMAIL_TEMPLATE"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateEmailTemplate),
          onCancel: hanldeCancelModal,
          children: (
            <CreateEmailTemplate
              setIsLoadingUpload={setIsLoadingUpload}
              emailCategoryList={emailCategoryList?.data}
            />
          ),
        })
        break
      case MODAL.UPDATE_EMAIL_TEMPLATE:
        setModal({
          open: true,
          title: t("P_EMAIL_TEMPLATE.TITLE_UPDATE_EMAIL_TEMPLATE"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateSong),
          onCancel: hanldeCancelModal,
          children: (
            <UpdateEmailTemplate
              data={record!}
              emailCategoryList={emailCategoryList?.data}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneEmailTemplate),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyEmailTemplate),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EMAIL_TEMPLATE.TITLE_DETAIL_EMAIL_TEMPLATE"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: emailTemplateList,
    refetch: refetchEmailTemplateList,
    isFetching: isFetchingEmailTemplateList,
  } = useQuery([ENDPOINT.EMAIL_TEMPLATE.GET_LIST, params], () =>
    EmailTemplateService.getList(convertRequest(params)),
  )

  const { data: emailCategoryList, isFetching: isFetchingEmailCategoryList } =
    useQuery([ENDPOINT.EMAIL_CATEGORY.GET_ALL, params], () =>
      EmailCategoryService.getAll(),
    )

  const {
    mutate: createEmailTemplate,
    isLoading: isLoadingCreateEmailTemplate,
  } = useMutation(
    (body: ICreateEmailTemplateRequest) => EmailTemplateService.create(body),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const {
    mutate: updateEmailTemplate,
    isLoading: isLoadingUpdateEmailTemplate,
  } = useMutation(
    (payload: { id: number; body: IUpdateEmailTemplateRequest }) =>
      EmailTemplateService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsAttachTicket } = useMutation(
    (payload: { id: number; body: { is_attach_ticket: boolean } }) =>
      EmailTemplateService.updateIsAttachTicket(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsEnabled } = useMutation(
    (payload: { id: number; body: { is_enabled: boolean } }) =>
      EmailTemplateService.updateIsEnabled(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const formMonitor = useWatch({
    control,
  })

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EmailTemplateService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EmailTemplateService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchEmailTemplateList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<ISong> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "subject",
      width: "300px",
      render: (value) => value,
    },
    {
      title: "Danh mục",
      dataIndex: "categories",
      width: "150px",
      align: "center",
      render: (value: IEmailCategoryCustomize[]) => {
        return (
          <div className="column-categories">
            {value?.length > 0
              ? value?.map((category, index) => {
                  return (
                    <Tag className="no-select" color="#108ee9" key={index}>
                      {category?.name}
                    </Tag>
                  )
                })
              : ""}
          </div>
        )
      },
    },
    {
      title: "Đính kèm vé",
      dataIndex: "is_attach_ticket",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsAttachTicket({
              id: record?.id,
              body: {
                is_attach_ticket: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Trạng thái sử dụng",
      dataIndex: "is_enabled",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsEnabled({
              id: record?.id,
              body: {
                is_enabled: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEmailTemplate) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-email-template">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={emailTemplateList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingEmailTemplateList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={emailTemplateList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEmailTemplate || isLoadingUpdateEmailTemplate}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
      <ModalWrap {...secondModal}>
        <FormProvider {...methods}>{secondModal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
