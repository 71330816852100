import {
  ColorPickerWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { Form, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"
import "./style.scss"
import { convertArrayToOptions, convertObjectToArray } from "@/utils/helpers"
import { colorStyleOptions, gradientStyleOptions } from "@/utils/constants"

interface IProps {
  setIsLoadingUpload: (value: boolean) => void
}

export const CreateSliderType: React.FC<IProps> = ({ setIsLoadingUpload }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([])

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  const { mutate: uploadFile, isLoading } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return { ...file, url: response?.data?.path, status: "done" }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeFile = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
      }

      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, tempFile])

      uploadFile({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedFiles((prevUploadedFiles) =>
        prevUploadedFiles.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  useEffect(() => {
    setIsLoadingUpload(isLoading)
  }, [isLoading])

  useEffect(() => {
    setValue("modal_name", MODAL.CREATE_SLIDER_TYPE)
  }, [])

  useEffect(() => {
    setValue("image_path", uploadedFiles[0]?.url)
  }, [uploadedFiles])

  return (
    <div className="c-create-slider-type">
      <Form layout="vertical">
        <FormItemWrap
          label="Tên"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap placeholder="Nhập tên" field={field} allowClear />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Màu nền"
          required
          validateStatus={errors?.bg_color ? "error" : ""}
          help={<>{errors?.bg_color && errors?.bg_color?.message}</>}
        >
          <div className="field-bg-color">
            <div
              className="preview-bg-color"
              style={{
                background: "red",
              }}
            ></div>
            <div className="right">
              <div className="color-style">
                <Controller
                  name="style"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <SelectWrap
                      placeholder="Chọn loại nền"
                      options={convertObjectToArray(colorStyleOptions)}
                      field={field}
                      allowClear
                    />
                  )}
                />
                <Controller
                  name="gradient_style"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <SelectWrap
                      placeholder="Chọn kiểu"
                      options={convertObjectToArray(gradientStyleOptions)}
                      field={field}
                      allowClear
                    />
                  )}
                />
              </div>
              <div className="color-pickers">
                <Controller
                  name="bg_color"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <ColorPickerWrap
                      defaultValue="#1677FF"
                      showText
                      field={field}
                    />
                  )}
                />
                <Controller
                  name="bg_color"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <ColorPickerWrap
                      defaultValue="#1677FF"
                      showText
                      field={field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </FormItemWrap>
        <FormItemWrap
          label="Màu nền nút chuyển hướng"
          required
          validateStatus={errors?.cta_hover_color ? "error" : ""}
          help={
            <>{errors?.cta_hover_color && errors?.cta_hover_color?.message}</>
          }
        >
          <Controller
            name="cta_hover_color"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <ColorPickerWrap defaultValue="#1677FF" showText field={field} />
            )}
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
