import {
  DatePickerWrap,
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import { IGetAllEventPositionResponse } from "@/types/response/eventPosition"
import { eventTicketTypes, genderOptions } from "@/utils/constants"
import { convertArrayToOptions, convertObjectToArray } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"
import moment from "moment"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: any
  eventPositionList?: IGetAllEventPositionResponse
}

export const UpdateEventTicket: React.FC<IProps> = ({
  data,
  eventPositionList,
}) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_EVENT_TICKET,
      id: data.id,
      name: data?.name,
      birthday: data?.birthday ? moment(data?.birthday) : null,
      gender: data?.gender,
      email: data?.email,
      phone: data?.phone,
      facebook: data?.facebook,
      position_id: data?.event_position?.id,
      drink_id: data?.drink_id,
      is_activated: data?.is_activated,
      owner_id: data?.owner?.id,
      note: data?.note,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Họ và tên"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Vui lòng nhập Họ và tên"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Ngày sinh"
        required
        validateStatus={errors?.birthday ? "error" : ""}
        help={<>{errors?.birthday && errors?.birthday?.message}</>}
      >
        <Controller
          name="birthday"
          control={control}
          render={({ field }) => (
            <DatePickerWrap
              className="c-birthday"
              placeholder="Ví dụ: 13/05/1989"
              format="DD/MM/YYYY"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Giới tính"
        required
        validateStatus={errors?.gender ? "error" : ""}
        help={<>{errors?.gender && errors?.gender?.message}</>}
      >
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn giới tính"
              options={convertObjectToArray(genderOptions)}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Số điện thoại"
        required
        validateStatus={errors?.phone ? "error" : ""}
        help={<>{errors?.phone && errors?.phone?.message}</>}
      >
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: 0396687531"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Email"
        required
        validateStatus={errors?.email ? "error" : ""}
        help={<>{errors?.email && errors?.email?.message}</>}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: email@example.com"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Facebook"
        required
        validateStatus={errors?.facebook ? "error" : ""}
        help={<>{errors?.facebook && errors?.facebook?.message}</>}
      >
        <Controller
          name="facebook"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Ví dụ: https://www.facebook.com/denvaufc"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Hạng vé"
        required
        validateStatus={errors?.position_id ? "error" : ""}
        help={<>{errors?.position_id && errors?.position_id?.message}</>}
      >
        <Controller
          name="position_id"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn hạng vé"
              options={convertArrayToOptions(eventPositionList!, "id", "name")}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Người thụ hưởng"
        required
        validateStatus={errors?.owner_id ? "error" : ""}
        help={<>{errors?.owner_id && errors?.owner_id?.message}</>}
      >
        <Controller
          name="owner_id"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap placeholder="Nhập User ID" field={field} />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Ghi chú">
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <TextAreaWrap
              placeholder="Nhập ghi chú"
              rows={6}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái kích hoạt" required>
        <Controller
          name="is_activated"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
