import {
  ButtonWrap,
  CKEditorWrap,
  FormItemWrap,
  InputWrap,
  ModalWrap,
  RangerPickerWrap,
  SelectAddressWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IModalState, IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { IGetAllEventTypeResponse } from "@/types/response"
import { IEvent } from "@/types/response/event"
import {
  ATTACHED_TICKET_TYPE_ENUMS,
  EVENT_FORM_TYPE_ENUMS,
  EVENT_FORM_URL_TYPE_ENUMS,
  UPLOAD_LIST_TYPE,
  attachedTicketTypes,
  eventFormUrlTypes,
} from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  getEventForm,
  getFileUploadedObject,
  notificationModal,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Form, Tabs, UploadFile } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { EVENT_FORM_KEY_ACTIVE_ENUMS, MODAL } from "../../constants"
import { formSchemaFn } from "../../formSchemaFn"

interface IProps {
  data: IEvent
  setIsLoadingUpload: (value: boolean) => void
  eventTypeList?: IGetAllEventTypeResponse
}

export const UpdateEvent: React.FC<IProps> = ({
  data,
  setIsLoadingUpload,
  eventTypeList,
}) => {
  const [uploadedThumbnails, setUploadedThumbnails] = useState<IUploadedFile[]>(
    [],
  )
  const [subModal, setSubModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const {
    control,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  const subMethods = useForm({
    resolver: yupResolver(formSchemaFn(MODAL.SELECT_ADDRESS)),
  })

  const addressData = {
    province_name: formMonitor?.province_name,
    district_name: formMonitor?.district_name,
    ward_name: formMonitor?.ward_name,
    street_name: formMonitor?.street_name,
    house_number: formMonitor?.house_number,
    place_name: formMonitor?.place_name,
  }

  const { reset: subReset, handleSubmit: subHandleSubmit } = subMethods

  const { mutate: uploadThumbnail, isLoading: isLoadingUploadThumbnail } =
    useMutation((body: IUploadFileRequest) => FileService.uploadImage(body), {
      onSuccess: (response) => {
        setUploadedThumbnails((prevUploadThumbnails) =>
          prevUploadThumbnails.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedThumbnails((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    })

  const handleChangeThumbnail = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedThumbnails((prevUploadThumbnails) => [
        ...prevUploadThumbnails,
        tempFile,
      ])

      uploadThumbnail({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedThumbnails((prevUploadThumbnails) =>
        prevUploadThumbnails.filter(
          (uploadFile) => uploadFile.uid !== file.uid,
        ),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  const handleSubModalContent = (type: MODAL) => {
    switch (type) {
      case MODAL.SELECT_ADDRESS:
        setSubModal({
          open: true,
          title: "Chọn địa chỉ",
          width: 608,
          bodyHeight: 500,
          onOk: subHandleSubmit(handleSaveAddress),
          onCancel: handleCancelAddress,
          children: <SelectAddressWrap addressData={addressData} />,
        })
        break
      default:
        setSubModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const handleDescription = (data: string) => {
    setValue("description", data)
  }

  const handleCancelAddress = () => {
    if (!formMonitor?.location1 && !formMonitor?.location2) {
      subReset({})
    }
    handleSubModalContent(MODAL.NOT_SHOW)
  }

  const handleSaveAddress = (data: any) => {
    if (
      !data?.province_name ||
      !data?.district_name ||
      !data?.ward_name ||
      !data?.street_name
    ) {
      return notificationModal({
        type: "warning",
        className: "c-event-popup",
        content: "Vui lòng nhập địa chỉ",
        okText: t("COMMON.MODAL_WRAP.BUTTON_OK"),
        maskClosable: true,
      })
    }

    setValue("province_name", data?.province_name)
    setValue("district_name", data?.district_name)
    setValue("ward_name", data?.ward_name)
    setValue("street_name", data?.street_name)
    setValue("house_number", data?.house_number)
    setValue("place_name", data?.place_name)
    setValue(
      "location1",
      `${data?.province_name}, ${data?.district_name}, ${data?.ward_name}`,
    )
    setValue(
      "location2",
      `${data?.place_name ? data?.place_name + " - " : ""}${
        data?.house_number ? data?.house_number + " - " : ""
      }${data?.street_name}`,
    )
    handleSubModalContent(MODAL.NOT_SHOW)
  }

  useEffect(() => {
    const isLoading = isLoadingUploadThumbnail
    setIsLoadingUpload(isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUploadThumbnail])

  useEffect(() => {
    setValue("thumbnail_path", uploadedThumbnails[0]?.url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedThumbnails])

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_EVENT,
      id: data.id,
      name: data.name,
      thumbnail_path: data?.thumbnail_path,
      description: data?.description,
      time_organizing: [
        moment(data.start_time, "YYYY-MM-DD HH:mm:ss"),
        moment(data.end_time, "YYYY-MM-DD HH:mm:ss"),
      ],
      province_name: data.province_name,
      district_name: data.district_name,
      ward_name: data.ward_name,
      street_name: data.street_name,
      house_number: data?.house_number,
      place_name: data?.place_name,
      location1: `${data?.province_name}, ${data?.district_name}, ${data?.ward_name}`,
      location2: `${data?.place_name ? data?.place_name + " - " : ""}${
        data?.house_number ? data?.house_number + " - " : ""
      }${data?.street_name}`,
      type_id: data.event_type.id,
      have_drink: data.have_drink,
      have_payment: data.have_payment,
      attached_ticket_type: data.attached_ticket_type,
      attached_ticket_template: data?.attached_ticket_template,
      is_displayed: data.is_displayed,

      ticket_fan_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_FAN,
      )?.id,
      ticket_fan_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_FAN,
      )?.is_displayed,
      ticket_fan_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_FAN,
      )?.url_type,
      ticket_fan_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_FAN,
      )?.optional_url,

      ticket_team_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_TEAM,
      )?.id,
      ticket_team_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_TEAM,
      )?.is_displayed,
      ticket_team_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_TEAM,
      )?.url_type,
      ticket_team_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.TICKET_TEAM,
      )?.optional_url,

      join_support_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_SUPPORT,
      )?.id,
      join_support_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_SUPPORT,
      )?.is_displayed,
      join_support_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_SUPPORT,
      )?.url_type,
      join_support_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_SUPPORT,
      )?.optional_url,

      join_media_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_MEDIA,
      )?.id,
      join_media_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_MEDIA,
      )?.is_displayed,
      join_media_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_MEDIA,
      )?.url_type,
      join_media_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_MEDIA,
      )?.optional_url,

      join_talent_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_TALENT,
      )?.id,
      join_talent_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_TALENT,
      )?.is_displayed,
      join_talent_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_TALENT,
      )?.url_type,
      join_talent_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.JOIN_TALENT,
      )?.optional_url,

      attendance_id: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.ATTENDANCE,
      )?.id,
      attendance_active: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.ATTENDANCE,
      )?.is_displayed,
      attendance_url_type: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.ATTENDANCE,
      )?.url_type,
      attendance_optional_url: getEventForm(
        data.event_forms,
        EVENT_FORM_TYPE_ENUMS.ATTENDANCE,
      )?.optional_url,
    })

    data?.thumbnail_path &&
      setUploadedThumbnails([getFileUploadedObject(data?.thumbnail_path)])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Form layout="vertical">
      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="Chính" key={EVENT_FORM_KEY_ACTIVE_ENUMS.MAIN}>
          <FormItemWrap
            label="Tên sự kiện"
            required
            validateStatus={errors?.name ? "error" : ""}
            help={<>{errors?.name && errors?.name?.message}</>}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <InputWrap
                  placeholder="Nhập tên sự kiện"
                  field={field}
                  allowClear
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap label="Thumbnail">
            <UploadWrap
              listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
              onChangeFiles={handleChangeThumbnail}
              onRemoveFiles={handleRemoveFile}
              uploadedFiles={uploadedThumbnails}
              setUploadedThumbnails={setUploadedThumbnails}
              maxCount={1}
              accept="image/*"
            />
          </FormItemWrap>
          <FormItemWrap
            label="Mô tả"
            required
            validateStatus={errors?.description ? "error" : ""}
            help={<>{errors?.description && errors?.description?.message}</>}
          >
            <Controller
              name="description"
              control={control}
              render={() => (
                <CKEditorWrap
                  defaultValue={data?.description}
                  onChange={handleDescription}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Thời gian tổ chức"
            required
            validateStatus={errors?.time_organizing ? "error" : ""}
            help={
              <>{errors?.time_organizing && errors?.time_organizing?.message}</>
            }
          >
            <Controller
              name="time_organizing"
              control={control}
              render={({ field }) => (
                <RangerPickerWrap
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format={["DD/MM/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm:ss"]}
                  showTime
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Địa điểm tổ chức"
            required
            validateStatus={
              errors?.province_name ||
              errors?.district_name ||
              errors?.ward_name ||
              errors?.street_name ||
              errors?.location1 ||
              errors?.location2
                ? "error"
                : ""
            }
            help={
              (errors?.province_name ||
                errors?.district_name ||
                errors?.ward_name ||
                errors?.street_name ||
                errors?.location1 ||
                errors?.location2) &&
              "Vui lòng nhập địa chỉ!"
            }
          >
            <div className="address">
              <Controller
                name="location1"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <InputWrap
                    readOnly
                    field={field}
                    placeholder="Tỉnh/Thành phố, Quận/Huyện, Phường/Xã"
                  />
                )}
              />
              <Controller
                name="location2"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <InputWrap
                    readOnly
                    field={field}
                    placeholder="Tên đường, Tòa nhà, Số nhà"
                  />
                )}
              />
              <ButtonWrap
                type="primary"
                style={{ width: "100%" }}
                onClick={() => handleSubModalContent(MODAL.SELECT_ADDRESS)}
              >
                {formMonitor?.location1 && formMonitor?.location2
                  ? "Chỉnh sửa địa chỉ"
                  : "Thêm địa chỉ"}
              </ButtonWrap>
            </div>
          </FormItemWrap>
          <FormItemWrap
            label="Phân loại"
            required
            validateStatus={errors?.type_id ? "error" : ""}
            help={<>{errors?.type_id && errors?.type_id?.message}</>}
          >
            <Controller
              name="type_id"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn phân loại"
                  options={convertArrayToOptions(eventTypeList!, "id", "name")}
                  field={field}
                  allowClear
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Vé đính kèm"
            required
            validateStatus={errors?.attached_ticket_type ? "error" : ""}
            help={
              <>
                {errors?.attached_ticket_type &&
                  errors?.attached_ticket_type?.message}
              </>
            }
          >
            <Controller
              name="attached_ticket_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại"
                  options={convertObjectToArray(attachedTicketTypes)}
                  field={field}
                  allowClear
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Tên tệp chứa mẫu"
            required={
              formMonitor.attached_ticket_type ===
              ATTACHED_TICKET_TYPE_ENUMS.PDF_TICKET
            }
            validateStatus={errors?.attached_ticket_template ? "error" : ""}
            help={
              <>
                {errors?.attached_ticket_template &&
                  errors?.attached_ticket_template?.message}
              </>
            }
          >
            <Controller
              name="attached_ticket_template"
              control={control}
              render={({ field }) => (
                <InputWrap
                  disabled={
                    formMonitor.attached_ticket_type !==
                    ATTACHED_TICKET_TYPE_ENUMS.PDF_TICKET
                  }
                  placeholder="Nhập tên tệp"
                  addonAfter=".blade.php"
                  field={field}
                  allowClear
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap label="Nước uống" required>
            <Controller
              name="have_drink"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap label="Thanh toán" required>
            <Controller
              name="have_payment"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="is_displayed"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
        </Tabs.TabPane>

        {/* Vé (Fan) */}
        <Tabs.TabPane
          tab="Vé (Fan)"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.TICKET_FAN}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="ticket_fan_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.ticket_fan_url_type ? "error" : ""}
            help={
              <>
                {errors?.ticket_fan_url_type &&
                  errors?.ticket_fan_url_type?.message}
              </>
            }
          >
            <Controller
              name="ticket_fan_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.ticket_fan_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.ticket_fan_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.ticket_fan_optional_url &&
                    errors?.ticket_fan_optional_url?.message}
                </>
              }
            >
              <Controller
                name="ticket_fan_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>

        {/* Vé (Đội ngũ) */}
        <Tabs.TabPane
          tab="Vé (Đội ngũ)"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.TICKET_TEAM}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="ticket_team_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.ticket_team_url_type ? "error" : ""}
            help={
              <>
                {errors?.ticket_team_url_type &&
                  errors?.ticket_team_url_type?.message}
              </>
            }
          >
            <Controller
              name="ticket_team_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.ticket_team_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.ticket_team_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.ticket_team_optional_url &&
                    errors?.ticket_team_optional_url?.message}
                </>
              }
            >
              <Controller
                name="ticket_team_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>

        {/* Cộng tác viên (Nhân sự) */}
        <Tabs.TabPane
          tab="Cộng tác viên (Nhân sự)"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.JOIN_SUPPORT}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="join_support_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.join_support_url_type ? "error" : ""}
            help={
              <>
                {errors?.join_support_url_type &&
                  errors?.join_support_url_type?.message}
              </>
            }
          >
            <Controller
              name="join_support_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.join_support_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.join_support_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.join_support_optional_url &&
                    errors?.join_support_optional_url?.message}
                </>
              }
            >
              <Controller
                name="join_support_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>

        {/* Media (Nhân sự) */}
        <Tabs.TabPane
          tab="Media (Nhân sự)"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.JOIN_MEDIA}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="join_media_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.join_media_url_type ? "error" : ""}
            help={
              <>
                {errors?.join_media_url_type &&
                  errors?.join_media_url_type?.message}
              </>
            }
          >
            <Controller
              name="join_media_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.join_media_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.join_media_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.join_media_optional_url &&
                    errors?.join_media_optional_url?.message}
                </>
              }
            >
              <Controller
                name="join_media_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>

        {/* Talent (Nhân sự) */}
        <Tabs.TabPane
          tab="Talent (Nhân sự)"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.JOIN_TALENT}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="join_talent_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.join_talent_url_type ? "error" : ""}
            help={
              <>
                {errors?.join_talent_url_type &&
                  errors?.join_talent_url_type?.message}
              </>
            }
          >
            <Controller
              name="join_talent_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.join_talent_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.join_support_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.join_support_optional_url &&
                    errors?.join_support_optional_url?.message}
                </>
              }
            >
              <Controller
                name="join_talent_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>

        {/* Điểm danh */}
        <Tabs.TabPane
          tab="Điểm danh"
          key={EVENT_FORM_KEY_ACTIVE_ENUMS.ATTENDANCE}
        >
          <FormItemWrap label="Trạng thái hiển thị" required>
            <Controller
              name="attendance_active"
              control={control}
              render={({ field }) => (
                <SwitchWrap
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          <FormItemWrap
            label="Liên kết biểu mẫu"
            required
            validateStatus={errors?.attendance_url_type ? "error" : ""}
            help={
              <>
                {errors?.attendance_url_type &&
                  errors?.attendance_url_type?.message}
              </>
            }
          >
            <Controller
              name="attendance_url_type"
              control={control}
              render={({ field }) => (
                <SelectWrap
                  placeholder="Chọn loại liên kết"
                  options={convertObjectToArray(eventFormUrlTypes)}
                  field={field}
                />
              )}
            />
          </FormItemWrap>
          {formMonitor.attendance_url_type ===
            EVENT_FORM_URL_TYPE_ENUMS.OPTIONAL && (
            <FormItemWrap
              label="Liên kết"
              required
              validateStatus={errors?.join_support_optional_url ? "error" : ""}
              help={
                <>
                  {errors?.join_support_optional_url &&
                    errors?.join_support_optional_url?.message}
                </>
              }
            >
              <Controller
                name="attendance_optional_url"
                control={control}
                render={({ field }) => (
                  <InputWrap
                    placeholder="Nhập liên kết"
                    field={field}
                    allowClear
                  />
                )}
              />
            </FormItemWrap>
          )}
        </Tabs.TabPane>
      </Tabs>
      <ModalWrap {...subModal}>
        <FormProvider {...subMethods}>{subModal.children}</FormProvider>
      </ModalWrap>
    </Form>
  )
}
