import { Input } from "antd"
import { TextAreaProps } from "antd/es/input"
import { ControllerRenderProps } from "react-hook-form"

interface ITextAreaWrapProps extends TextAreaProps {
  field?: ControllerRenderProps<any, any>
}

export const TextAreaWrap: React.FC<ITextAreaWrapProps> = ({
  className,
  field,
  ...props
}) => {
  return <Input.TextArea className={className} {...field} {...props} />
}
