import { createContext, useContext, useEffect, useState } from "react"
import { deleteCookie, getCookie, setCookie } from "@/utils/helpers/storage"
import { IGetMeResponse, ILoginResponse } from "@/types/response"

interface AuthContextData {
  isAuthenticated: boolean
  currentUser: IGetMeResponse | null
  setCurrentUser: (currentUser: IGetMeResponse) => void
  logInSuccess: (data: ILoginResponse) => Promise<void>
  logOutSuccess: () => void
}

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextData>({
  isAuthenticated: false,
  currentUser: null,
  setCurrentUser: (currentUser: IGetMeResponse) => {},
  logInSuccess: (data: ILoginResponse) => Promise.resolve(),
  logOutSuccess: () => {},
})

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<IGetMeResponse | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getCookie("accessToken"),
  )

  // Watch currentUser
  useEffect(() => {
    if (!!getCookie("accessToken")) {
      if (!currentUser) {
        const user = getCookie("currentUser")
        if (user) {
          setCurrentUser(JSON.parse(user) as IGetMeResponse)
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false)
        }
      } else {
        setCookie("currentUser", JSON.stringify(currentUser), { expires: 365 })
      }
    }
  }, [currentUser])

  const logInSuccess = async (data: ILoginResponse) => {
    const { access_token } = data

    // save access token in cookies
    setCookie("accessToken", access_token, { expires: 365 })

    setIsAuthenticated(true)
  }

  const logOutSuccess = () => {
    // Remove access token from cookies
    deleteCookie("accessToken")
    deleteCookie("currentUser")

    // Clear provider state
    setCurrentUser(null)
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        currentUser,
        setCurrentUser,
        logInSuccess,
        logOutSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  // Custom hook to use auth context
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}

export { AuthProvider, useAuth }
