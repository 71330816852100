import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_EMAIL_ACCOUNT ||
        modalName === MODAL.UPDATE_EMAIL_ACCOUNT) &&
      yup.string().required("Vui lòng nhập Tên hiển thị!"),
    username:
      (modalName === MODAL.CREATE_EMAIL_ACCOUNT ||
        modalName === MODAL.UPDATE_EMAIL_ACCOUNT) &&
      yup
        .string()
        .required("Vui lòng nhập Email!")
        .matches(REGEX.EMAIL, "Vui lòng nhập đúng định dạng Email!"),
  } as any)
