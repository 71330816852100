import { notificationMessage } from "@/utils/helpers"
import { Typography } from "antd"
import "./style.scss"

const { Paragraph } = Typography

interface IProps {
  value: string
}

export const CopyWrap: React.FC<IProps> = ({ value }) => {
  return (
    <div className="copy-wrap">
      <Paragraph
        copyable={{
          tooltips: ["Sao chép", "Đã sao chép"],
          onCopy: () =>
            notificationMessage({
              message: "Đã sao chép vào bộ nhớ tạm",
              type: "success",
            }),
        }}
      >
        {value}
      </Paragraph>
    </div>
  )
}
