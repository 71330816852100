import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateUserRequest,
  IGetListUserRequest,
  IUpdateUserRequest,
} from "@/types/request"
import {
  ICreateUserResponse,
  IGetListUserResponse,
  IUpdateUserResponse,
} from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class User {
  getList = (
    params: IGetListUserRequest,
  ): Promise<IResponsePagination<IGetListUserResponse>> => {
    return http.get(ENDPOINT.USER.GET_LIST, { params: params })
  }

  create = (
    body: ICreateUserRequest,
  ): Promise<IResponse<ICreateUserResponse>> => {
    return http.post(ENDPOINT.USER.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateUserRequest,
  ): Promise<IResponse<IUpdateUserResponse>> => {
    return http.put(ENDPOINT.USER.UPDATE.replace(":id", String(id)), body)
  }

  updateIsActivated = (
    id: number,
    body: {
      is_activated: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.USER.UPDATE_IS_ACTIVATED.replace(":id", String(id)),
      body,
    )
  }
}

export const UserService = new User()
