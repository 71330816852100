import { Button, ButtonProps } from "antd"
import clsx from "clsx"
import "./style.scss"

interface ExtendedButtonProps extends ButtonProps {
  status?: "success" | "danger" | "warning"
}

export const ButtonWrap: React.FC<ExtendedButtonProps> = ({
  className,
  children,
  type,
  status,
  ...props
}) => {
  const renderType = () => {
    switch (status) {
      case "success":
      case "danger":
      case "warning":
        return "text"

      default:
        return type
    }
  }

  return (
    <Button
      className={clsx(
        "c-button",
        {
          "c-button--success": status === "success",
          "c-button--danger": status === "danger",
          "c-button--warning": status === "warning",
        },
        className,
      )}
      type={renderType()}
      {...props}
    >
      {children}
    </Button>
  )
}
