import { IEmailLog, IEventTicket } from "@/types/response"
import { Tag } from "antd"
import clsx from "clsx"
import moment from "moment"
import React from "react"
import { MODAL, MODAL_COMMON_TYPE } from "../constants"
import "./style.scss"

interface IProps {
  data: IEmailLog[]
  record: IEventTicket
  type: MODAL_COMMON_TYPE
  className?: string
  handleModalContent: (type: MODAL, record?: IEventTicket) => void
  handleSecondModalContent: (type: MODAL, data?: any) => void
}

export const Log: React.FC<IProps> = ({
  data,
  record,
  type,
  className,
  handleModalContent,
  handleSecondModalContent,
}) => {
  const LIMIT = 4
  const renderLogs = () => {
    const defaultLogs = (
      <>
        {data?.slice(0, LIMIT).map((log, index) => {
          return (
            <Tag
              color="#108ee9"
              key={index}
              onClick={() =>
                handleSecondModalContent(
                  type === MODAL_COMMON_TYPE.EMAIL
                    ? MODAL.VIEW_SEND_EMAIL_LOG_DETAIL
                    : type === MODAL_COMMON_TYPE.TICKET
                    ? MODAL.VIEW_SEND_TICKET_LOG_DETAIL
                    : MODAL.VIEW_PAYMENT_DETAIL,
                  log,
                )
              }
            >
              {moment(log.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </Tag>
          )
        })}
      </>
    )
    return (
      <>
        {defaultLogs}
        {data?.length > LIMIT && (
          <div
            className="more-btn"
            onClick={() =>
              handleModalContent(
                type === MODAL_COMMON_TYPE.EMAIL
                  ? MODAL.VIEW_ALL_SEND_EMAIL_LOG
                  : type === MODAL_COMMON_TYPE.TICKET
                  ? MODAL.VIEW_ALL_SEND_TICKET_LOG
                  : MODAL.VIEW_ALL_PAYMENT_LOG,
                record,
              )
            }
          >
            Xem thêm (+{data?.length - LIMIT})
          </div>
        )}
      </>
    )
  }
  return (
    <div className={clsx("c-send-log no-select", className)}>
      {renderLogs()}
    </div>
  )
}
