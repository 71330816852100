import { CheckboxWrap, FormItemWrap, InputWrap } from "@/components/commons"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import { IRole } from "@/types/response/role"
import { Divider, Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: IRole
  permissionList?: IPermissionGroup[]
}

export const UpdateRole: React.FC<IProps> = ({ data, permissionList }) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  useEffect(() => {
    const baseValue = {
      modal_name: MODAL.UPDATE_ROLE,
      id: data.id,
      name: data?.name,
      display_name: data.display_name,
    }

    const permissions = data?.permissions?.map((item) => item.name)
    const permissionObject = permissions.reduce<{ [key: string]: boolean }>(
      (acc, key) => {
        acc[key] = true
        return acc
      },
      {},
    )

    reset({
      ...baseValue,
      ...permissionObject,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên vai trò"
        required
        validateStatus={errors?.display_name ? "error" : ""}
        help={<>{errors?.display_name && errors?.display_name?.message}</>}
      >
        <Controller
          name="display_name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên vai trò"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Key"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap placeholder="Nhập key" field={field} allowClear />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Quyền"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        {permissionList?.map((group, index) => {
          return (
            <>
              {group?.permissions?.length > 0 && (
                <Divider
                  key={index}
                  orientation="left"
                  orientationMargin="0"
                  plain
                  className="no-select"
                >
                  {group.name}
                </Divider>
              )}
              {group?.permissions?.map((permission, index) => (
                <Controller
                  key={index}
                  name={String(permission.name)}
                  control={control}
                  render={({ field }) => (
                    <CheckboxWrap
                      field={field}
                      checked={
                        (formMonitor as any)[String(permission.name)] && true
                      }
                    >
                      {permission.display_name}
                    </CheckboxWrap>
                  )}
                />
              ))}
            </>
          )
        })}
      </FormItemWrap>
    </Form>
  )
}
