import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEmailCategoryRequest,
  IGetAllEmailCategoryRequest,
  IGetListEmailCategoryRequest,
  IUpdateEmailCategoryRequest,
} from "@/types/request"
import {
  ICreateEmailCategoryResponse,
  IGetAllEmailCategoryResponse,
  IGetListEmailCategoryResponse,
  IUpdateEmailCategoryResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EmailCategory {
  getAll = (
    params?: IGetAllEmailCategoryRequest,
  ): Promise<IResponse<IGetAllEmailCategoryResponse>> => {
    return http.get(ENDPOINT.EMAIL_CATEGORY.GET_ALL, { params: params })
  }

  getList = (
    params: IGetListEmailCategoryRequest,
  ): Promise<IResponsePagination<IGetListEmailCategoryResponse>> => {
    return http.get(ENDPOINT.EMAIL_CATEGORY.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEmailCategoryRequest,
  ): Promise<IResponse<ICreateEmailCategoryResponse>> => {
    return http.post(ENDPOINT.EMAIL_CATEGORY.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEmailCategoryRequest,
  ): Promise<IResponse<IUpdateEmailCategoryResponse>> => {
    return http.put(
      ENDPOINT.EMAIL_CATEGORY.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EMAIL_CATEGORY.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EMAIL_CATEGORY.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EmailCategoryService = new EmailCategory()
