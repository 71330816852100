import { ISvgIcon } from "@/types/common"

export const ArrowUpIcon: React.FC<ISvgIcon> = ({
  width = 20,
  height = 20,
  strokeColor = "var(--color-neutral-8)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke={strokeColor}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m17 14l-5-5l-5 5"
      />
    </svg>
  )
}
