import { Drawer } from "antd"
import { useState } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"
import "./style.scss"

export const Layout = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const handleClose = () => {
    setOpenMenu(false)
  }

  const handleShowMenu = () => {
    setOpenMenu(true)
  }

  return (
    <div className="c-layout">
      <Sidebar />
      <Drawer
        placement="left"
        width={250}
        onClose={handleClose}
        open={openMenu}
        closable={false}
        closeIcon={<></>}
        classNames={{
          body: "c-drawer-sidebar__body",
        }}
      >
        <Sidebar />
      </Drawer>
      <div className="c-content">
        <Header onShowMenu={handleShowMenu} />
        <div className="content-inner">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
