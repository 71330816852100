import {
  CopyWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableWrap,
  TooltipWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { UserService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { PartnerService } from "@/services/partner"
import { ProvinceService } from "@/services/province"
import { RoleService } from "@/services/role"
import { IModalState } from "@/types/common"
import {
  ICreatePartnerRequest,
  ICreateUserRequest,
  IUpdatePartnerRequest,
  IUpdateUserRequest,
} from "@/types/request"
import { IRoleCustomize, IUser } from "@/types/response"
import { IGetPartnerResponse } from "@/types/response/partner"
import {
  IMAGE_TYPE,
  USER_ACCOUNT_TYPES,
  userVerifyColor,
  userVerifyStatus,
} from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  getImage,
  notificationMessage,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tabs, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { TabsProps } from "antd/lib"
import moment from "moment"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import {
  CreateUser,
  SendEmailAll,
  UpdateUser,
  ViewPartnerDetail,
} from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IPartnerProps {
  title?: string
}

export const User: React.FC<IPartnerProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    full_name: searchParams.get("full_name") || "",
    username: searchParams.get("username") || "",
    email: searchParams.get("email") || "",
    phone: searchParams.get("phone") || "",
    gender: searchParams.get("gender") || "",
    hometown: searchParams.get("hometown") || "",
    current_city: searchParams.get("current_city") || "",
    account_type:
      searchParams.get("account_type") || String(USER_ACCOUNT_TYPES.USER),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IUser> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleChangeTab = (key: string) => {
    setSelectedItems([])
    setSearchParams({
      ...convertToStringObject(params),
      account_type: key || String(USER_ACCOUNT_TYPES.USER),
    })
  }

  const handleCreateUser = (values: any) => {
    console.log("values", values)

    const body: ICreateUserRequest = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      avatar_path: values?.avatar_path,
      birthday: values.birthday.format("YYYY-MM-DD"),
      gender: values.gender,
      hometown: values?.hometown,
      current_city: values?.current_city,
      is_activated: values.is_activated,
      roles: values?.roles,
    }
    createUser(body)
  }

  const handleUpdateUser = (values: any) => {
    const body: IUpdateUserRequest = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      avatar_path: values?.avatar_path,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      gender: values.gender,
      hometown: values?.hometown,
      current_city: values?.current_city,
      is_activated: values.is_activated,
      roles: values?.roles,
    }

    updateUser({ id: values.id, body })
  }

  const handleDeleteOnePartner = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyPartner = () => {
    softDeleteMany(selectedItems)
  }

  const handleCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
    reset({})
  }

  const handleModalContent = (type: MODAL, record?: IUser) => {
    switch (type) {
      case MODAL.CREATE_USER:
        setModal({
          open: true,
          title: "Thêm đối tác",
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateUser),
          onCancel: handleCancelModal,
          children: (
            <CreateUser
              setIsLoadingUpload={setIsLoadingUpload}
              provinceList={provinceList?.data!}
              roleList={roleList?.data!}
            />
          ),
        })
        break
      case MODAL.UPDATE_USER:
        setModal({
          open: true,
          title: "Cập nhật đối tác",
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateUser),
          onCancel: handleCancelModal,
          children: (
            <UpdateUser
              setIsLoadingUpload={setIsLoadingUpload}
              provinceList={provinceList?.data!}
              roleList={roleList?.data!}
              data={record!}
            />
          ),
        })
        break
      case MODAL.SEND_EMAIL_ALL:
        setModal({
          open: true,
          title: t("P_TICKET_FAN.TITLE_SEND_ONE_EMAIL"),
          width: 620,
          okText: t("COMMON.MODAL_WRAP.BUTTON_SEND_EMAIL"),
          onCancel: handleCancelModal,
          isNoFooter: true,
          children: <SendEmailAll handleCancelModal={handleCancelModal} />,
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: "Chuyển vào thùng rác 1 bản ghi",
          width: 486,
          okText: "Chấp nhận",
          onOk: handleSubmit(handleDeleteOnePartner),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content="Xoá 1"
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: "Chuyển vào thùng rác nhiều bản ghi",
          width: 486,
          okText: "Chấp nhận",
          onOk: handleSubmit(handleDeleteManyPartner),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content="Xoá 1"
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: "Chi tiết",
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: <ViewPartnerDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: userList,
    refetch: refetchUserList,
    isFetching: isFetchingUserList,
  } = useQuery([ENDPOINT.USER.GET_LIST, params], () =>
    UserService.getList(convertRequest(params)),
  )

  const { data: provinceList } = useQuery(
    [ENDPOINT.PROVINCE.GET_PROVINCES],
    () => ProvinceService.getProvinces(),
  )

  const { data: roleList } = useQuery([ENDPOINT.ROLE.GET_ALL], () =>
    RoleService.getAll(),
  )

  const { mutate: createUser, isLoading: isLoadingCreatePartner } = useMutation(
    (body: ICreateUserRequest) => UserService.create(body),
    {
      onSuccess: () => {
        refetchUserList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateUser, isLoading: isLoadingUpdatePartner } = useMutation(
    (payload: { id: number; body: IUpdateUserRequest }) =>
      UserService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchUserList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsActivated } = useMutation(
    (payload: { id: number; body: { is_activated: boolean } }) =>
      UserService.updateIsActivated(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchUserList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => PartnerService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchUserList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => PartnerService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchUserList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "60px",
      align: "center",
    },
    {
      title: "Tên",
      width: "180px",
      render: (record: IUser) => (
        <div className="column-name">
          <div className="avatar">
            <img
              src={getImage(record?.avatar_path, IMAGE_TYPE.AVATAR)}
              alt={record.full_name}
            />
          </div>
          <div className="right">
            <span className="name">{record.full_name}</span>
            <span className="username">@{record.username}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "180px",
      render: (value) => <CopyWrap value={value} />,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: "90px",
      render: (value) => value && <CopyWrap value={value} />,
    },
    {
      title: "Ngày sinh",
      dataIndex: "birthday",
      width: "80px",
      align: "center",
      render: (value) =>
        value && moment(value, "YYYY-MM-DD").format("DD/MM/YYYY"),
    },
    {
      title: "Vai trò hệ thống",
      dataIndex: "roles",
      width: "100px",
      align: "center",
      render: (value: IRoleCustomize[]) => (
        <div className="column-roles">
          {value?.map((role, index) => (
            <Tag key={index} color="var(--color-green-1)" className="no-select">
              {role?.display_name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Xác minh tài khoản",
      dataIndex: "email_verified_at",
      width: "118px",
      align: "center",
      render: (value) => {
        const status = value ? 1 : 2
        return value ? (
          <TooltipWrap title={moment(value).format("DD/MM/YYYY HH:mm:ss")}>
            <Tag color={userVerifyColor[status]}>
              {userVerifyStatus[status]}
            </Tag>
          </TooltipWrap>
        ) : (
          <Tag color={userVerifyColor[status]}>{userVerifyStatus[status]}</Tag>
        )
      },
    },
    {
      title: "Kích hoạt",
      dataIndex: "is_activated",
      width: "80px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsActivated({
              id: record?.id,
              body: {
                is_activated: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IUser) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  const items: TabsProps["items"] = [
    {
      key: String(USER_ACCOUNT_TYPES.USER),
      label: "Người dùng",
    },
    {
      key: String(USER_ACCOUNT_TYPES.ADMIN),
      label: "Quản lý",
    },
    {
      key: String(USER_ACCOUNT_TYPES.DEACTIVATED),
      label: "Hủy kích hoạt",
    },
  ]

  return (
    <div className="p-user">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
        provinceList={provinceList?.data!}
      />
      <Tabs
        type="card"
        items={items}
        activeKey={
          searchParams.get("account_type") || String(USER_ACCOUNT_TYPES.USER)
        }
        onChange={handleChangeTab}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={userList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingUserList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={userList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreatePartner || isLoadingUpdatePartner}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
