import {
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { IPermission } from "@/types/response/permission"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import { convertArrayToOptions } from "@/utils/helpers"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: IPermissionGroup
  permissionList: IPermission[]
}

export const UpdatePermissionGroup: React.FC<IProps> = ({
  data,
  permissionList,
}) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_PERMISSION_GROUP,
      id: data.id,
      name: data?.name,
      permission_ids: data?.permissions?.map((obj) => obj.id),
      priority: data.priority,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên nhóm quyền"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên nhóm quyền"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Nhóm">
        <Controller
          name="permission_ids"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn quyền"
              options={convertArrayToOptions(
                permissionList!,
                "id",
                "display_name",
              )}
              field={field}
              mode="multiple"
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Thứ tự hiển thị"
        required
        validateStatus={errors?.priority ? "error" : ""}
        help={<>{errors?.priority && errors?.priority?.message}</>}
      >
        <Controller
          name="priority"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap placeholder="Nhập thứ tự" field={field} />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
