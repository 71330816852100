import {
  IEmailLogCustomize,
  IEventTicketPaymentCustomize,
} from "@/types/response"
import { Tag } from "antd"
import moment from "moment"
import { MODAL, MODAL_COMMON_TYPE } from "../../constants"
import "./style.scss"

interface IProps {
  data: IEmailLogCustomize[] | IEventTicketPaymentCustomize[]
  type: MODAL_COMMON_TYPE
  handleSecondModalContent: (type: MODAL, data?: any) => void
}

export const ViewAllLog: React.FC<IProps> = ({
  data,
  type,
  handleSecondModalContent,
}) => {
  return (
    <div className="c-view-all-log">
      {data.map((log, index) => {
        return (
          <Tag
            color="#108ee9"
            key={index}
            onClick={() =>
              handleSecondModalContent(
                type === MODAL_COMMON_TYPE.EMAIL
                  ? MODAL.VIEW_SEND_EMAIL_LOG_DETAIL
                  : type === MODAL_COMMON_TYPE.TICKET
                  ? MODAL.VIEW_SEND_TICKET_LOG_DETAIL
                  : MODAL.NOT_SHOW,
                log,
              )
            }
          >
            {moment(log.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </Tag>
        )
      })}
    </div>
  )
}
