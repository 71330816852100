/* eslint-disable no-useless-escape */

import { APP_ROUTER } from "@/routes"
import { Options } from "@/types/common"

export const SERVER_URL = process.env.REACT_APP_BACKEND_URL

export enum IMAGE_TYPE {
  THUMBNAIL = "thumbnail",
  AVATAR = "avatar",
  LOGO = "logo",
}

export enum SELECT_TYPE {
  SLIDER_TYPE = "SliderType",
  SONG_GROUP = "song_group",
  NEWS_AGENCY = "news_agency",
  NEWS_TOPIC = "news_topic",
  SONG_GROUP_TYPE = "song_group_type",
  ARTIST = "artist",
  EVENT_TYPE = "event_type",
  URL_TYPE = "url_type",
  ACCESS_MODE = "access_mode",
  AGE_LIMIT = "age_limit",
  ENCRYPTION = "encryption",
  FROM_EMAIL = "from_email",
  USER = "user",
  PROFILE = "profile",
  PAYMENT = "Payment",
}

export enum NOTIFICATION_STATUS {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

export enum PARAM_FOR {
  FORM = "form",
  PARAM = "form",
}

export const avatarSample =
  "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"

export const REGEX = {
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PASSWORD:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@¥$!%*#?&(){}\[\]<>:;\-_,."'+|\\\/^])[A-Za-z\d@¥$!%*#?&(){}\[\]<>:;\-_,."'+|\\\/^]{8,}$/,
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  FACEBOOK_URL:
    /^(?:https?:\/\/)?(?:www\.|m\.|mbasic\.)?facebook\.com\/[a-zA-Z0-9_\-\.]+(?:\?.*)?$/,
}

export const genderOptions: Options = {
  1: "Nam",
  2: "Nữ",
  3: "Chưa xác định",
}

export const displayStatusOptions: Options = {
  0: "Ẩn",
  1: "Hiện",
}

export const enableStatusOptions: Options = {
  0: "Không cho phép",
  1: "Cho phép",
}

export const colorStyleOptions: Options = {
  1: "Simple",
  2: "Gradient",
}

export const gradientStyleOptions: Options = {
  1: "Trái sang phải",
  2: "Phải sang trái",
}

export enum UPLOAD_LIST_TYPE {
  TEXT = "text",
  PICTURE = "picture",
  PICTURE_CARD = "picture-card",
  PICTURE_CIRCLE = "picture-circle",
}

export enum FILE_TYPE {
  AUDIO = "audio",
  IMAGE = "image",
  VIDEO = "video",
}

export const songGroupTypes: Options = {
  album: "Album",
  mixtape: "Mixtape",
  lp: "LP",
  ep: "EP",
  single: "Single",
}

export const songReleaseStatus: Options = {
  0: "Chưa phát hành",
  1: "Đã phát hành",
}

export const songReleaseColors: Options = {
  0: "var(--color-red-1)",
  1: "var(--color-green-1)",
}

export const songWarningOptions: Options = {
  0: "Không",
  1: "Có",
}

export enum ATTACHED_TICKET_TYPE_ENUMS {
  QR_TICKET = 1,
  PDF_TICKET = 2,
  UPLOAD_TICKET = 3,
}

export const attachedTicketTypes: Options = {
  1: "Mã QR (Auto generate)",
  2: "Vé PDF (Auto generate)",
  3: "Vé (Upload manually)",
}

export const eventPaymentOptions: Options = {
  0: "Không",
  1: "Có",
}

export const eventDrinkOptions: Options = {
  0: "Không",
  1: "Có",
}

export const eventFormUrlTypes: Options = {
  1: "Liên kết hệ thống",
  2: "Liên kết tự chọn",
}

export const sendEmailStatus: Options = {
  0: "Thất bại",
  1: "Thành công",
}

export const sendTicketStatus: Options = {
  0: "Không chọn",
  1: "Đã gửi",
  2: "Chưa gửi",
}

export enum SEND_TICKET_STATUS {
  NONE = 0,
  SENT = 1,
  UNSENT = 2,
}

export const confirmJoinEventStatus: Options = {
  0: "Không chọn",
  1: "Đã xác nhận",
  2: "Chưa xác nhận",
}

export enum CONFIRM_JOIN_EVENT_STATUS {
  NONE = 0,
  CONFIRMED = 1,
  UNCONFIRMED = 2,
}

export const sendEmailColors: Options = {
  0: "var(--color-red-1)",
  1: "var(--color-green-1)",
}

export const emailTypes: Options = {
  1: "Hệ thống",
  2: "Vé",
  3: "Thường",
}

export const attachTicketStatus: Options = {
  0: "Có",
  1: "Không",
}

export const activatedStatus: Options = {
  0: "Hủy kích hoạt",
  1: "Kích hoạt",
}

export const eventTicketTypes: Options = {
  1: "Fan",
  2: "Đội ngũ",
}

export enum EVENT_TICKET_STATUS {
  INITIALIZED = 1,
  PENDING_PAYMENT = 2,
  VERIFICATION_REQUEST = 3,
  REGISTRATION_SUCCESSFUL = 4,
  VERIFICATION_FAILED = 5,
  CANCELED = 6,
}

export const eventTicketStatus: Options = {
  1: "Khởi tạo",
  2: "Chờ thanh toán",
  3: "Yêu cầu xác minh",
  4: "Đăng ký thành công",
  5: "Xác minh thất bại",
  6: "Hủy",
}

export const eventTicketTab: Options = {
  3: "verify-request",
  4: "success",
}

export enum TICKET_PAYMENT_STATUS {
  VERIFICATION_REQUEST = 1,
  PAID = 2,
  VERIFICATION_FAILED = 3,
}

export const ticketPaymentStatus: Options = {
  1: "Yêu cầu xác minh",
  2: "Đã thanh toán",
  3: "Xác minh thất bại",
}

export const ticketPaymentColor: Options = {
  1: "var(--color-yellow-3)",
  2: "var(--color-green-1)",
  3: "var(--color-red-1)",
}

export enum EVENT_FORM_URL_TYPE_ENUMS {
  DEFAULT = 1,
  OPTIONAL = 2,
}

export enum EVENT_FORM_TYPE_ENUMS {
  TICKET_FAN = 1,
  TICKET_TEAM = 2,
  JOIN_SUPPORT = 3,
  JOIN_MEDIA = 4,
  JOIN_TALENT = 5,
  ATTENDANCE = 6,
}

export const eventFormTypeRoutes: Options = {
  1: APP_ROUTER.EVENT.TICKET_FAN,
  2: APP_ROUTER.EVENT.TICKET_TEAM,
  7: APP_ROUTER.EVENT.POSITION,
}

export enum ERROR_CODES {
  INVALID_ACCOUNT = "INVALID_ACCOUNT",
  NOT_ENOUGH_EMAILS = "NOT_ENOUGH_EMAILS",
  NO_RECIPIENT = "NO_RECIPIENT",
}

export enum EMAIL_TEMPLATE_TYPE {
  EVENT = "event",
  CATEGORY = "category",
  OTHER = "other",
}

export const templateEmailOptions = [
  {
    value: EMAIL_TEMPLATE_TYPE.EVENT,
    label: "Sự kiện",
    isLeaf: false,
  },
  {
    value: EMAIL_TEMPLATE_TYPE.CATEGORY,
    label: "Danh mục",
    isLeaf: false,
  },
  {
    value: EMAIL_TEMPLATE_TYPE.OTHER,
    label: "Khác",
    isLeaf: false,
  },
]

export enum VERIFY_PAYMENT_METHODS {
  TICKET_CODE = 1,
  OPTIONAL_DESCRIPTION = 2,
}

export const verifyPaymentMethods: Options = {
  1: "Nội dung chuyển khoản là mã đăng ký vé",
  2: "Nội dung chuyển khoản tuỳ chọn",
}

export const userVerifyColor: Options = {
  1: "var(--color-green-1)",
  2: "var(--color-red-1)",
}

export const userVerifyStatus: Options = {
  1: "Đã xác minh",
  2: "Chưa xác minh",
}

export const userStatus: Options = {
  1: "Đã xác minh",
  2: "Chưa xác minh",
}

export const userAccountTypes: Options = {
  0: "Tất cả",
  1: "Người dùng",
  2: "Quản lý",
  3: "Hủy kích hoạt",
}

export enum USER_ACCOUNT_TYPES {
  ALL = 0,
  USER = 1,
  ADMIN = 2,
  DEACTIVATED = 3,
}

export const importSourceModes: Options = {
  0: "Nhập từ tệp",
  1: "Auto generate",
}

export const votedMemberStatus: Options = {
  out: "Ra đi",
  stay: "Ở lại",
}

export const votedMemberColors: Options = {
  out: "var(--color-red-1)",
  stay: "var(--color-green-1)",
}

export enum VOTED_MEMBER_STATUS {
  OUT = "out",
  STAY = "stay",
}
