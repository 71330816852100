import { IResponsePagination } from "@/types/common"
import { IUpdateConfigRequest } from "@/types/request"
import { IGetAllConfigResponse } from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Config {
  getAll = (): Promise<IResponsePagination<IGetAllConfigResponse>> => {
    return http.get(ENDPOINT.CONFIG.GET_ALL)
  }

  update = (body: IUpdateConfigRequest) => {
    return http.put(ENDPOINT.CONFIG.UPDATE, body)
  }
}

export const ConfigService = new Config()
