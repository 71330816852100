import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreatePartnerRequest,
  IGetPartnerRequest,
  IUpdatePartnerRequest,
} from "@/types/request/partner"
import {
  ICreatePartnerResponse,
  IGetPartnerResponse,
  IUpdatePartnerResponse,
} from "@/types/response/partner"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Partner {
  getList = (
    params: IGetPartnerRequest,
  ): Promise<IResponsePagination<IGetPartnerResponse[]>> => {
    return http.get(ENDPOINT.PARTNER.GET_LIST, { params: params })
  }

  create = (
    body: ICreatePartnerRequest,
  ): Promise<IResponse<ICreatePartnerResponse>> => {
    return http.post(ENDPOINT.PARTNER.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdatePartnerRequest,
  ): Promise<IResponse<IUpdatePartnerResponse>> => {
    return http.put(ENDPOINT.PARTNER.UPDATE.replace(":id", String(id)), body)
  }

  updateIsDisplayed = (
    id: number,
    body: {
      is_displayed: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.PARTNER.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
      body,
    )
  }

  updatePriority = (
    id: number,
    body: {
      priority: number
    },
  ) => {
    return http.put(
      ENDPOINT.PARTNER.UPDATE_PRIORITY.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.PARTNER.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.PARTNER.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const PartnerService = new Partner()
