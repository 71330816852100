import {
  Config,
  EmailAccount,
  EmailCategory,
  EmailLog,
  EmailTemplate,
  Event,
  EventPosition,
  EventType,
  FilterBoxchat,
  Home,
  Login,
  Partner,
  Permission,
  PermissionGroup,
  Role,
  Slider,
  SliderType,
  Song,
  SongGroup,
  TicketFan,
  User,
} from "@/pages"
import { Navigate, Outlet, useLocation } from "react-router-dom"

export interface IRouteWrapProps {
  isAuthenticated: boolean
}

export const APP_ROUTER = {
  AUTH: {
    LOGIN: "/login",
  },
  HOME: "/",
  PARTNER: "/partner",
  SLIDER: {
    LIST: "/slider/list",
    TYPE: "/slider/type",
  },
  SONG: {
    LIST: "/song/list",
    GROUP: "/song/group",
  },
  EVENT: {
    ROOT: "/event",
    LIST: "/event/list",
    TYPE: "/event/type",
    EVENT_TAB: "/event/:event_id/:tab_id",
    TICKET_FAN: "/event/:event_id/ticket-fan",
    TICKET_TEAM: "/event/:event_id/ticket-team",
    POSITION: "/event/:event_id/position",
  },
  EMAIL: {
    ROOT: "/email",
    ACCOUNT: "/email/account",
    LOG: "/email/log",
    TEMPLATE: "/email/template",
    CATEGORY: "/email/category",
  },
  TOOL: {
    ROOT: "/tool",
    FILTER_BOXCHAT: "/filter-boxchat",
  },
  CONFIG: "/config",
  USER: {
    ROOT: "/user",
    LIST: "/user/list",
    ROLE: "/user/role",
    PERMISSION: "/user/permission",
    PERMISSION_GROUP: "/user/permission-group",
  },
}

export const publicRoutes = [
  {
    path: APP_ROUTER.AUTH.LOGIN,
    element: <Login title="Đăng nhập" />,
  },
]

export const privateRoutes = [
  {
    path: APP_ROUTER.HOME,
    element: <Home title="Trang chủ" />,
  },
  {
    path: APP_ROUTER.CONFIG,
    element: <Config title="Cấu hình" />,
  },
  {
    path: APP_ROUTER.EVENT.LIST,
    element: <Event title="Sự kiện" />,
  },
  {
    path: APP_ROUTER.EVENT.TYPE,
    element: <EventType title="Loại sự kiện" />,
  },
  {
    path: APP_ROUTER.EVENT.POSITION,
    element: <EventPosition title="Hạng vé" />,
  },
  {
    path: APP_ROUTER.EVENT.TICKET_FAN,
    element: <TicketFan title="Vé (Fan)" />,
  },
  {
    path: APP_ROUTER.PARTNER,
    element: <Partner title="Đối tác" />,
  },
  {
    path: APP_ROUTER.SLIDER.LIST,
    element: <Slider title="Danh sách Slider" />,
  },
  {
    path: APP_ROUTER.SLIDER.TYPE,
    element: <SliderType title="Loại Slider" />,
  },
  {
    path: APP_ROUTER.SONG.LIST,
    element: <Song title="Danh sách bài hát" />,
  },
  {
    path: APP_ROUTER.SONG.GROUP,
    element: <SongGroup title="Nhóm bài hát" />,
  },
  {
    path: APP_ROUTER.EMAIL.ACCOUNT,
    element: <EmailAccount title="Tài khoản Email" />,
  },
  {
    path: APP_ROUTER.EMAIL.LOG,
    element: <EmailLog title="Lịch sử gửi Email" />,
  },
  {
    path: APP_ROUTER.EMAIL.TEMPLATE,
    element: <EmailTemplate title="Mẫu Email" />,
  },
  {
    path: APP_ROUTER.EMAIL.CATEGORY,
    element: <EmailCategory title="Danh mục Email" />,
  },
  {
    path: APP_ROUTER.USER.LIST,
    element: <User title="Danh sách người dùng" />,
  },
  {
    path: APP_ROUTER.USER.ROLE,
    element: <Role title="Nhóm quyền" />,
  },
  {
    path: APP_ROUTER.USER.PERMISSION,
    element: <Permission title="Quyền" />,
  },
  {
    path: APP_ROUTER.USER.PERMISSION_GROUP,
    element: <PermissionGroup title="Nhóm quyền" />,
  },
  {
    path: APP_ROUTER.TOOL.FILTER_BOXCHAT,
    element: <FilterBoxchat title="Lọc Boxchat" />,
  },
]

export const PublicRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation()

  return isAuthenticated ? (
    <Navigate to={APP_ROUTER.HOME} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export const PrivateRouteWrap: React.FC<IRouteWrapProps> = ({
  isAuthenticated,
}) => {
  const location = useLocation()

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={APP_ROUTER.AUTH.LOGIN} state={{ from: location }} replace />
  )
}
