import { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import "./style.scss"

interface IProps {
  id: number | string | undefined
  modalName: string
  content: string | React.ReactNode
}

export const ConfirmDeleteOne: React.FC<IProps> = ({
  id,
  modalName,
  content,
}) => {
  const { reset } = useFormContext()

  useEffect(() => {
    if (id) {
      reset({
        id,
        modal_name: modalName,
      })
    }
  }, [id])

  return <div className="c-confirm-delete-one">{content}</div>
}
