import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    first_name:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup.string().required("Vui lòng nhập Họ"),
    last_name:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup.string().required("Vui lòng nhập Tên"),
    email:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup
        .string()
        .required("Vui lòng nhập Email!")
        .matches(REGEX.EMAIL, "Vui lòng nhập đúng định dạng Email!"),
    phone:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup
        .string()
        .required("Vui lòng nhập số điện thoại!")
        .matches(REGEX.PHONE, "Vui lòng nhập đúng định dạng Số điện thoại!"),
    birthday:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup.string().required("Vui lòng nhập Ngày sinh"),
    gender:
      (modalName === MODAL.CREATE_USER || modalName === MODAL.UPDATE_USER) &&
      yup.number().required("Vui lòng nhập Giới tính"),
  } as any)
