import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "ckeditor5-build-full"
import { useRef } from "react"

interface IProps {
  onChange?: (data: string) => void
  defaultValue?: string
  height?: string
}

interface Loader {
  file: Promise<File>
}
export const CKEditorWrap: React.FC<IProps> = ({
  onChange,
  defaultValue,
  height = "220px",
}) => {
  const API_URL = "https://api.cloudinary.com"
  const UPLOAD_ENDPOINT = "v1_1/denvaufc/image/upload"

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const uploadAdapter = (loader: Loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file: any) => {
            body.append("file", file)
            body.append("upload_preset", "ylznkk8c")
            body.append("folder", "news/medias")
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: res.secure_url,
                })
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any,
    ) => {
      return uploadAdapter(loader)
    }
  }

  const handleChange = (e: any, editor: any) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      onChange && onChange(editor.getData())
    }, 300)
  }

  return (
    <CKEditor
      data={defaultValue}
      config={{
        extraPlugins: [uploadPlugin],
      }}
      onReady={(editor) => {
        editor?.editing?.view?.change((writer: any) => {
          writer.setStyle(
            "height",
            height,
            editor?.editing?.view?.document?.getRoot() as any,
          )
        })
      }}
      editor={ClassicEditor}
      onChange={handleChange}
    />
  )
}
