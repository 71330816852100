import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    title:
      (modalName === MODAL.CREATE_SLIDER ||
        modalName === MODAL.UPDATE_SLIDER) &&
      yup.string().required("Vui lòng nhập Tiêu đề"),
    priority:
      (modalName === MODAL.CREATE_SLIDER ||
        modalName === MODAL.UPDATE_SLIDER) &&
      yup.number().required("Vui lòng nhập Thứ tự hiển thị"),
    type_id:
      (modalName === MODAL.CREATE_SLIDER ||
        modalName === MODAL.UPDATE_SLIDER) &&
      yup.number().required("Vui lòng chọn Loại"),
    link_to:
      (modalName === MODAL.CREATE_SLIDER ||
        modalName === MODAL.UPDATE_SLIDER) &&
      yup.string().matches(REGEX.URL, "Vui lòng nhập đúng định dạng liên kết!"),
  } as any)
