import {
  BreadcrumbWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { APP_ROUTER } from "@/routes"
import { convertObjectToArray, convertToStringObject, t } from "@/utils/helpers"
import { Col, Form, Row } from "antd"
import { useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import "./style.scss"
import { votedMemberStatus } from "@/utils/constants"

interface IHeaderProps {
  params: any
}

const Header: React.FC<IHeaderProps> = ({ params }) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { control, reset, setValue } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.TOOL.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.TOOL.FILTER_BOXCHAT"),
      href: APP_ROUTER.TOOL.FILTER_BOXCHAT,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        name: searchParams.get("name") || undefined,
        status: searchParams.get("status") || undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        name: formMonitor?.name || "",
        status: formMonitor?.status || "",
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-event-type-header no-select">
      <div className="title">Lọc Boxchat</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
      </div>
      <Form labelCol={{ span: 4, md: 8 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label="Tên thành viên" colon>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder="Nhập tên thành viên"
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label="Trạng thái" colon>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("status", "")}
                        placeholder={t(
                          "P_EMAIL_ACCOUNT.FILTER.PLACEHOLDER_IS_ENABLED",
                        )}
                        options={convertObjectToArray(
                          votedMemberStatus,
                          "string",
                        )}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
