import { InboxOutlined } from "@ant-design/icons"
import { Upload } from "antd"
import { UploadProps } from "antd/lib"
import { useEffect, useState } from "react"

const { Dragger } = Upload

export const UploadDraggerWrap = ({
  onChangeFiles,
  uploadedFiles,
  listType,
  accept,
  maxCount,
  className,
  description,
  ...props
}: any) => {
  const [fileList, setFileList] = useState<any>([])

  const handleChange: UploadProps["onChange"] = ({ file, fileList }) => {
    onChangeFiles(file, fileList)
    setFileList(fileList)
  }

  useEffect(() => {
    setFileList(uploadedFiles)
  }, [uploadedFiles])

  return (
    <Dragger
      name="file"
      maxCount={maxCount}
      listType={listType}
      fileList={fileList}
      accept={accept}
      beforeUpload={() => false}
      onChange={handleChange}
      {...props}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Nhấn hoặc thả tệp ở đây để tải lên</p>
      <p className="ant-upload-hint">{description}</p>
    </Dragger>
  )
}
