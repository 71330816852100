import {
  DatePickerWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { IUser } from "@/types/response"
import { IProvince } from "@/types/response/province"
import { IRole } from "@/types/response/role"
import { UPLOAD_LIST_TYPE, genderOptions } from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  getFileUploadedObject,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Col, Form, Row, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"
import "./style.scss"
import moment from "moment"

interface IProps {
  setIsLoadingUpload: (value: boolean) => void
  provinceList: IProvince[]
  roleList: IRole[]
  data: IUser
}

export const UpdateUser: React.FC<IProps> = ({
  setIsLoadingUpload,
  provinceList,
  roleList,
  data,
}) => {
  const [uploadedAvatars, setUploadedAvatars] = useState<IUploadedFile[]>([])
  console.log("data", data)

  const {
    control,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext()

  const { mutate: uploadAvatar, isLoading: isLoadingUploadAvatar } =
    useMutation((body: IUploadFileRequest) => FileService.uploadImage(body), {
      onSuccess: (response) => {
        setUploadedAvatars((prevUploadAvatars) =>
          prevUploadAvatars.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedAvatars((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    })

  const handleChangeAvatar = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedAvatars((prevUploadAvatars) => [
        ...prevUploadAvatars,
        tempFile,
      ])

      uploadAvatar({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedAvatars((prevUploadAvatars) =>
        prevUploadAvatars.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_USER,
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      avatar_path: data?.avatar_path,
      birthday: data?.birthday && moment(data?.birthday),
      gender: data.gender,
      hometown: data?.hometown,
      current_city: data?.current_city,
      is_activated: data.is_activated,
      roles: data?.roles?.map((role) => role.name),
    })

    data?.avatar_path &&
      setUploadedAvatars([getFileUploadedObject(data?.avatar_path)])
  }, [data])

  useEffect(() => {
    const isLoading = isLoadingUploadAvatar
    setIsLoadingUpload(isLoading)
  }, [isLoadingUploadAvatar])

  useEffect(() => {
    setValue("avatar_path", uploadedAvatars[0]?.url)
  }, [uploadedAvatars])

  useEffect(() => {
    setValue("modal_name", MODAL.CREATE_USER)
  }, [])

  return (
    <div className="c-update-user">
      <Form layout="vertical">
        <Row gutter={10}>
          <Col span={24} sm={12} xl={12} xxl={12}>
            <FormItemWrap
              label="Họ"
              className="field-name"
              required
              validateStatus={errors?.first_name ? "error" : ""}
              help={<>{errors?.first_name && errors?.first_name?.message}</>}
            >
              <Controller
                name="first_name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <InputWrap placeholder="Nhập Họ" field={field} allowClear />
                )}
              />
            </FormItemWrap>
          </Col>
          <Col span={24} sm={12} xl={12} xxl={12}>
            <FormItemWrap
              label="Tên"
              className="field-name"
              required
              validateStatus={errors?.last_name ? "error" : ""}
              help={<>{errors?.last_name && errors?.last_name?.message}</>}
            >
              <Controller
                name="last_name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <InputWrap placeholder="Nhập Tên" field={field} allowClear />
                )}
              />
            </FormItemWrap>
          </Col>
        </Row>
        <FormItemWrap label="Ảnh đại diện">
          <UploadWrap
            listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
            onChangeFiles={handleChangeAvatar}
            onRemoveFiles={handleRemoveFile}
            uploadedFiles={uploadedAvatars}
            setUploadedAvatars={setUploadedAvatars}
            maxCount={1}
          />
        </FormItemWrap>
        <FormItemWrap label="Email" required>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap field={field} allowClear readOnly />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Số điện thoại"
          required
          validateStatus={errors?.phone ? "error" : ""}
          help={<>{errors?.phone && errors?.phone?.message}</>}
        >
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <InputWrap
                placeholder="Ví dụ: 0396687531"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Ngày sinh"
          required
          validateStatus={errors?.birthday ? "error" : ""}
          help={<>{errors?.birthday && errors?.birthday?.message}</>}
        >
          <Controller
            name="birthday"
            control={control}
            render={({ field }) => (
              <DatePickerWrap
                className="c-birthday"
                placeholder="Ví dụ: 13/05/1989"
                format="DD/MM/YYYY"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Giới tính"
          required
          validateStatus={errors?.gender ? "error" : ""}
          help={<>{errors?.gender && errors?.gender?.message}</>}
        >
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <SelectWrap
                placeholder="Chọn giới tính"
                options={convertObjectToArray(genderOptions)}
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Quê quán" colon>
          <Controller
            name="hometown"
            control={control}
            render={({ field }) => (
              <SelectWrap
                allowClear
                placeholder="Chọn Quê quán"
                options={convertArrayToOptions(
                  provinceList!,
                  "name_with_type",
                  "name_with_type",
                  "string",
                )}
                field={field}
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Thành phố hiện tại" colon>
          <Controller
            name="current_city"
            control={control}
            render={({ field }) => (
              <SelectWrap
                allowClear
                placeholder="Chọn Thành phố hiện tại"
                options={convertArrayToOptions(
                  provinceList!,
                  "name_with_type",
                  "name_with_type",
                  "string",
                )}
                field={field}
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Vai trò">
          <Controller
            name="roles"
            control={control}
            render={({ field }) => (
              <SelectWrap
                placeholder="Chọn vai trò"
                options={convertArrayToOptions(
                  roleList,
                  "name",
                  "display_name",
                  "string",
                )}
                field={field}
                mode="multiple"
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Kích hoạt" required>
          <Controller
            name="is_activated"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <SwitchWrap
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                field={field}
              />
            )}
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
