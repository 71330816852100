import { FormItemWrap, SelectWrap } from "@/components/commons"
import { ENDPOINT } from "@/services/endpoint"
import { IEmailCondition, IResponse, Options } from "@/types/common"
import { IGetDetailEventResponse } from "@/types/response/event"
import {
  confirmJoinEventStatus,
  eventTicketStatus,
  sendTicketStatus,
} from "@/utils/constants"
import { convertObjectToArray } from "@/utils/helpers"
import { Form } from "antd"
import _ from "lodash"
import React, { useEffect } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import "./style.scss"
import { IGetAllEventPositionResponse } from "@/types/response/eventPosition"

interface IProps {
  emailCondition: IEmailCondition
  positionList: Options
  handleChangeCondition: (form: IEmailCondition) => void
}

export const ChangeCondition: React.FC<IProps> = ({
  emailCondition,
  handleChangeCondition,
  positionList,
}) => {
  const { event_id: eventId } = useParams()
  const queryClient = useQueryClient()
  const methods = useForm({
    mode: "onChange",
  })

  const {
    reset,
    control,
    formState: { errors },
  } = methods

  const formWatcher = useWatch({
    control,
  })

  const eventDetail: IResponse<IGetDetailEventResponse> | undefined =
    queryClient.getQueryData([ENDPOINT.EVENT.GET_DETAIL, eventId])

  const itemPicked = ["4", "6"]
  const itemHavePaymentPicked = ["4", "3", "5", "2", "6"]

  const ticketStatus = _.pick(
    eventTicketStatus,
    eventDetail?.data?.have_payment ? itemHavePaymentPicked : itemPicked,
  )

  const renderHelpMessage = (field: keyof typeof messages) => {
    const messages: {
      [key: string]: object
    } = {
      ticket_status: {
        2: "Email sẽ được gửi cho lượt đăng ký đang Chờ thanh toán",
        3: "Email sẽ được gửi cho lượt đăng ký đang Yêu cầu xác minh",
        4: "Email sẽ được gửi cho lượt đăng ký đã Đăng ký thành công ",
        5: "Email sẽ được gửi cho lượt đăng ký Xác minh thất bại",
        6: "Email sẽ được gửi cho lượt đăng ký bị Hủy",
      },
      send_ticket_status: {
        0: "Email sẽ được gửi cho dù trước đó lượt đăng ký đã được gửi vé hay chưa",
        1: "Email sẽ được gửi nếu lượt đăng ký đã được gửi vé",
        2: "Email sẽ được gửi nếu lượt đăng ký đó chưa được gửi vé",
      },
      confirm_status: {
        0: "Email sẽ được gửi cho dù lượt đăng ký đã xác nhận tham gia hay chưa",
        1: "Email sẽ được gửi cho lượt đăng ký đã xác nhận tham gia sự kiện",
        2: "Email sẽ được gửi cho lượt đăng ký chưa xác nhận tham gia sự kiện",
      },
    }

    const value = formWatcher[field]
    return (messages as any)[field]?.[value] || ""
  }

  useEffect(() => {
    const condition: IEmailCondition = {
      ticket_status: formWatcher?.ticket_status,
      send_ticket_status: formWatcher?.send_ticket_status,
      confirm_status: formWatcher?.confirm_status,
      position_id: formWatcher?.position_id,
    }
    handleChangeCondition(condition)
  }, [formWatcher])

  useEffect(() => {
    reset({
      ticket_status: emailCondition?.ticket_status,
      send_ticket_status: emailCondition?.send_ticket_status,
      confirm_status: emailCondition?.confirm_status,
      position_id: emailCondition?.position_id,
    })
  }, [])

  return (
    <Form layout="vertical" className="c-change-condition">
      <FormItemWrap
        label="Hạng vé"
        required
        validateStatus={errors?.position_id ? "error" : ""}
        help={<>{errors?.position_id && errors?.position_id?.message}</>}
      >
        <Controller
          name="position_id"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn hạng vé"
              options={convertObjectToArray(positionList!)}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Trạng thái vé"
        required
        validateStatus={errors?.ticket_status ? "error" : ""}
        help={<>{renderHelpMessage("ticket_status")}</>}
      >
        <Controller
          name="ticket_status"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn trạng thái"
              options={convertObjectToArray(ticketStatus)}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Gửi vé"
        required
        validateStatus={errors?.send_ticket_status ? "error" : ""}
        help={<>{renderHelpMessage("send_ticket_status")}</>}
      >
        <Controller
          name="send_ticket_status"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn trạng thái"
              options={convertObjectToArray(sendTicketStatus)}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      {!eventDetail?.data?.have_payment && (
        <FormItemWrap
          label="Xác nhận tham gia"
          required
          validateStatus={errors?.confirm_status ? "error" : ""}
          help={<>{renderHelpMessage("confirm_status")}</>}
        >
          <Controller
            name="confirm_status"
            control={control}
            render={({ field }) => (
              <SelectWrap
                placeholder="Chọn trạng thái"
                options={convertObjectToArray(confirmJoinEventStatus)}
                field={field}
              />
            )}
          />
        </FormItemWrap>
      )}
    </Form>
  )
}
