import { ISvgIcon } from "@/types/common"

export const ClockIcon: React.FC<ISvgIcon> = ({
  width = 20,
  height = 20,
  fillColor = "var(--color-neutral-8)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fillColor}
        d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m4.2 14.2L11 13V7h1.5v5.2l4.5 2.7z"
      />
    </svg>
  )
}
