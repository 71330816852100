import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateSongRequest,
  IGetListSongRequest,
  IUpdateSongRequest,
} from "@/types/request"
import {
  ICreateSongResponse,
  IGetListSongResponse,
  IUpdateSongResponse,
} from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"
import { Key } from "react"

class Song {
  getList = (
    params: IGetListSongRequest,
  ): Promise<IResponsePagination<IGetListSongResponse>> => {
    return http.get(ENDPOINT.SONG.GET_LIST, { params: params })
  }

  create = (
    body: ICreateSongRequest,
  ): Promise<IResponse<ICreateSongResponse>> => {
    return http.post(ENDPOINT.SONG.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateSongRequest,
  ): Promise<IResponse<IUpdateSongResponse>> => {
    return http.put(ENDPOINT.SONG.UPDATE.replace(":id", String(id)), body)
  }

  updateIsDisplayed = (
    id: number,
    body: {
      is_displayed: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.SONG.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
      body,
    )
  }

  updateIsWarning = (
    id: number,
    body: {
      is_warning: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.SONG.UPDATE_IS_WARNING.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(ENDPOINT.SONG.SOFT_DELETE_ONE.replace(":id", String(id)))
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.SONG.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const SongService = new Song()
