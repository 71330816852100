import { DatePicker } from "antd"
import { DatePickerProps } from "antd/lib"
import { ControllerRenderProps } from "react-hook-form"

interface IProps extends Omit<DatePickerProps, "value" | "onChange"> {
  field?: ControllerRenderProps<any, any>
}

export const DatePickerWrap: React.FC<IProps> = ({
  className,
  field,
  ...props
}) => {
  return <DatePicker className={className} {...field} {...props} />
}
