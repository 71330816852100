import { Input } from "antd"
import { PasswordProps } from "antd/lib/input"
import { ControllerRenderProps } from "react-hook-form"

interface IInputPasswordWrapProps extends PasswordProps {
  field?: ControllerRenderProps<any, any>
}

export const InputPasswordWrap: React.FC<IInputPasswordWrapProps> = ({
  className,
  field,
  ...props
}) => {
  return <Input.Password className={className} {...field} {...props} />
}
