import {
  InputCellWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ENDPOINT } from "@/services/endpoint"
import { PartnerService } from "@/services/partner"
import { IModalState } from "@/types/common"
import { ICreatePartnerRequest, IUpdatePartnerRequest } from "@/types/request"
import { IGetPartnerResponse } from "@/types/response/partner"
import { IMAGE_TYPE } from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  getImage,
  notificationMessage,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateParter, UpdatePartner, ViewPartnerDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IPartnerProps {
  title?: string
}

export const Partner: React.FC<IPartnerProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name"),
    is_displayed: searchParams.get("is_displayed") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
      is_displayed: true,
    },
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetPartnerResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreatePartner = (values: any) => {
    const body: ICreatePartnerRequest = {
      name: values.name,
      description: values?.description,
      logo_path: values?.logo_path,
      is_displayed: values.is_displayed,
      priority: values.priority,
    }

    createPartner(body)
  }

  const handleUpdatePartner = (values: any) => {
    const body: IUpdatePartnerRequest = {
      name: values.name,
      description: values?.description,
      logo_path: values?.logo_path,
      is_displayed: values.is_displayed,
      priority: values.priority,
    }

    updatePartner({ id: values.id, body })
  }

  console.log("error", errors)

  const handleDeleteOnePartner = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyPartner = () => {
    console.log("xx", selectedItems)

    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IGetPartnerResponse) => {
    switch (type) {
      case MODAL.CREATE_PARTNER:
        setModal({
          open: true,
          title: "Thêm đối tác",
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreatePartner),
          onCancel: hanldeCancelModal,
          children: <CreateParter setIsLoadingUpload={setIsLoadingUpload} />,
        })
        break
      case MODAL.UPDATE_PARTNER:
        setModal({
          open: true,
          title: "Cập nhật đối tác",
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdatePartner),
          onCancel: hanldeCancelModal,
          children: (
            <UpdatePartner
              setIsLoadingUpload={setIsLoadingUpload}
              data={record!}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: "Chuyển vào thùng rác 1 bản ghi",
          width: 486,
          okText: "Chấp nhận",
          onOk: handleSubmit(handleDeleteOnePartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content="Xoá 1"
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: "Chuyển vào thùng rác nhiều bản ghi",
          width: 486,
          okText: "Chấp nhận",
          onOk: handleSubmit(handleDeleteManyPartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content="Xoá 1"
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: "Chi tiết",
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewPartnerDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: partners,
    refetch: refetchPartners,
    isFetching,
  } = useQuery([ENDPOINT.PARTNER.GET_LIST, params], () =>
    PartnerService.getList(convertRequest(params)),
  )

  const { mutate: createPartner, isLoading: isLoadingCreatePartner } =
    useMutation((body: ICreatePartnerRequest) => PartnerService.create(body), {
      onSuccess: () => {
        refetchPartners()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({})
      },
      onError: (error: any, body) => {},
    })

  const { mutate: updatePartner, isLoading: isLoadingUpdatePartner } =
    useMutation(
      (payload: { id: number; body: IUpdatePartnerRequest }) =>
        PartnerService.update(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchPartners()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Cập nhật thành công",
            type: "success",
          })
          setSelectedItems([])
          reset({})
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateIsDislayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      PartnerService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPartners()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePriority } = useMutation(
    (payload: { id: number; body: { priority: number } }) =>
      PartnerService.updatePriority(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchPartners()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => PartnerService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchPartners()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => PartnerService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchPartners()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IGetPartnerResponse> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      width: "320px",
      render: (record: IGetPartnerResponse) => (
        <div className="column-name">
          <div className="avatar">
            <img
              src={getImage(record?.logo_path, IMAGE_TYPE.LOGO)}
              alt={record.name}
            />
          </div>
          <span className="name">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Thứ tự hiển thị",
      dataIndex: "priority",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <InputCellWrap
          defaultValue={value}
          onChange={(e) =>
            updatePriority({
              id: record?.id,
              body: {
                priority: Number(e),
              },
            })
          }
        />
      ),
    },
    {
      title: "Trạng thái hiển thị",
      dataIndex: "is_displayed",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsDislayed({
              id: record?.id,
              body: {
                is_displayed: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IGetPartnerResponse) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-partner">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={partners?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetching}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={partners?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreatePartner || isLoadingUpdatePartner}
        disabled={isLoadingUpload}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
