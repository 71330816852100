import { IResponse } from "@/types/common"
import { IGetAllArtistResponse } from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Artist {
  getAll = (): Promise<IResponse<IGetAllArtistResponse>> => {
    return http.get(ENDPOINT.ARTIST.GET_ALL)
  }

  // getList = (
  //   params: IGetListSongRequest,
  // ): Promise<IResponsePagination<IGetListSongResponse>> => {
  //   return http.get(ENDPOINT.SONG_GROUP.GET_LIST, { params: params })
  // }

  // create = (
  //   body: ICreateSliderRequest,
  // ): Promise<IResponse<ICreateSliderResponse>> => {
  //   return http.post(ENDPOINT.SLIDER.CREATE, body)
  // }

  // update = (
  //   id: number,
  //   body: IUpdateSliderRequest,
  // ): Promise<IResponse<IUpdateSliderResponse>> => {
  //   return http.put(ENDPOINT.SLIDER.UPDATE.replace(":id", String(id)), body)
  // }

  // updateIsDisplayed = (
  //   id: number,
  //   body: {
  //     is_displayed: boolean
  //   },
  // ) => {
  //   return http.put(
  //     ENDPOINT.SONG_GROUP.UPDATE_IS_DISPLAYED.replace(":id", String(id)),
  //     body,
  //   )
  // }

  // softDeleteOne = (id: number) => {
  //   return http.delete(
  //     ENDPOINT.SONG_GROUP.SOFT_DELETE_ONE.replace(":id", String(id)),
  //   )
  // }

  // softDeleteMany = (ids: Key[]) => {
  //   return http.delete(ENDPOINT.SONG_GROUP.SOFT_DELETE_MANY, {
  //     data: {
  //       ids,
  //     },
  //   })
  // }
}

export const ArtistService = new Artist()
