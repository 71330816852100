import { FormItemWrap, InputWrap } from "@/components/commons"
import { IEmailCategory } from "@/types/response"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: IEmailCategory
}

export const UpdateEmailCategory: React.FC<IProps> = ({ data }) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_EMAIL_CATEGORY,
      id: data.id,
      name: data.name,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên danh mục"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên danh mục"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
