import {
  ModalWrap,
  PaginationWrap,
  TableEditorWrap,
  TableWrap,
} from "@/components/commons"
import { MentionIcon, UserIcon } from "@/components/icons"
import { EmailAccountService } from "@/services/emailAccount"
import { EmailLogService } from "@/services/emailLog"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import {
  ICreateEmailAccountRequest,
  IUpdateEmailAccountRequest,
} from "@/types/request"
import { IEmailAccount } from "@/types/response"
import { emailTypes, sendEmailColors, sendEmailStatus } from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  t,
} from "@/utils/helpers"
import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { ViewSongDetail } from "./Modal"
import { MODAL } from "./constants"
import "./style.scss"

interface IProps {
  title?: string
}

export const EmailLog: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    subject: searchParams.get("subject") || "",
    sender_name: searchParams.get("sender_name") || "",
    sender_email: searchParams.get("sender_email") || "",
    receiver_name: searchParams.get("receiver_name") || "",
    receiver_email: searchParams.get("receiver_email") || "",
    type: searchParams.get("type") || "",
    status: searchParams.get("status") || "",
  }

  const rowSelection: TableRowSelection<IEmailAccount> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IEmailAccount) => {
    switch (type) {
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_EMAIL_LOG.TITLE_DETAIL_EMAIL_LOG"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSongDetail record={record!} />,
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: emailLogList,
    refetch: refetchEmailLogList,
    isFetching: isFetchingEmailLogList,
  } = useQuery([ENDPOINT.EMAIL_LOGS.GET_LIST, params], () =>
    EmailLogService.getList(convertRequest(params)),
  )

  const { mutate: createEmailAccount, isLoading: isLoadingCreateEmailAccount } =
    useMutation(
      (body: ICreateEmailAccountRequest) => EmailAccountService.create(body),
      {
        onSuccess: () => {
          refetchEmailLogList()
          handleModalContent(MODAL.NOT_SHOW)
          setSearchParams({
            ...convertToStringObject(params),
            current_page: String(1),
          })
          notificationMessage({
            message: "Thêm thành công",
            type: "success",
          })
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateEmailAccount, isLoading: isLoadingUpdateEmailAccount } =
    useMutation(
      (payload: { id: number; body: IUpdateEmailAccountRequest }) =>
        EmailAccountService.update(payload.id, payload.body),
      {
        onSuccess: () => {
          refetchEmailLogList()
          handleModalContent(MODAL.NOT_SHOW)
          notificationMessage({
            message: "Cập nhật thành công",
            type: "success",
          })
          setSelectedItems([])
        },
        onError: (error: any, body) => {},
      },
    )

  const { mutate: updateIsEnabled } = useMutation(
    (payload: { id: number; body: { is_enabled: boolean } }) =>
      EmailAccountService.updateIsEnabled(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchEmailLogList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => EmailAccountService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchEmailLogList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => EmailAccountService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchEmailLogList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IEmailAccount> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tiêu đề",
      dataIndex: "subject",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Tài khoản gửi",
      width: "240px",
      render: (record) => {
        return (
          <div className="column-sender">
            <div className="name">
              <UserIcon />
              {record?.sender_name}
            </div>
            <div className="username">
              <MentionIcon />
              {record?.sender_email}
            </div>
          </div>
        )
      },
    },
    {
      title: "Tài khoản nhận",
      width: "240px",
      render: (record) => {
        return (
          <div className="column-receiver">
            <div className="name">
              <UserIcon />
              {record?.receiver_name}
            </div>
            <div className="username">
              <MentionIcon />
              {record?.receiver_email}
            </div>
          </div>
        )
      },
    },
    {
      title: "Phân loại",
      dataIndex: "type",
      width: "120px",
      render: (value) => emailTypes[value],
    },
    {
      title: "Trạng thái gửi",
      dataIndex: "status",
      width: "120px",
      render: (value) => (
        <Tag color={sendEmailColors[value]}>{sendEmailStatus[value]}</Tag>
      ),
    },
    {
      title: "Người thực hiện",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: IEmailAccount) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-email-log">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header params={params} />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={emailLogList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingEmailLogList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={emailLogList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateEmailAccount || isLoadingUpdateEmailAccount}
        {...modal}
      >
        {modal.children}
      </ModalWrap>
    </div>
  )
}
