import { IResponse } from "@/types/common"
import {
  IGetListDistrictsResponse,
  IGetListProvincesResponse,
  IGetListWardsResponse,
} from "@/types/response/province"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Province {
  getProvinces = (): Promise<IResponse<IGetListProvincesResponse>> => {
    return http.get(ENDPOINT.PROVINCE.GET_PROVINCES)
  }

  getDistricts = ({
    province_id,
  }: {
    province_id?: string
  }): Promise<IResponse<IGetListDistrictsResponse>> => {
    return http.get(
      ENDPOINT.PROVINCE.GET_DISTRICTS.replace(
        ":province_id",
        String(province_id),
      ),
    )
  }

  getWards = ({
    district_id,
  }: {
    district_id?: string
  }): Promise<IResponse<IGetListWardsResponse>> => {
    return http.get(
      ENDPOINT.PROVINCE.GET_WARDS.replace(":district_id", String(district_id)),
    )
  }
}

export const ProvinceService = new Province()
