import {
  ButtonWrap,
  FormItemWrap,
  InputNumberWrap,
  InputPasswordWrap,
  InputWrap,
  SelectWrap,
  SpinWrap,
} from "@/components/commons"
import { ConfigService, EmailAccountService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IResponse } from "@/types/common"
import { IUpdateConfigRequest } from "@/types/request"
import { IGetAllConfigResponse } from "@/types/response"
import { convertArrayToOptions, notificationMessage } from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Card, Form } from "antd"
import { Helmet } from "react-helmet-async"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import Header from "./Header"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"
import { SELECT_TYPE } from "@/utils/constants"

interface IProps {
  title?: string
}

export const Config: React.FC<IProps> = ({ title }) => {
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors, isDirty },
  } = methods

  const resetFormWithValue = (response?: IResponse<IGetAllConfigResponse>) => {
    reset({
      ...getValues(),
      bank_username: response?.data?.bank_username,
      bank_password: response?.data?.bank_password,
      bank_account_no: response?.data?.bank_account_no,
      bank_device_id: response?.data?.bank_device_id,
      limit_email_daily: response?.data?.limit_email_daily,
      send_email_delay: response?.data?.send_email_delay,
      system_email_id: Number(response?.data?.system_email_id) ?? null,
      email_notified: response?.data?.email_notified,
      ticket_registration_time: response?.data?.ticket_registration_time,
      ticket_payment_time: response?.data?.ticket_payment_time,
    })
  }

  const {
    data: configs,
    refetch: refetchConfigs,
    isFetching: isFetchingConfigs,
  } = useQuery([ENDPOINT.CONFIG.GET_ALL], () => ConfigService.getAll(), {
    onSuccess: (response: IResponse<IGetAllConfigResponse>) => {
      resetFormWithValue(response)
    },
  })

  const {
    data: accountList,
    refetch: refetchAccountList,
    isFetching: isFetchingAccountList,
  } = useQuery([ENDPOINT.EMAIL_ACCOUNT.GET_ALL], () =>
    EmailAccountService.getAll(),
  )

  const { mutate: updateConfig, isLoading: isLoadingUpdateConfig } =
    useMutation((body: IUpdateConfigRequest) => ConfigService.update(body), {
      onSuccess: () => {
        refetchConfigs()
        refetchAccountList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
      },
      onError: (error: any, body) => {},
    })

  const handleUpdateConfig = (values: any) => {
    const body: IUpdateConfigRequest = {
      bank_username: values?.bank_username,
      bank_password: values?.bank_password,
      bank_account_no: values?.bank_account_no,
      bank_device_id: values?.bank_device_id,
      limit_email_daily: values?.limit_email_daily,
      send_email_delay: values?.send_email_delay,
      system_email_id: values?.system_email_id,
      email_notified: values?.email_notified,
      ticket_registration_time: values?.ticket_registration_time,
      ticket_payment_time: values?.ticket_payment_time,
    }

    updateConfig(body)
  }

  return (
    <div className="p-config">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <div className="body">
        <SpinWrap spinning={isFetchingConfigs || isFetchingAccountList}>
          <Form layout="vertical" onFinish={handleSubmit(handleUpdateConfig)}>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title="Cấu hình ngân hàng"
            >
              <FormItemWrap
                label="Tài khoản ngân hàng"
                required
                validateStatus={errors?.bank_username ? "error" : ""}
                help={
                  <>{errors?.bank_username && errors?.bank_username?.message}</>
                }
              >
                <Controller
                  name="bank_username"
                  control={control}
                  render={({ field }) => (
                    <InputWrap
                      placeholder="Nhập Tài khoản ngân hàng"
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Mật khẩu ngân hàng"
                required
                validateStatus={errors?.bank_password ? "error" : ""}
                help={
                  <>{errors?.bank_password && errors?.bank_password?.message}</>
                }
              >
                <Controller
                  name="bank_password"
                  control={control}
                  render={({ field }) => (
                    <InputPasswordWrap
                      placeholder="Nhập Mật khẩu ngân hàng"
                      autoComplete="off"
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Số tài khoản"
                required
                validateStatus={errors?.bank_account_no ? "error" : ""}
                help={
                  <>
                    {errors?.bank_account_no &&
                      errors?.bank_account_no?.message}
                  </>
                }
              >
                <Controller
                  name="bank_account_no"
                  control={control}
                  render={({ field }) => (
                    <InputWrap
                      placeholder="Số tài khoản"
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Mã thiết bị"
                required
                validateStatus={errors?.bank_device_id ? "error" : ""}
                help={
                  <>
                    {errors?.bank_device_id && errors?.bank_device_id?.message}
                  </>
                }
              >
                <Controller
                  name="bank_device_id"
                  control={control}
                  render={({ field }) => (
                    <InputWrap
                      placeholder="Nhập mã thiết bị"
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
            </Card>
            <Card
              style={{ marginTop: 16 }}
              type="inner"
              title="Cấu hình hệ thống"
            >
              <FormItemWrap
                label="Giới hạn email gửi hàng ngày"
                tooltip="Số lượng email có thể gửi hàng ngày (đối với từng tài khoản)"
                required
                validateStatus={errors?.bank_username ? "error" : ""}
                help={
                  <>{errors?.bank_username && errors?.bank_username?.message}</>
                }
              >
                <Controller
                  name="limit_email_daily"
                  control={control}
                  render={({ field }) => (
                    <InputNumberWrap
                      placeholder="Nhập số lượng"
                      field={field}
                      addonAfter="lần/tài khoản"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Thời gian chờ giữa 2 lần gửi email"
                tooltip="Áp dụng cho trường hợp gửi 2 email trở lên"
                required
                validateStatus={errors?.send_email_delay ? "error" : ""}
                help={
                  <>
                    {errors?.send_email_delay &&
                      errors?.send_email_delay?.message}
                  </>
                }
              >
                <Controller
                  name="send_email_delay"
                  control={control}
                  render={({ field }) => (
                    <InputNumberWrap
                      placeholder="Nhập thời gian"
                      field={field}
                      addonAfter="giây"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Email gửi thông báo"
                tooltip="Các thông báo của hệ thống sẽ được gửi bằng email này"
                required
                validateStatus={errors?.system_email_id ? "error" : ""}
                help={
                  <>
                    {errors?.system_email_id &&
                      errors?.system_email_id?.message}
                  </>
                }
              >
                <Controller
                  name="system_email_id"
                  control={control}
                  render={({ field }) => (
                    <SelectWrap
                      placeholder="Chọn email"
                      type={SELECT_TYPE.FROM_EMAIL}
                      options={convertArrayToOptions(
                        accountList?.data!,
                        "id",
                        "username",
                      )}
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Email nhận thông báo"
                tooltip="Khi có lỗi, thông báo sẽ được gửi về email này"
                required
                validateStatus={errors?.email_notified ? "error" : ""}
                help={
                  <>
                    {errors?.email_notified && errors?.email_notified?.message}
                  </>
                }
              >
                <Controller
                  name="email_notified"
                  control={control}
                  render={({ field }) => (
                    <InputWrap
                      placeholder="Nhập email"
                      field={field}
                      allowClear
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Thời gian đăng ký vé"
                tooltip="Sau thời gian này thì biểu mẫu đăng ký vé sẽ hết hạn"
                required
                validateStatus={errors?.ticket_registration_time ? "error" : ""}
                help={
                  <>
                    {errors?.ticket_registration_time &&
                      errors?.ticket_registration_time?.message}
                  </>
                }
              >
                <Controller
                  name="ticket_registration_time"
                  control={control}
                  render={({ field }) => (
                    <InputNumberWrap
                      placeholder="Nhập thời gian"
                      field={field}
                      addonAfter="phút"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </FormItemWrap>
              <FormItemWrap
                label="Thời gian thanh toán vé"
                tooltip="Sau thời gian này thì biểu mẫu thanh toán vé sẽ hết hạn"
                required
                validateStatus={errors?.ticket_payment_time ? "error" : ""}
                help={
                  <>
                    {errors?.ticket_payment_time &&
                      errors?.ticket_payment_time?.message}
                  </>
                }
              >
                <Controller
                  name="ticket_payment_time"
                  control={control}
                  render={({ field }) => (
                    <InputNumberWrap
                      placeholder="Nhập thời gian"
                      field={field}
                      addonAfter="phút"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </FormItemWrap>
            </Card>
            <div className="action-btns">
              <ButtonWrap onClick={() => resetFormWithValue(configs)}>
                Reset
              </ButtonWrap>
              <ButtonWrap
                type="primary"
                htmlType="submit"
                disabled={!isDirty}
                loading={isLoadingUpdateConfig}
              >
                Lưu
              </ButtonWrap>
            </div>
          </Form>
        </SpinWrap>
      </div>
    </div>
  )
}
