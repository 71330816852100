import { FormItemWrap, InputWrap, SelectWrap } from "@/components/commons"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import { convertArrayToOptions } from "@/utils/helpers"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"
import "./style.scss"

interface IProps {
  permissionGroupList: IPermissionGroup[]
}

export const CreatePermission: React.FC<IProps> = ({ permissionGroupList }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setValue("modal_name", MODAL.CREATE_PERMISSION)
  }, [])

  return (
    <div className="c-create-permission">
      <Form layout="vertical">
        <FormItemWrap
          label="Tên quyền"
          required
          validateStatus={errors?.display_name ? "error" : ""}
          help={<>{errors?.display_name && errors?.display_name?.message}</>}
        >
          <Controller
            name="display_name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap
                placeholder="Nhập tên quyền"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Key"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap placeholder="Nhập key" field={field} allowClear />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Nhóm">
          <Controller
            name="group_id"
            control={control}
            render={({ field }) => (
              <SelectWrap
                placeholder="Chọn nhóm"
                options={convertArrayToOptions(
                  permissionGroupList!,
                  "id",
                  "name",
                )}
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
