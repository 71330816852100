import { DatePicker } from "antd"
import { RangePickerProps } from "antd/es/date-picker"
import { ControllerRenderProps } from "react-hook-form"

interface IProps extends Omit<RangePickerProps, "value" | "onChange"> {
  field?: ControllerRenderProps<any, any>
  placeholder?: [string, string]
  format?: [string, string]
  showTime?: boolean
}

export const RangerPickerWrap: React.FC<IProps> = ({
  className,
  placeholder,
  format,
  showTime,
  field,
}) => {
  return (
    <DatePicker.RangePicker
      className={className}
      placeholder={placeholder}
      format={format}
      showTime={showTime}
      {...field}
    />
  )
}
