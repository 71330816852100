import {
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SwitchWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { IGetPartnerResponse } from "@/types/response"
import { UPLOAD_LIST_TYPE } from "@/utils/constants"
import { getFileUploadedObject } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, UploadFile } from "antd"
import { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"
import "./style.scss"

interface IProps {
  setIsLoadingUpload: (value: boolean) => void
  data: IGetPartnerResponse
}

export const UpdatePartner: React.FC<IProps> = ({
  setIsLoadingUpload,
  data,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([])
  console.log("data", data)

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const formMonitor = useWatch({
    control,
  })

  console.log("formMonitor", formMonitor)

  const { mutate: uploadFile, isLoading } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return { ...file, url: response?.data?.path, status: "done" }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedFiles((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeFile = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
      }

      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, tempFile])

      uploadFile({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedFiles((prevUploadedFiles) =>
        prevUploadedFiles.filter((uploadFile) => uploadFile.uid !== file.uid),
      )
    }
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    setIsLoadingUpload(isLoading)
  }, [isLoading])

  useEffect(() => {
    setValue("modal_name", MODAL.UPDATE_PARTNER)
  }, [])

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_PARTNER,
      id: data.id,
      name: data.name,
      description: data?.description,
      priority: data.priority,
      is_displayed: data.is_displayed,
      logo_path: data?.logo_path,
    })

    data?.logo_path &&
      setUploadedFiles([getFileUploadedObject(data?.logo_path)])
  }, [data])

  useEffect(() => {
    setValue("logo_path", uploadedFiles[0]?.url)
  }, [uploadedFiles])

  return (
    <div className="c-create-partner">
      <Form layout="vertical">
        <FormItemWrap
          label="Tên đối tác"
          required
          validateStatus={errors?.name ? "error" : ""}
          help={<>{errors?.name && errors?.name?.message}</>}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputWrap
                placeholder="Nhập tên đối tác"
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Logo">
          <UploadWrap
            listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
            onChangeFiles={handleChangeFile}
            onRemoveFiles={handleRemoveFile}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            maxCount={1}
          />
        </FormItemWrap>

        <FormItemWrap label="Mô tả">
          <Controller
            name="description"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextAreaWrap
                placeholder="Nhập mô tả"
                rows={8}
                field={field}
                allowClear
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap
          label="Thứ tự hiển thị"
          required
          validateStatus={errors?.priority ? "error" : ""}
          help={<>{errors?.priority && errors?.priority?.message}</>}
        >
          <Controller
            name="priority"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputNumberWrap placeholder="Nhập thứ tự" field={field} />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Trạng thái hiển thị">
          <Controller
            name="is_displayed"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <SwitchWrap
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                field={field}
              />
            )}
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
