import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreatePermissionRequest,
  IGetListPermissionRequest,
  IUpdatePermissionRequest,
} from "@/types/request/permission"
import {
  ICreatePermissionResponse,
  IGetAllPermissionByGroupResponse,
  IGetAllPermissionResponse,
  IGetListPermissionResponse,
  IGetRemainingPermissionResponse,
  IUpdatePermissionResponse,
} from "@/types/response/permission"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class Permission {
  getAll = (): Promise<IResponse<IGetAllPermissionResponse>> => {
    return http.get(ENDPOINT.PERMISSION.GET_ALL)
  }

  getAllByGroup = (): Promise<IResponse<IGetAllPermissionByGroupResponse>> => {
    return http.get(ENDPOINT.PERMISSION.GET_ALL_BY_GROUP)
  }

  getRemaining = (): Promise<IResponse<IGetRemainingPermissionResponse>> => {
    return http.get(ENDPOINT.PERMISSION.GET_REMAINING)
  }

  getList = (
    params: IGetListPermissionRequest,
  ): Promise<IResponsePagination<IGetListPermissionResponse>> => {
    return http.get(ENDPOINT.PERMISSION.GET_LIST, { params: params })
  }

  create = (
    body: ICreatePermissionRequest,
  ): Promise<IResponse<ICreatePermissionResponse>> => {
    return http.post(ENDPOINT.PERMISSION.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdatePermissionRequest,
  ): Promise<IResponse<IUpdatePermissionResponse>> => {
    return http.put(ENDPOINT.PERMISSION.UPDATE.replace(":id", String(id)), body)
  }

  forceDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.PERMISSION.FORCE_DELETE_ONE.replace(":id", String(id)),
    )
  }

  forceDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.PERMISSION.FORCE_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const PermissionService = new Permission()
