import {
  InputCellWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
  TooltipWrap,
} from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { SliderService, SliderTypeService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { IModalState } from "@/types/common"
import { ICreateSliderRequest, IUpdateSliderRequest } from "@/types/request"
import { IGetSliderResponse } from "@/types/response"
import { IGetPartnerResponse } from "@/types/response/partner"
import { IMAGE_TYPE } from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  getImage,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Image, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateSliderType, UpdateSlider, ViewSliderDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const SliderType: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name"),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
      is_displayed: true,
    },
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetSliderResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateSlider = (values: any) => {
    const body: ICreateSliderRequest = {
      title: values.title,
      description: values.description,
      image_path: values?.image_path,
      cta_text: values?.cta_text,
      link_to: values?.link_to,
      type_id: values?.type_id,
      is_displayed: values?.is_displayed,
      priority: values?.priority,
    }

    createSlider(body)
  }

  const handleUpdateSlider = (values: any) => {
    const body: IUpdateSliderRequest = {
      title: values.title,
      description: values.description,
      image_path: values?.image_path,
      cta_text: values?.cta_text,
      link_to: values?.link_to,
      type_id: values?.type_id,
      is_displayed: values?.is_displayed,
      priority: values?.priority,
    }

    updateSlider({ id: values.id, body })
  }

  const handleDeleteOnePartner = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManyPartner = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: IGetSliderResponse) => {
    switch (type) {
      case MODAL.CREATE_SLIDER_TYPE:
        setModal({
          open: true,
          title: t("P_SLIDER_TYPE.TITLE_CREATE_SLIDER_TYPE"),
          width: 608,
          bodyHeight: 300,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateSlider),
          onCancel: hanldeCancelModal,
          children: (
            <CreateSliderType setIsLoadingUpload={setIsLoadingUpload} />
          ),
        })
        break
      // case MODAL.UPDATE_SLIDER:
      //   setModal({
      //     open: true,
      //     title: t("P_SLIDER.TITLE_UPDATE_SLIDER"),
      //     width: 608,
      //     bodyHeight: 588,
      //     okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
      //     onOk: handleSubmit(handleUpdateSlider),
      //     onCancel: hanldeCancelModal,
      //     children: (
      //       <UpdateSlider
      //         data={record!}
      //         sliderTypeList={sliderTypeList?.data}
      //         setIsLoadingUpload={setIsLoadingUpload}
      //       />
      //     ),
      //   })
      //   break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOnePartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyPartner),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SLIDER.TITLE_DETAIL_SLIDER"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: <ViewSliderDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: sliderTypeList,
    refetch: refetchTypeSliderList,
    isFetching: isFetchingTypeSliderList,
  } = useQuery([ENDPOINT.SLIDER_TYPE.GET_LIST, params], () =>
    SliderTypeService.getList(convertRequest(params)),
  )

  const { mutate: createSlider, isLoading: isLoadingCreateSlider } =
    useMutation((body: ICreateSliderRequest) => SliderService.create(body), {
      onSuccess: () => {
        refetchTypeSliderList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    })

  const { mutate: updateSlider } = useMutation(
    (payload: { id: number; body: IUpdateSliderRequest }) =>
      SliderService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchTypeSliderList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsDislayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      SliderService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchTypeSliderList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updatePriority } = useMutation(
    (payload: { id: number; body: { priority: number } }) =>
      SliderService.updatePriority(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchTypeSliderList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => SliderService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchTypeSliderList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => SliderService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchTypeSliderList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IGetPartnerResponse> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "60px",
      align: "center",
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Màu nền",
      dataIndex: "bg_color",
      width: "160px",
      align: "center",
      render: (value) => {
        return (
          <div
            className="column-bg-color"
            style={{
              background: value,
            }}
          ></div>
        )
      },
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "82px",
      align: "center",
      fixed: "right",
      render: (record: IGetSliderResponse) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-slider-type">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={sliderTypeList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingTypeSliderList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={sliderTypeList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreateSlider}
        disabled={isLoadingUpload}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
