import { ButtonWrap } from "@/components/commons"
import { EmailService } from "@/services/email"
import { ISendEmailAllRequest } from "@/types/request"
import { ERROR_CODES, NOTIFICATION_STATUS } from "@/utils/constants"
import { notificationMessage } from "@/utils/helpers"
import { Form, Steps } from "antd"
import { useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { useParams } from "react-router-dom"
import { MODAL } from "../../constants"
import { ConfirmContent, EmailInformation } from "./Tabs"
import ConfirmReceiver from "./Tabs/ConfirmReceiver"
import "./style.scss"

interface IProps {
  handleCancelModal: () => void
}

export const SendEmailAll: React.FC<IProps> = ({ handleCancelModal }) => {
  const { control, reset, trigger } = useFormContext()
  const { event_id: eventId } = useParams()

  const formMonitor = useWatch({
    control,
  })

  const [activeStep, setActiveStep] = useState<number>(0)

  const steps = [
    {
      title: "Nội dung gửi",
      content: <EmailInformation />,
      disabled: true,
    },
    {
      title: "Xác nhận nội dung gửi",
      content: <ConfirmContent />,
      disabled: true,
    },
    {
      title: "Xác nhận người nhận",
      content: <ConfirmReceiver />,
      disabled: true,
    },
  ]

  const { mutate: sendEmailAll, isLoading: isLoadingSendEmailAll } =
    useMutation((body: any) => EmailService.sendEmailAll(body), {
      onSuccess: () => {
        handleCancelModal()
        notificationMessage({
          message: "Tiến trình gửi email đang được thực hiện",
          type: "success",
        })
      },
      onError: (error: any, body) => {
        if (error?.error_code === ERROR_CODES.NOT_ENOUGH_EMAILS) {
          notificationMessage({
            message: "Số lượng email còn lại không đủ để gửi",
            type: NOTIFICATION_STATUS.ERROR,
          })
        }

        if (error?.error_code === ERROR_CODES.NO_RECIPIENT) {
          notificationMessage({
            message: "Danh sách người nhận đang trống",
            type: NOTIFICATION_STATUS.ERROR,
          })
        }
      },
    })

  const handleNext = async () => {
    if (activeStep < 2) {
      if (activeStep === 0) {
        // Step: EmailInformation
        // const isValid = await trigger(["subject", "content", "account_ids"])
        // if (!isValid) return
      }
      // Step: ConfirmContent
      setActiveStep((prevStep) => prevStep + 1)
    }

    if (activeStep === 2) {
      // Step: ConfirmReceiver > Send
      const body: ISendEmailAllRequest = {
        subject: formMonitor?.subject,
        content: formMonitor?.content,
        event_id: Number(eventId),
        account_ids: formMonitor?.account_ids,
        status: formMonitor?.ticket_status,
        send_ticket_status: formMonitor?.send_ticket_status,
        is_attach_ticket: formMonitor?.is_attach_ticket,
      }
      sendEmailAll(body)
    }
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  useEffect(() => {
    reset({
      modal_name: MODAL.SEND_EMAIL_ALL,
      is_attach_ticket: false,
    })
  }, [])

  return (
    <Form layout="vertical" className="c-send-email-all-modal">
      <Steps
        current={activeStep}
        items={steps}
        labelPlacement="vertical"
        onChange={(curr) => setActiveStep(curr)}
      />
      <div className="steps-content">{steps[activeStep]?.content}</div>
      <div className="action-btns">
        {activeStep > 0 ? (
          <ButtonWrap size="middle" onClick={handleBack}>
            Quay lại
          </ButtonWrap>
        ) : (
          <div></div>
        )}
        {activeStep < steps.length - 1 && (
          <ButtonWrap
            type="primary"
            size="middle"
            htmlType="submit"
            onClick={handleNext}
          >
            Tiếp tục
          </ButtonWrap>
        )}
        {activeStep === steps.length - 1 && (
          <ButtonWrap
            type="primary"
            size="middle"
            onClick={handleNext}
            loading={isLoadingSendEmailAll}
            disabled={isLoadingSendEmailAll}
          >
            Gửi
          </ButtonWrap>
        )}
      </div>
    </Form>
  )
}
