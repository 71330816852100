import {
  ButtonWrap,
  FormItemWrap,
  SelectWrap,
  UploadDraggerWrap,
} from "@/components/commons"
import { EventTicketService } from "@/services/eventTicket"
import { IUploadedFile } from "@/types/common"
import { IGetAllEventPositionResponse } from "@/types/response/eventPosition"
import { UPLOAD_LIST_TYPE, importSourceModes } from "@/utils/constants"
import { convertObjectToArray, exportExcelFile } from "@/utils/helpers"
import { Alert, Form, Space } from "antd"
import { UploadFile } from "antd/lib"
import { useEffect, useState } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useMutation } from "react-query"
import { MODAL } from "../../constants"

interface IProps {
  eventPositionList?: IGetAllEventPositionResponse
}

export const ImportEventTicket: React.FC<IProps> = ({ eventPositionList }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const { mutate: downloadSample, isLoading: isLoadingCreateEmailAccount } =
    useMutation(() => EventTicketService.exportSample(), {
      onSuccess: (response: any) => {
        exportExcelFile({
          data: response,
          fileName: "sample",
        })
      },
      onError: (error: any, body) => {},
    })

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])

  const formMonitor = useWatch({
    control,
  })

  const handleChangeFile = (file: UploadFile) => {
    console.log("file", file)
    setUploadedFiles([file])
  }

  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    setValue("file", uploadedFiles[0])
  }, [uploadedFiles])

  useEffect(() => {
    setValue("modal_name", MODAL.IMPORT_EVENT_TICKET)
    setValue("generate_ticket_code", Number(false))
    setValue("generate_qr_code", Number(false))
    setValue("generate_created_at", Number(false))
    setValue("generate_updated_at", Number(false))
  }, [])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Alert
        message="Vé nhập vào sẽ ở trạng thái Đăng ký thành công"
        type="success"
      />
      <Alert
        message="Vé nhập vào sẽ có Người khởi tạo, Chủ sở hữu mặc định là Quản Trị Viên (@qtv)"
        type="warning"
      />
      <Alert
        message="Tải xuống Tệp mẫu tại đây"
        type="error"
        action={
          <ButtonWrap
            size="small"
            type="primary"
            onClick={() => downloadSample()}
          >
            Tải xuống
          </ButtonWrap>
        }
      />
      <Form layout="vertical">
        <FormItemWrap
          required
          validateStatus={errors?.file ? "error" : ""}
          help={
            <>{errors?.file && !formMonitor.file && errors?.file?.message}</>
          }
        >
          <UploadDraggerWrap
            onChangeFiles={handleChangeFile}
            uploadedFiles={uploadedFiles}
            listType={UPLOAD_LIST_TYPE.PICTURE}
            description="Chỉ hỗ trợ các tệp có định dạng .xlsx, .xls, .csv"
            maxCount={1}
            accept=".xlsx, .xls, .csv"
          />
        </FormItemWrap>
        <FormItemWrap label="Mã vé" required>
          <Controller
            name="generate_ticket_code"
            control={control}
            render={({ field }) => (
              <SelectWrap
                options={convertObjectToArray(importSourceModes)}
                field={field}
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Mã QR" required>
          <Controller
            name="generate_qr_code"
            control={control}
            render={({ field }) => (
              <SelectWrap
                options={convertObjectToArray(importSourceModes)}
                field={field}
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Ngày khởi tạo" required>
          <Controller
            name="generate_created_at"
            control={control}
            render={({ field }) => (
              <SelectWrap
                options={convertObjectToArray(importSourceModes)}
                field={field}
              />
            )}
          />
        </FormItemWrap>
        <FormItemWrap label="Ngày cập nhật" required>
          <Controller
            name="generate_updated_at"
            control={control}
            render={({ field }) => (
              <SelectWrap
                options={convertObjectToArray(importSourceModes)}
                field={field}
              />
            )}
          />
        </FormItemWrap>
      </Form>
    </Space>
  )
}
