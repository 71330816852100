import { Pagination, PaginationProps } from "antd"

const showTotal: PaginationProps["showTotal"] = (total) => `${total} kết quả`

export const PaginationWrap: React.FC<PaginationProps> = ({
  className,
  ...props
}) => {
  return <Pagination className={className} showTotal={showTotal} {...props} />
}
