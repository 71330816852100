import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreatePermissionGroupRequest,
  IGetListPermissionGroupRequest,
  IUpdatePermissionGroupRequest,
} from "@/types/request/permissionGroup"
import {
  ICreatePermissionGroupResponse,
  IGetAllPermissionGroupResponse,
  IGetListPermissionGroupResponse,
  IUpdatePermissionGroupResponse,
} from "@/types/response/permissionGroup"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class PermissionGroup {
  getAll = (): Promise<IResponse<IGetAllPermissionGroupResponse>> => {
    return http.get(ENDPOINT.PERMISSION_GROUP.GET_ALL)
  }

  getList = (
    params: IGetListPermissionGroupRequest,
  ): Promise<IResponsePagination<IGetListPermissionGroupResponse>> => {
    return http.get(ENDPOINT.PERMISSION_GROUP.GET_LIST, { params: params })
  }

  create = (
    body: ICreatePermissionGroupRequest,
  ): Promise<IResponse<ICreatePermissionGroupResponse>> => {
    return http.post(ENDPOINT.PERMISSION_GROUP.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdatePermissionGroupRequest,
  ): Promise<IResponse<IUpdatePermissionGroupResponse>> => {
    return http.put(
      ENDPOINT.PERMISSION_GROUP.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  updatePriority = (
    id: number,
    body: {
      priority: number
    },
  ) => {
    return http.put(
      ENDPOINT.PERMISSION_GROUP.UPDATE_PRIORITY.replace(":id", String(id)),
      body,
    )
  }

  forceDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.PERMISSION_GROUP.FORCE_DELETE_ONE.replace(":id", String(id)),
    )
  }

  forceDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.PERMISSION_GROUP.FORCE_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const PermissionGroupService = new PermissionGroup()
