import * as yup from "yup"
import { MODAL } from "./constants"
import { REGEX } from "@/utils/constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_SLIDER_TYPE ||
        modalName === MODAL.UPDATE_SLIDER_TYPE) &&
      yup.string().required("Vui lòng nhập Tên"),
  } as any)
