import { MODAL } from "@/pages/Event/constants"
import { ENDPOINT } from "@/services/endpoint"
import { ProvinceService } from "@/services/province"
import { IAddress } from "@/types/common"
import { IDistrict, IProvince } from "@/types/response/province"
import { convertArrayToOptions } from "@/utils/helpers"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { QueryClient, useQuery } from "react-query"
import { FormItemWrap } from "../FormItemWrap"
import { InputWrap } from "../InputWrap"
import { SelectWrap } from "../SelectWrap"

interface IProps {
  addressData: IAddress
}
export const SelectAddressWrap: React.FC<IProps> = ({ addressData }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  const queryClient = new QueryClient()

  const formMonitor = useWatch({
    control,
  })

  const { data: provinceList } = useQuery(
    [ENDPOINT.PROVINCE.GET_PROVINCES],
    () => ProvinceService.getProvinces(),
    {
      onSuccess: () => {},
    },
  )

  const provinceId = provinceList?.data?.find(
    (province: IProvince) =>
      province.name_with_type === formMonitor.province_name,
  )?.code!

  const { data: districtList } = useQuery(
    [ENDPOINT.PROVINCE.GET_DISTRICTS, { province_id: provinceId }],
    () =>
      ProvinceService.getDistricts({
        province_id: provinceId,
      }),
    {
      enabled: !!formMonitor.province_name,
    },
  )

  const districtId = districtList?.data?.find(
    (district: IDistrict) =>
      district.name_with_type === formMonitor.district_name,
  )?.code!

  const { data: wardList } = useQuery(
    [ENDPOINT.PROVINCE.GET_WARDS, { district_id: districtId }],
    () =>
      ProvinceService.getWards({
        district_id: districtId,
      }),
    {
      enabled: !!formMonitor.district_name,
    },
  )

  useEffect(() => {
    setValue("modal_name", MODAL.SELECT_ADDRESS)
    if (
      addressData?.province_name &&
      addressData?.district_name &&
      addressData?.ward_name
    ) {
      setValue("province_name", addressData.province_name)
      setValue("district_name", addressData.district_name)
      setValue("ward_name", addressData.ward_name)
      setValue("street_name", addressData.street_name)
      setValue("house_number", addressData.house_number)
      setValue("place_name", addressData.place_name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Thành phố (Tỉnh)"
        required
        validateStatus={errors?.province_name ? "error" : ""}
        help={<>{errors?.province_name && errors?.province_name?.message}</>}
      >
        <Controller
          name="province_name"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn Thành phố (Tỉnh)"
              showSearch
              allowClear
              onClear={() => {
                setValue("province_name", undefined)
                setValue("district_name", undefined)
                setValue("ward_name", undefined)
                queryClient.removeQueries({
                  queryKey: [
                    ENDPOINT.PROVINCE.GET_DISTRICTS,
                    { province_id: provinceId },
                  ],
                })
                queryClient.removeQueries({
                  queryKey: [
                    ENDPOINT.PROVINCE.GET_WARDS,
                    { district_id: districtId },
                  ],
                })
              }}
              filterOption={(input, option) => {
                return (option?.key ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              options={convertArrayToOptions(
                provinceList?.data!,
                "name_with_type",
                "name_with_type",
                "string",
              )}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Quận (Huyện)"
        required
        validateStatus={errors?.district_name ? "error" : ""}
        help={<>{errors?.district_name && errors?.district_name?.message}</>}
      >
        <Controller
          name="district_name"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn Quận (Huyện)"
              showSearch
              allowClear
              onClear={() => {
                setValue("district_name", undefined)
                setValue("ward_name", undefined)
              }}
              filterOption={(input, option) => {
                return (option?.key ?? "").toLowerCase().includes(input)
              }}
              options={convertArrayToOptions(
                districtList?.data!,
                "name_with_type",
                "name_with_type",
                "string",
              )}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Phường (Xã)"
        required
        validateStatus={errors?.ward_name ? "error" : ""}
        help={<>{errors?.ward_name && errors?.ward_name?.message}</>}
      >
        <Controller
          name="ward_name"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <SelectWrap
              showSearch
              allowClear
              placeholder="Chọn Phường (Xã)"
              filterOption={(input, option) => {
                return (option?.key ?? "").toLowerCase().includes(input)
              }}
              options={convertArrayToOptions(
                wardList?.data!,
                "name_with_type",
                "name_with_type",
                "string",
              )}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Đường/Tòa nhà"
        required
        validateStatus={errors?.street_name ? "error" : ""}
        help={<>{errors?.street_name && errors?.street_name?.message}</>}
      >
        <Controller
          name="street_name"
          control={control}
          render={({ field }) => (
            <InputWrap placeholder="Nhập Đường/Tòa nhà" field={field} />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Số nhà/Tầng">
        <Controller
          name="house_number"
          control={control}
          render={({ field }) => (
            <InputWrap placeholder="Nhập Số nhà/Tầng" field={field} />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Tên địa điểm">
        <Controller
          name="place_name"
          control={control}
          render={({ field }) => (
            <InputWrap placeholder="Nhập tên địa điểm" field={field} />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
