import ReactPlayer from "react-player"

interface IProps {
  path: string
}

export const PreviewSong: React.FC<IProps> = ({ path }) => {
  return (
    <div className="c-preview-modal">
      <ReactPlayer
        url={path}
        controls={true}
        id="reactPlayer"
        className="react-player"
      />
    </div>
  )
}
