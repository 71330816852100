import {
  DatePickerWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
  TimePickerWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import UploadWrap from "@/components/commons/UploadWrap"
import { FileService } from "@/services"
import { IUploadedFile } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { ISongGroup } from "@/types/response"
import { UPLOAD_LIST_TYPE, songGroupTypes } from "@/utils/constants"
import {
  convertArrayToOptions,
  convertObjectToArray,
  getFileUploadedObject,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, UploadFile } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useMutation } from "react-query"
import { v4 as uuidv4 } from "uuid"
import { MODAL } from "../../constants"

interface IProps {
  data: ISongGroup
  setIsLoadingUpload: (value: boolean) => void
}

export const UpdateSongGroup: React.FC<IProps> = ({
  data,
  setIsLoadingUpload,
}) => {
  const [uploadedThumbnails, setUploadedThumbnails] = useState<IUploadedFile[]>(
    [],
  )
  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext()

  const { mutate: uploadThumbnail, isLoading: isLoading } = useMutation(
    (body: IUploadFileRequest) => FileService.uploadImage(body),
    {
      onSuccess: (response) => {
        setUploadedThumbnails((prevUploadThumbnails) =>
          prevUploadThumbnails.map((file) => {
            if (file.uid === response?.data?.uuid) {
              delete file.percent

              return {
                ...file,
                id: response.data.id,
                url: response?.data?.path,
                status: "done",
              }
            }

            return file
          }),
        )
      },
      onError: (error: any, body) => {
        setUploadedThumbnails((prevUploadFiles) =>
          prevUploadFiles.map((file) => {
            if (file.uid === body.uuid) {
              delete file.percent

              return { ...file, status: "error" }
            }

            return file
          }),
        )
      },
    },
  )

  const handleChangeThumbnail = (file: UploadFile, fileList: UploadFile[]) => {
    if (!file.status) {
      const tempFile: IUploadedFile = {
        uid: uuidv4(),
        name: file.name,
        status: "uploading",
        percent: 50,
        mimeType: file.type,
      }

      setUploadedThumbnails((prevUploadThumbnails) => [
        ...prevUploadThumbnails,
        tempFile,
      ])

      uploadThumbnail({
        file,
        folder: "images",
        uuid: tempFile.uid,
      })
    } else if (file.status === "removed") {
      setUploadedThumbnails((prevUploadThumbnails) =>
        prevUploadThumbnails.filter(
          (uploadFile) => uploadFile.uid !== file.uid,
        ),
      )
    }
  }
  const handleRemoveFile = (removedFile: IUploadedFile) => {}

  useEffect(() => {
    setIsLoadingUpload(isLoading)
  }, [isLoading])

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_SONG_GROUP,
      id: data.id,
      name: data.name,
      thumbnail_path: data?.thumbnail_path,
      description: data?.description,
      day_release: data?.day_release ? moment(data?.day_release) : null,
      time_release: data?.time_release
        ? moment(data?.time_release, "HH:mm")
        : null,
      type: data.type,
      is_displayed: data.is_displayed,
    })

    data?.thumbnail_path &&
      setUploadedThumbnails([getFileUploadedObject(data?.thumbnail_path)])
  }, [data])

  useEffect(() => {
    setValue("image_path", uploadedThumbnails[0]?.url)
  }, [uploadedThumbnails])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap placeholder="Nhập tên nhóm" field={field} allowClear />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Mô tả">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextAreaWrap
              placeholder="Nhập mô tả"
              rows={6}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Thumbnail">
        <UploadWrap
          listType={UPLOAD_LIST_TYPE.PICTURE_CARD}
          onChangeFiles={handleChangeThumbnail}
          onRemoveFiles={handleRemoveFile}
          uploadedFiles={uploadedThumbnails}
          setUploadedThumbnails={setUploadedThumbnails}
          maxCount={1}
          accept="image/*"
        />
      </FormItemWrap>
      <FormItemWrap
        label="Ngày phát hành"
        required
        validateStatus={errors?.day_release ? "error" : ""}
        help={<>{errors?.day_release && errors?.day_release?.message}</>}
      >
        <Controller
          name="day_release"
          control={control}
          render={({ field }) => (
            <DatePickerWrap
              placeholder="Chọn ngày"
              format="DD/MM/YYYY"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Giờ phát hành">
        <Controller
          name="time_release"
          control={control}
          render={({ field }) => (
            <TimePickerWrap
              placeholder="Chọn giờ"
              format="HH:mm"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Loại"
        required
        validateStatus={errors?.type_id ? "error" : ""}
        help={<>{errors?.type_id && errors?.type_id?.message}</>}
      >
        <Controller
          name="type"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SelectWrap
              allowClear
              placeholder="Chọn loại"
              options={convertArrayToOptions(
                convertObjectToArray(songGroupTypes, "string"),
                "value",
                "label",
                "string",
              )}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái hiển thị" required>
        <Controller
          name="is_displayed"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
