import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEmailAccountRequest,
  IGetListEmailAccountRequest,
  IUpdateEmailAccountRequest,
} from "@/types/request"
import {
  ICreateEmailAccountResponse,
  IGetAllEmailAccountResponse,
  IGetListEmailAccountResponse,
  IUpdateEmailAccountResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EmailAccount {
  getAll = (): Promise<IResponse<IGetAllEmailAccountResponse>> => {
    return http.get(ENDPOINT.EMAIL_ACCOUNT.GET_ALL)
  }

  getList = (
    params: IGetListEmailAccountRequest,
  ): Promise<IResponsePagination<IGetListEmailAccountResponse>> => {
    return http.get(ENDPOINT.EMAIL_ACCOUNT.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEmailAccountRequest,
  ): Promise<IResponse<ICreateEmailAccountResponse>> => {
    return http.post(ENDPOINT.EMAIL_ACCOUNT.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEmailAccountRequest,
  ): Promise<IResponse<IUpdateEmailAccountResponse>> => {
    return http.put(
      ENDPOINT.EMAIL_ACCOUNT.UPDATE.replace(":id", String(id)),
      body,
    )
  }

  updateIsEnabled = (
    id: number,
    body: {
      is_enabled: boolean
    },
  ) => {
    return http.put(
      ENDPOINT.EMAIL_ACCOUNT.UPDATE_IS_ENABLED.replace(":id", String(id)),
      body,
    )
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EMAIL_ACCOUNT.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EMAIL_ACCOUNT.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EmailAccountService = new EmailAccount()
