import {
  BreadcrumbWrap,
  ButtonWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { TrashIcon } from "@/components/icons"
import { APP_ROUTER } from "@/routes"
import { IGetSliderRequest } from "@/types/request/slider"
import { displayStatusOptions } from "@/utils/constants"
import {
  convertObjectToArray,
  convertToStringObject,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { PlusOutlined } from "@ant-design/icons"
import { Col, Form, Row } from "antd"
import { Key, useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { MODAL } from "../constants"
import "./style.scss"

interface IHeaderProps {
  params: IGetSliderRequest
  selectedItems: Key[]
  onClearSelectedItems: () => void
  handleModalContent: (type: MODAL) => void
}

const Header: React.FC<IHeaderProps> = ({
  params,
  selectedItems,
  onClearSelectedItems,
  handleModalContent,
}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { setValue, control, reset } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.SLIDER.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.SLIDER.LIST"),
      href: APP_ROUTER.SLIDER.LIST,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        title: searchParams.get("title") || "",
        is_displayed: searchParams.get("is_displayed")
          ? Number(searchParams.get("is_displayed"))
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        title: formMonitor?.title || "",
        is_displayed:
          formMonitor.is_displayed === undefined
            ? ""
            : String(formMonitor.is_displayed),
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-slider-header no-select">
      <div className="title">{t("P_SLIDER.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right">
          {selectedItems?.length > 0 ? (
            <>
              <div>
                {replaceStrings(t("COMMON.HEADER_TABLE.BUTTON_UNSELECT"), {
                  count: selectedItems?.length,
                })}
              </div>
              <div className="selected-action-btns">
                <ButtonWrap type="default" onClick={onClearSelectedItems}>
                  {t("COMMON.HEADER_TABLE.BUTTON_UNSELECT")}
                </ButtonWrap>
                <ButtonWrap
                  icon={<TrashIcon fillColor="var(--color-neutral-1)" />}
                  onClick={() => handleModalContent(MODAL.CONFIRM_DELETE_MANY)}
                  type="primary"
                  danger
                >
                  {t("COMMON.HEADER_TABLE.BUTTON_MOVE_TO_TRASH")}
                </ButtonWrap>
              </div>
            </>
          ) : (
            <ButtonWrap
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => handleModalContent(MODAL.CREATE_SLIDER)}
            >
              {t("P_SLIDER.HEADER.BUTTON_ADD_SLIDER")}
            </ButtonWrap>
          )}
        </div>
      </div>
      <Form labelCol={{ span: 4, md: 6 }} labelAlign="right">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap label={t("P_SLIDER.FILTER.LABEL_TITLE")} colon>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t("P_SLIDER.FILTER.PLACEHOLDER_TITLE")}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12} xl={8} xxl={6}>
                <FormItemWrap
                  label={t("P_SLIDER.FILTER.LABEL_IS_DISPLAYED")}
                  colon
                >
                  <Controller
                    name="is_displayed"
                    control={control}
                    render={({ field }) => (
                      <SelectWrap
                        allowClear
                        onClear={() => setValue("is_displayed", "")}
                        placeholder={t(
                          "P_SLIDER.FILTER.PLACEHOLDER_IS_DISPLAYED",
                        )}
                        options={convertObjectToArray(displayStatusOptions)}
                        field={field}
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
