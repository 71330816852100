import { ColumnActionWrap } from "@/components/commons/ColumnActionWrap"
import { EditIcon, EyeIcon, TrashIcon } from "@/components/icons"
import { IColumnActionItem } from "@/types/common"
import { ISongGroup } from "@/types/response"
import { t } from "@/utils/helpers"
import { useState } from "react"
import { MODAL } from "../constants"

interface IColumnActionProps {
  record: ISongGroup
  handleModalContent: (type: MODAL, record?: ISongGroup) => void
}

export const ColumnAction: React.FC<IColumnActionProps> = ({
  record,
  handleModalContent,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenDrawer = () => {
    setOpen(true)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const items: IColumnActionItem[] = [
    {
      key: "1",
      type: "group",
      label: t("COMMON.COLUMN_ACTION.GROUP_ACTION"),
      children: [
        {
          key: "1-1",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.UPDATE_SONG_GROUP, record)
                setOpen(false)
              }}
            >
              <EditIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_EDIT")}
            </div>
          ),
        },
        {
          key: "1-2",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.CONFIRM_DELETE_ONE, record)
                setOpen(false)
              }}
            >
              <TrashIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_SOFT_DELETE")}
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      label: t("COMMON.COLUMN_ACTION.GROUP_DISPLAY"),
      type: "group",
      children: [
        {
          key: "2-1",
          label: (
            <div
              className="label"
              onClick={() => {
                handleModalContent(MODAL.ROW_DETAIL, record)
                setOpen(false)
              }}
            >
              <EyeIcon />
              {t("COMMON.COLUMN_ACTION.LABEL_VIEW_DETAIL")}
            </div>
          ),
        },
      ],
    },
  ]

  return (
    <ColumnActionWrap
      menu={items}
      onOpenDrawer={handleOpenDrawer}
      onCloseDrawer={handleCloseDrawer}
      visibleDrawer={open}
    />
  )
}
