import {
  BreadcrumbWrap,
  ButtonWrap,
  FormItemWrap,
  InputWrap,
} from "@/components/commons"
import { TrashIcon } from "@/components/icons"
import { APP_ROUTER } from "@/routes"
import { convertToStringObject, replaceStrings, t } from "@/utils/helpers"
import { PlusOutlined } from "@ant-design/icons"
import { Col, Form, Row } from "antd"
import { Key, useEffect, useRef } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { MODAL } from "../constants"
import "./style.scss"

interface IProps {
  params: any
  selectedItems: Key[]
  onClearSelectedItems: () => void
  handleModalContent: (type: MODAL) => void
}

const Header: React.FC<IProps> = ({
  params,
  selectedItems,
  onClearSelectedItems,
  handleModalContent,
}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const typingTimeoutRef = useRef<null | NodeJS.Timeout>(null)

  const { control, reset } = useForm<any>({
    mode: "onChange",
  })

  const formMonitor = useWatch({
    control,
  })

  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.USER.PARENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.USER.PERMISSION_GROUP"),
      href: APP_ROUTER.USER.PERMISSION_GROUP,
    },
  ]

  useEffect(() => {
    if (searchParams) {
      reset({
        name: searchParams.get("name") || "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      setSearchParams({
        ...convertToStringObject(params),
        name: formMonitor?.name || "",
      })
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMonitor])

  return (
    <div className="c-permission-group-header no-select">
      <div className="title">{t("P_PERMISSION_GROUP.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right">
          {selectedItems?.length > 0 ? (
            <>
              <div>
                {replaceStrings(
                  t("COMMON.HEADER_TABLE.MSG_RECORD_HAS_BEEN_SELECTED"),
                  {
                    count: selectedItems?.length,
                  },
                )}
              </div>
              <div className="selected-action-btns">
                <ButtonWrap type="default" onClick={onClearSelectedItems}>
                  {t("COMMON.HEADER_TABLE.BUTTON_UNSELECT")}
                </ButtonWrap>
                <ButtonWrap
                  icon={<TrashIcon fillColor="var(--color-neutral-1)" />}
                  onClick={() => handleModalContent(MODAL.CONFIRM_DELETE_MANY)}
                  type="primary"
                  danger
                >
                  {t("COMMON.HEADER_TABLE.BUTTON_MOVE_TO_TRASH")}
                </ButtonWrap>
              </div>
            </>
          ) : (
            <ButtonWrap
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => handleModalContent(MODAL.CREATE_PERMISSION_GROUP)}
            >
              {t("P_PERMISSION_GROUP.HEADER.BUTTON_ADD_PERMISSION_GROUP")}
            </ButtonWrap>
          )}
        </div>
      </div>
      <Form labelCol={{ span: 4, md: 6 }} labelAlign="left">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} sm={12} md={9} xl={7} xxl={6}>
                <FormItemWrap
                  label={t("P_PERMISSION_GROUP.FILTER.LABEL_NAME")}
                  colon
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <InputWrap
                        field={field}
                        size="middle"
                        placeholder={t(
                          "P_PERMISSION_GROUP.FILTER.PLACEHOLDER_NAME",
                        )}
                        allowClear
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Header
