import { FormItemWrap, InputWrap, SelectWrap } from "@/components/commons"
import { IPermissionGroup } from "@/types/response/permissionGroup"
import { convertArrayToOptions } from "@/utils/helpers"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: any
  permissionGroupList?: IPermissionGroup[]
}

export const UpdatePermission: React.FC<IProps> = ({
  data,
  permissionGroupList,
}) => {
  const {
    control,
    reset,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    reset({
      modal_name: MODAL.UPDATE_PERMISSION,
      id: data.id,
      name: data?.name,
      display_name: data.display_name,
      group_id: data?.group?.id,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên quyền"
        required
        validateStatus={errors?.display_name ? "error" : ""}
        help={<>{errors?.display_name && errors?.display_name?.message}</>}
      >
        <Controller
          name="display_name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap placeholder="Nhập tên quyền" field={field} allowClear />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Key"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập key"
              field={field}
              readOnly
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Nhóm">
        <Controller
          name="group_id"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn nhóm"
              options={convertArrayToOptions(
                permissionGroupList!,
                "id",
                "name",
              )}
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
