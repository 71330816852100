import { useAuth } from "@/providers/Auth"
import { IMAGE_TYPE } from "@/utils/constants"
import { getImage, t } from "@/utils/helpers"
import { APP_MENU } from "../menu"
import { SidebarItem } from "./SidebarItem"
import "./style.scss"

export const Sidebar = () => {
  const { currentUser } = useAuth()

  return (
    <div className="c-sidebar">
      <div className="top">
        <div className="user-info">
          <div className="avatar">
            <img
              src={getImage(currentUser?.avatar_path!, IMAGE_TYPE.AVATAR)}
              alt={currentUser?.full_name}
            />
          </div>
          <div className="right">
            <span className="name">{currentUser?.full_name}</span>
            <span className="position">Quản trị viên</span>
          </div>
        </div>
      </div>
      <div className="menu">
        {APP_MENU.map((item, index) => (
          <SidebarItem key={index} data={item} />
        ))}
      </div>
      <div className="bottom">
        <span>{t("LAYOUT.SIDEBAR.COPYRIGHT")}</span>
        <span>{t("LAYOUT.SIDEBAR.WEBSITE_DESCRIPTION")}</span>
      </div>
    </div>
  )
}
