import { ISvgIcon } from "@/types/common"

export const MentionIcon: React.FC<ISvgIcon> = ({
  width = 20,
  height = 20,
  fillColor = "var(--color-neutral-8)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fillColor}
        d="M21 12a3.5 3.5 0 0 1-5.909 2.539A4 4 0 1 1 14 8.535V8h2v4a1.5 1.5 0 0 0 3 0a7 7 0 1 0-3.392 6h3.1A9 9 0 1 1 21 12m-9 2a2 2 0 1 0 0-4a2 2 0 0 0 0 4"
      />
    </svg>
  )
}
