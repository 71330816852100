import {
  CKEditorWrap,
  FormItemWrap,
  InputWrap,
  SelectWrap,
  SwitchWrap,
} from "@/components/commons"
import { IEmailTemplate, IGetAllEmailCategoryResponse } from "@/types/response"
import { convertArrayToOptions } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  data: IEmailTemplate
  emailCategoryList?: IGetAllEmailCategoryResponse
}

export const UpdateEmailTemplate: React.FC<IProps> = ({
  data,
  emailCategoryList,
}) => {
  const {
    control,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext()

  const handleEmailContent = (data: string) => {
    setValue("content", data)
  }

  console.log("xxx", data)

  useEffect(() => {
    reset({
      id: data.id,
      modal_name: MODAL.UPDATE_EMAIL_TEMPLATE,
      subject: data.subject,
      content: data.content,
      category_ids: data?.categories.map((obj) => obj.id),
      is_attach_ticket: data.is_attach_ticket,
      is_enabled: data.is_enabled,
    })
  }, [data])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tiêu đề"
        required
        validateStatus={errors?.subject ? "error" : ""}
        help={<>{errors?.subject && errors?.subject?.message}</>}
      >
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <InputWrap placeholder="Nhập tiêu đề" field={field} allowClear />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Nội dung"
        required
        validateStatus={errors?.content ? "error" : ""}
        help={<>{errors?.content && errors?.content?.message}</>}
      >
        <Controller
          name="content"
          control={control}
          render={() => (
            <CKEditorWrap
              defaultValue={data?.content}
              onChange={handleEmailContent}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Danh mục"
        required
        validateStatus={errors?.category_ids ? "error" : ""}
        help={<>{errors?.category_ids && errors?.category_ids?.message}</>}
      >
        <Controller
          name="category_ids"
          control={control}
          render={({ field }) => (
            <SelectWrap
              allowClear
              onClear={() => setValue("category_ids", "")}
              placeholder="Chọn danh mục"
              mode="multiple"
              options={convertArrayToOptions(emailCategoryList!, "id", "name")}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Đính kèm vé" required>
        <Controller
          name="is_attach_ticket"
          control={control}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Trạng thái sử dụng" required>
        <Controller
          name="is_enabled"
          control={control}
          render={({ field }) => (
            <SwitchWrap
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              field={field}
            />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
