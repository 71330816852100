import {
  ARTIST_LIST_TYPE,
  ArtistListWrap,
  ModalWrap,
  PaginationWrap,
  SwitchWrap,
  TableEditorWrap,
  TableWrap,
  TooltipWrap,
} from "@/components/commons"
import {
  CalendarIcon,
  CompactDiscIcon,
  FileMusicIcon,
  HeadphoneIcon,
} from "@/components/icons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ArtistService } from "@/services"
import { ENDPOINT } from "@/services/endpoint"
import { SongService } from "@/services/song"
import { SongGroupService } from "@/services/songGroup"
import { IModalState } from "@/types/common"
import { ICreateSongRequest, IUpdateSongRequest } from "@/types/request"
import { ISong } from "@/types/response"
import {
  IMAGE_TYPE,
  songGroupTypes,
  songReleaseColors,
  songReleaseStatus,
} from "@/utils/constants"
import {
  convertRequest,
  convertToStringObject,
  formatBytes,
  getFileExtension,
  getImage,
  notificationMessage,
  replaceStrings,
  t,
  truncate,
} from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Badge, Image, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import moment from "moment"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateSong, PreviewSong, UpdateSong, ViewSongDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const Song: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false)

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const [secondModal, setSecondModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    name: searchParams.get("name") || "",
    status: searchParams.get("status") || "",
    group_id: searchParams.get("group_id") || "",
    is_warning: searchParams.get("is_warning") || "",
    is_displayed: searchParams.get("is_displayed") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
  })

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<ISong> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateSong = (values: any) => {
    const body: ICreateSongRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      group_id: values?.group_id,
      artist_ids: values.artist_ids,
      day_release: moment(values.day_release).format("YYYY-MM-DD"),
      time_release: values?.time_release
        ? moment(values?.time_release).format("HH:mm")
        : null,
      file_id: values?.file_id,
      status: values.status,
      is_displayed: values.is_displayed,
      is_warning: values.is_warning,
    }

    createSong(body)
  }

  const handleUpdateSong = (values: any) => {
    console.log("values", values)

    const body: IUpdateSongRequest = {
      name: values.name,
      thumbnail_path: values?.thumbnail_path,
      description: values?.description,
      group_id: values?.group_id,
      artist_ids: values.artist_ids,
      day_release: moment(values.day_release).format("YYYY-MM-DD"),
      time_release: values?.time_release
        ? moment(values?.time_release).format("HH:mm")
        : null,
      file_id: values?.file_id,
      status: values.status,
      is_displayed: values.is_displayed,
      is_warning: values.is_warning,
    }

    updateSong({ id: values.id, body })
  }

  const handleDeleteOneSong = (values: any) => {
    softDeleteOne(values.id)
  }

  const handleDeleteManySong = () => {
    softDeleteMany(selectedItems)
  }

  const hanldeCancelModal = () => {
    reset({ name: "" })
    handleModalContent(MODAL.NOT_SHOW)
  }

  const hanldeCancelPreviewSongModal = () => {
    const divElement = document.querySelector("#reactPlayer")
    const audioElement = divElement?.querySelector("audio")
    if (audioElement) {
      audioElement.pause()
    }
    reset({})
    handleSecondModalContent(MODAL.NOT_SHOW)
  }

  const handleModalContent = (type: MODAL, record?: ISong) => {
    switch (type) {
      case MODAL.CREATE_SONG:
        setModal({
          open: true,
          title: t("P_SONG.TITLE_CREATE_SONG"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateSong),
          onCancel: hanldeCancelModal,
          children: (
            <CreateSong
              setIsLoadingUpload={setIsLoadingUpload}
              songGroupList={songGroupList?.data}
              artistList={artistList?.data}
            />
          ),
        })
        break
      case MODAL.UPDATE_SONG:
        setModal({
          open: true,
          title: t("P_SONG.TITLE_UPDATE_SONG"),
          width: 608,
          bodyHeight: 588,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateSong),
          onCancel: hanldeCancelModal,
          children: (
            <UpdateSong
              data={record!}
              songGroupList={songGroupList?.data}
              artistList={artistList?.data}
              setIsLoadingUpload={setIsLoadingUpload}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneSong),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_SOFT_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManySong),
          onCancel: hanldeCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_SOFT_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SONG.TITLE_DETAIL_SONG"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: hanldeCancelModal,
          children: (
            <ViewSongDetail
              record={record!}
              handleSecondModalContent={handleSecondModalContent}
            />
          ),
        })
        break
      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const handleSecondModalContent = (type: MODAL, record?: ISong) => {
    switch (type) {
      case MODAL.PREVIEW_SONG:
        setSecondModal({
          open: true,
          title: record?.file.name,
          isNoFooter: true,
          onCancel: hanldeCancelPreviewSongModal,
          children: <PreviewSong path={record?.file.path!} />,
        })
        break
      default:
        setSecondModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: songList,
    refetch: refetchSongList,
    isFetching: isFetchingSongList,
  } = useQuery([ENDPOINT.SONG.GET_LIST, params], () =>
    SongService.getList(convertRequest(params)),
  )

  const {
    data: songGroupList,
    refetch: refetchSongGroupList,
    isFetching: isFetchingGroupList,
  } = useQuery([ENDPOINT.SONG_GROUP.GET_ALL, params], () =>
    SongGroupService.getAll(),
  )

  const {
    data: artistList,
    refetch: refetchArtistList,
    isFetching: isFetchingArtistList,
  } = useQuery([ENDPOINT.ARTIST.GET_ALL, params], () => ArtistService.getAll())

  const { mutate: createSong, isLoading: isLoadingCreateSong } = useMutation(
    (body: ICreateSongRequest) => SongService.create(body),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateSong } = useMutation(
    (payload: { id: number; body: IUpdateSongRequest }) =>
      SongService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({ name: "" })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsDislayed } = useMutation(
    (payload: { id: number; body: { is_displayed: boolean } }) =>
      SongService.updateIsDisplayed(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: updateIsWarning } = useMutation(
    (payload: { id: number; body: { is_warning: boolean } }) =>
      SongService.updateIsWarning(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          is_displayed: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const formMonitor = useWatch({
    control,
  })

  const { mutate: softDeleteOne } = useMutation(
    (id: number) => SongService.softDeleteOne(id),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => SongService.softDeleteMany(ids),
    {
      onSuccess: () => {
        refetchSongList()
        refetchSongGroupList()
        refetchArtistList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<ISong> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      width: "300px",
      render: (record) => {
        return (
          <div className="column-name">
            <div className="thumbnail">
              {record?.is_warning ? (
                <Badge
                  count={
                    <Tag className="tag" color="#ff4d4f">
                      PG
                    </Tag>
                  }
                  status="warning"
                >
                  <Image
                    width={80}
                    height={80}
                    src={getImage(record?.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
                    title={record.name}
                  />
                </Badge>
              ) : (
                <Image
                  width={80}
                  height={80}
                  src={getImage(record?.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
                  title={record.name}
                />
              )}
            </div>
            <div className="right">
              <span className="name">
                <TooltipWrap placement="top" title={record?.name}>
                  {truncate(record?.name, 28)}
                </TooltipWrap>
              </span>
              <ArtistListWrap
                artists={record?.artists}
                type={ARTIST_LIST_TYPE.MULTIPLE}
              />
              <span className="listener-counter">
                <HeadphoneIcon /> {record?.listener_counter + " " + "lượt nghe"}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      title: "Nhóm",
      dataIndex: "songGroup",
      width: "210px",
      align: "center",
      render: (value) =>
        value ? (
          <div className="column-group">
            <div className="thumbnail">
              <Image
                width={64}
                height={64}
                src={getImage(value?.thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
                title={value.name}
              />
            </div>
            <div className="right">
              <span className="group-name">
                <TooltipWrap placement="top" title={value?.name}>
                  {truncate(value?.name, 28)}
                </TooltipWrap>
              </span>
              <span className="day-release">
                <CalendarIcon />
                <span>{moment(value.day_release).format("DD/MM/YYYY")}</span>
              </span>
              <div className="type">
                <CompactDiscIcon /> <span>{songGroupTypes[value.type]}</span>
              </div>
            </div>
          </div>
        ) : (
          ""
        ),
    },
    {
      title: "Ngày phát hành",
      width: "148px",
      align: "center",
      render: (record) => {
        return (
          <div>
            {record?.time_release &&
              moment(record?.time_release, "HH:mm:ss").format("HH:mm")}{" "}
            {moment(record?.day_release).format("DD/MM/YYYY")}
          </div>
        )
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      width: "132px",
      align: "center",
      render: (value) => (
        <div className="column-status">
          <Tag color={songReleaseColors[value]}>{songReleaseStatus[value]}</Tag>
        </div>
      ),
    },
    {
      title: "Thông tin tệp",
      dataIndex: "file",
      width: "260px",
      align: "center",
      render: (value, record) =>
        value ? (
          <div
            className="column-file"
            onClick={() => handleSecondModalContent(MODAL.PREVIEW_SONG, record)}
          >
            <Badge.Ribbon
              placement="start"
              text={getFileExtension(value.name)?.toUpperCase()}
              color="red"
            >
              <FileMusicIcon width={90} />
            </Badge.Ribbon>
            <div className="right">
              <p className="file-name">
                <TooltipWrap placement="top" title={value.name}>
                  {truncate(value?.name, 18)}
                </TooltipWrap>
              </p>
              <div className="file-size">{formatBytes(value?.size)}</div>
              <div className="file-bit-rate">{value?.detail.bit_rate}</div>
            </div>
          </div>
        ) : (
          "Chưa có tệp"
        ),
    },
    {
      title: "Cảnh báo ngôn từ",
      dataIndex: "is_warning",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsWarning({
              id: record?.id,
              body: {
                is_warning: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Trạng thái hiển thị",
      dataIndex: "is_displayed",
      width: "160px",
      align: "center",
      render: (value, record) => (
        <SwitchWrap
          defaultValue={value}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(e) =>
            updateIsDislayed({
              id: record?.id,
              body: {
                is_displayed: e,
              },
            })
          }
        />
      ),
    },
    {
      title: "Người khởi tạo",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={[record.created_by]}
          time={record.created_at}
          type="single"
        />
      ),
    },
    {
      title: "Người chỉnh sửa",
      width: "240px",
      render: (record) => (
        <TableEditorWrap
          users={record.updated_by}
          time={record.updated_at}
          type="multiple"
        />
      ),
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record: ISong) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-song">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        songGroupList={songGroupList?.data}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={songList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingSongList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={songList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        loading={isLoadingCreateSong}
        disabled={isLoadingUpload}
        {...modal}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
      <ModalWrap {...secondModal}>
        <FormProvider {...methods}>{secondModal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
