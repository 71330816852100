import { IResponse } from "@/types/common"
import { IUploadFileRequest } from "@/types/request"
import { IUploadFileResponse } from "@/types/response"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class File {
  uploadImage = async (
    body: IUploadFileRequest,
  ): Promise<IResponse<IUploadFileResponse>> => {
    const formData = new FormData()
    formData.append("file", body.file)
    formData.append("folder", body.folder)
    formData.append("uuid", body.uuid)

    const res: IResponse<IUploadFileResponse> = await http.post(
      ENDPOINT.FILE.UPLOAD_IMAGE,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )

    return res
  }

  uploadAudio = async (
    body: IUploadFileRequest,
  ): Promise<IResponse<IUploadFileResponse>> => {
    const formData = new FormData()
    formData.append("file", body.file)
    formData.append("folder", body.folder)
    formData.append("uuid", body.uuid)

    const res: IResponse<IUploadFileResponse> = await http.post(
      ENDPOINT.FILE.UPLOAD_AUDIO,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )

    return res
  }
}

export const FileService = new File()
