import { FileThinIcon } from "@/components/icons"
import { Card } from "antd"
import parse from "html-react-parser"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import "./style.scss"

export const ConfirmContent = () => {
  const { getValues } = useFormContext()
  const emailContentRef = useRef<HTMLQuoteElement | null>(null)
  const attachmentRef = useRef<HTMLDivElement | null>(null)
  const [emailBodyHeight, setEmailBodyHeight] = useState(0)

  useEffect(() => {
    const emailContentOffsetHeight = emailContentRef?.current?.offsetHeight || 0
    const attachmentOffsetHeight = attachmentRef?.current?.offsetHeight || 0

    const totalHeight = emailContentOffsetHeight + attachmentOffsetHeight

    setEmailBodyHeight(totalHeight)
  }, [])

  return (
    <div className="c-confirm-content">
      <Card title={getValues("subject")}>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: {
              autoHide: "move",
              autoHideDelay: 1000,
            },
          }}
        >
          <div
            style={{ height: emailBodyHeight > 454 ? "340px" : "max-content" }}
          >
            <blockquote ref={emailContentRef}>
              {parse(getValues("content") || "")}
            </blockquote>
          </div>
        </OverlayScrollbarsComponent>

        {getValues("is_attach_ticket") && (
          <div className="attachment no-select" ref={attachmentRef}>
            Tệp đính kèm:
            <FileThinIcon width={80} height={80} />
            <div className="attachment-name">Vé</div>
          </div>
        )}
      </Card>
    </div>
  )
}
