import * as yup from "yup"
import { MODAL } from "./constants"

export const formSchemaFn = (modalName?: string) =>
  yup.object().shape({
    modal_name: yup.string().required("Modal name is required!"),
    name:
      (modalName === MODAL.CREATE_EMAIL_CATEGORY ||
        modalName === MODAL.UPDATE_EMAIL_CATEGORY) &&
      yup.string().required("Vui lòng nhập Tên danh mục!"),
  } as any)
