import { ConfigProvider } from "antd"
import locale from "antd/es/locale/vi_VN"
import "moment/locale/vi"
import { ReactNode } from "react"
import { HelmetProvider } from "react-helmet-async"
import { PermissionProvider } from "react-permission-role"
import { AuthProvider } from "./Auth"
import ReactQueryProvider from "./ReactQuery"
import { StateGlobalProvider } from "./StateGlobal"

const AllProviders = ({ children }: { children: ReactNode }) => {
  return (
    <HelmetProvider>
      <ConfigProvider theme={{ hashed: false }} locale={locale}>
        <PermissionProvider>
          <ReactQueryProvider>
            <AuthProvider>
              <StateGlobalProvider>{children}</StateGlobalProvider>
            </AuthProvider>
          </ReactQueryProvider>
        </PermissionProvider>
      </ConfigProvider>
    </HelmetProvider>
  )
}

export default AllProviders
