import { Switch, SwitchProps } from "antd"
import { ControllerRenderProps } from "react-hook-form"

interface ISwitchWrapProps extends SwitchProps {
  field?: ControllerRenderProps<any, any>
}

export const SwitchWrap: React.FC<ISwitchWrapProps> = ({
  className,
  field,
  ...props
}) => {
  return <Switch className={className} {...field} {...props} />
}
