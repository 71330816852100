import {
  ButtonWrap,
  FormItemWrap,
  PaginationWrap,
  TableWrap,
} from "@/components/commons"
import { TextAreaWrap } from "@/components/commons/TextAreaWrap"
import { IEventType } from "@/types/response"
import { votedMemberColors, votedMemberStatus } from "@/utils/constants"
import { notificationMessage } from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Col, Form, Row, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import Header from "./Header"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const FilterBoxchat: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [members, setMembers] = useState<any>([])

  const params = {
    name: searchParams.get("name") || "",
    status: searchParams.get("status") || "",
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn()),
  })

  const { handleSubmit, reset, watch, control } = methods

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    notificationMessage({
      message: "Đã sao chép vào bộ nhớ tạm",
      type: "success",
    })
  }

  const columns: ColumnsType<IEventType> = [
    {
      title: "STT",
      dataIndex: "id",
      width: "80px",
      align: "center",
    },
    {
      title: "Tên",
      width: "220px",
      render: (record) => (
        <div className="field-name">
          <div className="avatar">
            <img src={record?.avatar_path} alt={record?.name} />
          </div>
          <span className="name">{record?.name}</span>
        </div>
      ),
    },
    {
      title: "Vai trò",
      dataIndex: "role",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      width: "220px",
      render: (value) => (
        <Tag color={votedMemberColors[value]}>{votedMemberStatus[value]}</Tag>
      ),
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      width: "220px",
      render: (value) => value,
    },
    {
      title: "Action",
      width: "128px",
      align: "center",
      fixed: "right",
      render: (record) => (
        <div className="field-action">
          <ButtonWrap
            type="primary"
            onClick={() => handleCopyToClipboard(record?.name)}
          >
            Sao chép tên
          </ButtonWrap>
        </div>
      ),
    },
  ]

  const handleGetStayingMember = (source: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(source, "text/html")
    const spans = doc.querySelectorAll("span")
    const images = doc.querySelectorAll("image")

    const result = Array.from(spans).map((span, index) => {
      const name = span.textContent
      const avatar_path = images[index]
        ? images[index].getAttribute("xlink:href")
        : ""
      return { name, avatar_path }
    })

    return result
  }

  const handleGetAllMembers = (source: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(source, "text/html")

    const names = doc.querySelectorAll(
      ".x193iq5w.xeuugli.x13faqbe.x1vvkbs.xt0psk2.xlh3980.xvmahel.x1n0sxbx.x1f6kntn.xk50ysn.xzsf02u.xq9mrsl",
    )
    const avatars = doc.querySelectorAll("image")
    const notes = doc.querySelectorAll(
      ".x193iq5w.xeuugli.x13faqbe.x1vvkbs.xt0psk2.xlh3980.xvmahel.x1n0sxbx.x1pg5gke.x1fcty0u.xi81zsa.xq9mrsl",
    )

    const extractedData: {
      name: string
      avatar_path: string
      note: string
      role: string
    }[] = []

    names.forEach((nameElement, index) => {
      const name = (nameElement as HTMLElement).innerText
      const avatar_path = avatars[index]?.getAttribute("xlink:href")
      let note = (notes[index] as HTMLElement).innerText
      let role = "Thành viên"

      if (note && note.includes("Quản trị viên")) {
        role = "Quản trị viên"
        note = note.replace("Quản trị viên  · ", "")
      }

      if (name && avatar_path && note) {
        extractedData.push({ name, avatar_path, note, role })
      }
    })

    return extractedData
  }

  const formMonitor = useWatch({
    control,
  })

  const handleGetKickOutMember = () => {
    const staying = handleGetStayingMember(formMonitor.staying_member_source)
    const all = handleGetAllMembers(formMonitor.all_members_source)

    console.log("staying", staying)

    const result = all.map((person) => {
      const isStaying = staying.some(
        (stayPerson) => stayPerson.name === person.name,
      )
      return {
        ...person,
        status: isStaying ? "stay" : "out",
      }
    })

    setMembers(result)
  }

  const handleFilter = (data: any) => {
    if (params?.status) {
      return members?.filter((item: any) => item.status === params?.status)
    }
    return members
  }

  return (
    <div className="p-filter-boxchat">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header params={params} />

      <Form labelAlign="right" layout="vertical">
        <Row gutter={{ xxl: 20 }}>
          <Col span={24} xxl={24}>
            <Row gutter={16}>
              <Col span={24} md={12}>
                <FormItemWrap
                  label="1. Dán mã nguồn danh sách thành viên boxchat:"
                  colon
                >
                  <Controller
                    name="all_members_source"
                    control={control}
                    render={({ field }) => (
                      <TextAreaWrap
                        field={field}
                        rows={10}
                        placeholder="Mã nguồn"
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
              <Col span={24} md={12}>
                <FormItemWrap
                  label="2. Dán mã nguồn danh sách thành viên ở lại:"
                  colon
                >
                  <Controller
                    name="staying_member_source"
                    control={control}
                    render={({ field }) => (
                      <TextAreaWrap
                        field={field}
                        rows={10}
                        placeholder="Mã nguồn"
                      />
                    )}
                  />
                </FormItemWrap>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <div className="generate-data">
        <ButtonWrap type="primary" onClick={handleGetKickOutMember}>
          Xem kết quả
        </ButtonWrap>
      </div>
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={handleFilter(members)}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={false}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={handleFilter(members)?.length}
        current={1}
        total={handleFilter(members)?.length}
        // onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
    </div>
  )
}
