import { IResponse, IResponsePagination } from "@/types/common"
import {
  ICreateEventTypeRequest,
  IGetListEventTypeRequest,
  IUpdateEventTypeRequest,
} from "@/types/request"
import {
  ICreateEventTypeResponse,
  IGetAllEventTypeResponse,
  IGetListEventTypeResponse,
  IUpdateEventTypeResponse,
} from "@/types/response"
import { Key } from "react"
import http from "../axiosClient"
import { ENDPOINT } from "../endpoint"

class EventType {
  getAll = (): Promise<IResponse<IGetAllEventTypeResponse>> => {
    return http.get(ENDPOINT.EVENT_TYPE.GET_ALL)
  }

  getList = (
    params: IGetListEventTypeRequest,
  ): Promise<IResponsePagination<IGetListEventTypeResponse>> => {
    return http.get(ENDPOINT.EVENT_TYPE.GET_LIST, { params: params })
  }

  create = (
    body: ICreateEventTypeRequest,
  ): Promise<IResponse<ICreateEventTypeResponse>> => {
    return http.post(ENDPOINT.EVENT_TYPE.CREATE, body)
  }

  update = (
    id: number,
    body: IUpdateEventTypeRequest,
  ): Promise<IResponse<IUpdateEventTypeResponse>> => {
    return http.put(ENDPOINT.EVENT_TYPE.UPDATE.replace(":id", String(id)), body)
  }

  softDeleteOne = (id: number) => {
    return http.delete(
      ENDPOINT.EVENT_TYPE.SOFT_DELETE_ONE.replace(":id", String(id)),
    )
  }

  softDeleteMany = (ids: Key[]) => {
    return http.delete(ENDPOINT.EVENT_TYPE.SOFT_DELETE_MANY, {
      data: {
        ids,
      },
    })
  }
}

export const EventTypeService = new EventType()
