import { Form, FormItemProps } from "antd"

export const FormItemWrap: React.FC<FormItemProps> = ({
  className,
  colon = false,
  children,
  ...props
}) => {
  return (
    <Form.Item colon={colon} className={className} {...props}>
      {children}
    </Form.Item>
  )
}
