import { BreadcrumbWrap } from "@/components/commons"
import { APP_ROUTER } from "@/routes"
import { t } from "@/utils/helpers"
import "./style.scss"

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = ({}) => {
  const routes = [
    {
      title: t("COMMON.BREADCRUMB.MANAGEMENT"),
    },
    {
      title: t("COMMON.BREADCRUMB.CONFIG"),
      href: APP_ROUTER.CONFIG,
    },
  ]

  return (
    <div className="c-config-header no-select">
      <div className="title">{t("P_CONFIG.HEADER.TITLE")}</div>
      <div className="action">
        <BreadcrumbWrap items={routes} />
        <div className="right"></div>
      </div>
    </div>
  )
}

export default Header
