import { ModalWrap, PaginationWrap, TableWrap } from "@/components/commons"
import { ConfirmDeleteMany, ConfirmDeleteOne } from "@/components/modals"
import { ENDPOINT } from "@/services/endpoint"
import { PermissionService } from "@/services/permission"
import { RoleService } from "@/services/role"
import { IModalState } from "@/types/common"
import { ICreateRoleRequest, IUpdateRoleRequest } from "@/types/request/role"
import { IGetPartnerResponse } from "@/types/response/partner"
import { IPermission } from "@/types/response/permission"
import { IGetAllRoleResponse, IRole } from "@/types/response/role"
import {
  convertRequest,
  convertToStringObject,
  notificationMessage,
  replaceStrings,
  t,
} from "@/utils/helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import _ from "lodash"
import { Key, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { ColumnAction } from "./ColumnAction"
import Header from "./Header"
import { CreateRole, UpdateRole, ViewSliderDetail } from "./Modal"
import { MODAL } from "./constants"
import { formSchemaFn } from "./formSchemaFn"
import "./style.scss"

interface IProps {
  title?: string
}

export const Role: React.FC<IProps> = ({ title }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [modalName, setModalName] = useState()
  const [selectedItems, setSelectedItems] = useState<Key[]>([])

  const [modal, setModal] = useState<IModalState>({
    className: "",
    type: "",
    title: "",
    width: 608,
    open: false,
    children: <></>,
  })

  const params = {
    page_size: Number(searchParams.get("page_size")) || 10,
    current_page: Number(searchParams.get("current_page")) || 1,
    display_name: searchParams.get("display_name"),
  }

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(formSchemaFn(modalName)),
    defaultValues: {
      priority: 0,
    },
  })

  const { handleSubmit, reset, watch } = methods

  useEffect(() => {
    setModalName(watch("modal_name"))
  }, [watch("modal_name")])

  const rowSelection: TableRowSelection<IGetAllRoleResponse> = {
    selectedRowKeys: selectedItems,
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys)
    },
  }

  const handleClearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setSearchParams({
      ...convertToStringObject(params),
      current_page: String(currentPage),
      page_size: String(pageSize),
    })
  }

  const handleCreateRole = (values: any) => {
    const keysToDelete = ["name", "display_name", "priority", "modal_name"]
    const permission = _.omit({ ...values }, keysToDelete)
    const pickPermission = _.pickBy(permission, (value) => value === true)

    const body: ICreateRoleRequest = {
      name: values.name,
      display_name: values.display_name,
      permissions: Object.keys(pickPermission),
    }

    createRole(body)
  }

  const handleUpdateRole = (values: any) => {
    const keysToDelete = ["name", "display_name", "priority", "modal_name"]
    const permission = _.omit({ ...values }, keysToDelete)
    const pickPermission = _.pickBy(permission, (value) => value === true)

    const body: IUpdateRoleRequest = {
      display_name: values.display_name,
      permissions: Object.keys(pickPermission),
    }

    updateRole({ id: values.id, body })
  }

  const handleDeleteOneRole = (values: any) => {
    forceDeleteOne(values.id)
  }

  const handleDeleteManyRole = () => {
    softDeleteMany(selectedItems)
  }

  const handleCancelModal = () => {
    handleModalContent(MODAL.NOT_SHOW)
    reset({
      name: "",
    })
  }

  const handleModalContent = (type: MODAL, record?: IRole) => {
    switch (type) {
      case MODAL.CREATE_ROLE:
        setModal({
          open: true,
          title: t("P_ROLE.TITLE_CREATE_ROLE"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ADD"),
          onOk: handleSubmit(handleCreateRole),
          onCancel: handleCancelModal,
          children: (
            <CreateRole
              permissionList={
                permissionList?.data?.sort((a, b) => a.priority - b.priority)!
              }
            />
          ),
        })
        break
      case MODAL.UPDATE_ROLE:
        setModal({
          open: true,
          title: t("P_ROLE.TITLE_UPDATE_ROLE"),
          width: 608,
          okText: t("COMMON.MODAL_WRAP.BUTTON_UPDATE"),
          onOk: handleSubmit(handleUpdateRole),
          onCancel: handleCancelModal,
          children: (
            <UpdateRole
              data={record!}
              permissionList={
                permissionList?.data?.sort((a, b) => a.priority - b.priority)!
              }
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_ONE:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: 1,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteOneRole),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteOne
              id={record?.id}
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_ONE")}
            />
          ),
        })
        break
      case MODAL.CONFIRM_DELETE_MANY:
        setModal({
          open: true,
          title: replaceStrings(t("COMMON.MODAL_WRAP.TITLE_FORCE_DELETE"), {
            count: selectedItems.length,
          }),
          width: 486,
          okText: t("COMMON.MODAL_WRAP.BUTTON_ACCEPT"),
          onOk: handleSubmit(handleDeleteManyRole),
          onCancel: handleCancelModal,
          children: (
            <ConfirmDeleteMany
              modalName={MODAL.CONFIRM_DELETE_ONE}
              content={t("COMMON.MODAL_WRAP.CONTENT_FORCE_DELETE_MANY")}
            />
          ),
        })
        break
      case MODAL.ROW_DETAIL:
        setModal({
          open: true,
          title: t("P_SLIDER.TITLE_DETAIL_SLIDER"),
          width: 608,
          bodyHeight: 500,
          onlyCancel: true,
          onCancel: handleCancelModal,
          children: <ViewSliderDetail record={record!} />,
        })
        break

      default:
        setModal({
          open: false,
          width: 608,
          children: <></>,
        })
        break
    }
  }

  const {
    data: roleList,
    refetch: refetchRoleList,
    isFetching: isFetchingRoleList,
  } = useQuery([ENDPOINT.ROLE.GET_LIST, params], () =>
    RoleService.getList(convertRequest(params)),
  )

  const { data: permissionList, isFetching: isFetchingPermissionList } =
    useQuery([ENDPOINT.PERMISSION.GET_ALL_BY_GROUP, params], () =>
      PermissionService.getAllByGroup(),
    )

  const { mutate: createRole, isLoading: isLoadingCreatePermission } =
    useMutation((body: ICreateRoleRequest) => RoleService.create(body), {
      onSuccess: () => {
        refetchRoleList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Thêm thành công",
          type: "success",
        })
        reset({
          name: "",
        })
      },
      onError: (error: any, body) => {},
    })

  const { mutate: updateRole } = useMutation(
    (payload: { id: number; body: IUpdateRoleRequest }) =>
      RoleService.update(payload.id, payload.body),
    {
      onSuccess: () => {
        refetchRoleList()
        handleModalContent(MODAL.NOT_SHOW)
        notificationMessage({
          message: "Cập nhật thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({
          name: true,
        })
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: forceDeleteOne } = useMutation(
    (id: number) => RoleService.forceDeleteOne(id),
    {
      onSuccess: () => {
        refetchRoleList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const { mutate: softDeleteMany } = useMutation(
    (ids: Key[]) => RoleService.forceDeleteMany(ids),
    {
      onSuccess: () => {
        refetchRoleList()
        handleModalContent(MODAL.NOT_SHOW)
        setSearchParams({
          ...convertToStringObject(params),
          current_page: String(1),
        })
        notificationMessage({
          message: "Xoá thành công",
          type: "success",
        })
        setSelectedItems([])
        reset({})
      },
      onError: (error: any, body) => {},
    },
  )

  const columns: ColumnsType<IGetPartnerResponse> = [
    {
      title: "ID",
      dataIndex: "id",
      width: "40px",
      align: "center",
    },
    {
      title: "Tên vai trò",
      dataIndex: "display_name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Key",
      dataIndex: "name",
      width: "120px",
      render: (value) => value,
    },
    {
      title: "Quyền",
      dataIndex: "permissions",
      width: "160px",
      align: "center",
      render: (value: IPermission[]) => (
        <div className="column-permissions">
          {value?.map((permission, index) => (
            <Tag key={index} color="var(--color-green-1)" className="no-select">
              {permission?.display_name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Action",
      width: "82px",
      align: "center",
      fixed: "right",
      render: (record: IRole) => (
        <ColumnAction handleModalContent={handleModalContent} record={record} />
      ),
    },
  ]

  return (
    <div className="p-role">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        params={params}
        selectedItems={selectedItems}
        handleModalContent={handleModalContent}
        onClearSelectedItems={handleClearSelectedItems}
      />
      <TableWrap
        rowKey="id"
        columns={columns}
        dataSource={roleList?.data}
        scroll={{ x: 1548 }}
        pagination={false}
        loading={isFetchingRoleList}
        rowSelection={rowSelection}
      />
      <PaginationWrap
        className="pagination-table"
        pageSize={params.page_size}
        current={params.current_page}
        total={roleList?.total}
        onChange={handleChangePage}
        showSizeChanger
        showLessItems
      />
      <ModalWrap
        className={modal.className}
        title={modal.title}
        open={modal.open}
        onOk={modal.onOk}
        onCancel={modal.onCancel}
        okText={modal.okText}
        loading={isLoadingCreatePermission}
        width={modal.width}
        bodyHeight={modal.bodyHeight}
        onlyCancel={modal.onlyCancel}
      >
        <FormProvider {...methods}>{modal.children}</FormProvider>
      </ModalWrap>
    </div>
  )
}
