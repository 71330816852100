import {
  FormItemWrap,
  InputNumberWrap,
  InputWrap,
  SelectWrap,
} from "@/components/commons"
import { IPermission } from "@/types/response/permission"
import { convertArrayToOptions } from "@/utils/helpers"
import { Form } from "antd"
import { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { MODAL } from "../../constants"

interface IProps {
  permissionList: IPermission[]
}

export const CreatePermissionGroup: React.FC<IProps> = ({ permissionList }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()

  useEffect(() => {
    setValue("modal_name", MODAL.CREATE_PERMISSION_GROUP)
  }, [])

  return (
    <Form layout="vertical">
      <FormItemWrap
        label="Tên nhóm quyền"
        required
        validateStatus={errors?.name ? "error" : ""}
        help={<>{errors?.name && errors?.name?.message}</>}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputWrap
              placeholder="Nhập tên nhóm quyền"
              field={field}
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap label="Quyền">
        <Controller
          name="permission_ids"
          control={control}
          render={({ field }) => (
            <SelectWrap
              placeholder="Chọn quyền"
              options={convertArrayToOptions(
                permissionList,
                "id",
                "display_name",
              )}
              field={field}
              mode="multiple"
              allowClear
            />
          )}
        />
      </FormItemWrap>
      <FormItemWrap
        label="Thứ tự hiển thị"
        required
        validateStatus={errors?.priority ? "error" : ""}
        help={<>{errors?.priority && errors?.priority?.message}</>}
      >
        <Controller
          name="priority"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <InputNumberWrap placeholder="Nhập thứ tự" field={field} />
          )}
        />
      </FormItemWrap>
    </Form>
  )
}
