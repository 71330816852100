import { FormItemWrap, SwitchWrap, TableEditorWrap } from "@/components/commons"
import { ISongGroup } from "@/types/response"
import { IMAGE_TYPE, songGroupTypes } from "@/utils/constants"
import { getImage } from "@/utils/helpers"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { Form, Image, Typography } from "antd"
import moment from "moment"
import "./style.scss"

interface IProps {
  record: ISongGroup
}

export const ViewSongGroupDetail: React.FC<IProps> = ({ record }) => {
  const {
    name,
    thumbnail_path,
    description,
    day_release,
    time_release,
    type,
    songs,
    is_displayed,
    created_by,
    updated_by,
    created_at,
    updated_at,
  } = record

  return (
    <div className="c-view-song-group-detail">
      <Form layout="vertical">
        <FormItemWrap label="Tên nhóm">{name}</FormItemWrap>
        <FormItemWrap label="Thumbnail">
          <div className="field-thumbnail">
            <Image
              width={112}
              src={getImage(thumbnail_path, IMAGE_TYPE.THUMBNAIL)}
            />
          </div>
        </FormItemWrap>
        <FormItemWrap label="Mô tả">
          {description ? (
            <Typography.Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,
              }}
              title={description}
            >
              {description}
            </Typography.Paragraph>
          ) : (
            "Chưa có mô tả"
          )}
        </FormItemWrap>
        <FormItemWrap label="Loại">{songGroupTypes[type]}</FormItemWrap>
        <FormItemWrap label="Ngày phát hành">
          {time_release && moment(time_release, "HH:mm:ss").format("HH:mm")}{" "}
          {moment(day_release).format("DD/MM/YYYY")}
        </FormItemWrap>
        <FormItemWrap label="Số lượng bài hát">{songs.length}</FormItemWrap>
        <FormItemWrap label="Trạng thái hiển thị">
          <SwitchWrap
            defaultValue={is_displayed}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled
          />
        </FormItemWrap>
        <FormItemWrap label="Người khởi tạo">
          <TableEditorWrap
            users={[created_by]}
            time={created_at}
            type="single"
          />
        </FormItemWrap>
        <FormItemWrap label="Người chỉnh sửa">
          <TableEditorWrap
            users={updated_by}
            time={updated_at}
            type="multiple"
          />
        </FormItemWrap>
      </Form>
    </div>
  )
}
